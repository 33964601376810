import React from "react";
import "./sunset.scss";

export const Sunset = () => {
    return (
        <div className={"sunset"}>
            <div className={"sunset-container-1"}/>
            <div className={"sunset-container-2"}/>
            <div className={"sunset-container-3"}/>
            <div className={"content d-none d-md-block"}/>
        </div>
    )
};