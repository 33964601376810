import React, {useContext, useReducer, useState} from 'react';
import {Col, Row} from 'react-grid-system';
import {Element, HBox, VBox} from 'react-stylesheet';
import {Link, useHistory} from "react-router-dom";
import styled from 'styled-components';
import {AuthContext} from "../../../../providers/auth-provider";
import {DICTIONARY} from "../registration-dictionary";
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {colors, font_size, gutters, viewport} from '../../../00_base/variables';
import {Button, SimpleLoader, Span} from '../../../01_atoms';
import {FieldText} from '../../../02_molecules';

const reducer = (state, action) => {
    return {...state, [action.name]: action.value}
};

const defaultForm = {
    email: '',
    password: '',
};

const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Login = ({title, titleSize = "xxl", actionAfterLogin, goAfterLogin, className}) => {
    const [form, dispatchForm] = useReducer(reducer, defaultForm);
    const {isLogin, login} = useContext(AuthContext);

    const {userLanguage} = useContext(LanguageContext);

    const [warningsActive, setWarningsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    const {email, password} = form;

    if (isLogin) {
        history.push("/");
    }

    const emailValid = emailRegex.test(String(email).toLowerCase());
    const passwordValid = !!password;

    const checkValidation = () => {
        return emailValid && passwordValid;
    };

    const handleChange = (name, value) => {
        dispatchForm({
            type: "change",
            name: name,
            value: value
        })
    };

    const handleSubmit = (event) => {
        event && event.preventDefault();
        setIsLoading(true);
        if (checkValidation()) {
            login(email, password, actionAfterLogin, goAfterLogin, () => {
                setIsLoading(false)
                setWarningsActive(true)
            })
        } else {
            setWarningsActive(true);
            setIsLoading(false);
        }
    };

    const controlStyle = {marginBottom: gutters.md};
    const inputStyle = {};
    const loginForgotLink = {
        position:"absolute",
        top:"1.7rem",
        left: "2.5rem",
        fontSize: font_size.xxs,
        zIndex:100,
        cursor:"pointer"
    }
    const fieldTextProps = {
        borderRadius: 0,
        border: 0,
        marginBottom: 0,
        controlStyle: controlStyle,
        inputStyle: inputStyle,
        changeHandler: handleChange,
        focus: () => setWarningsActive(false)
    };
    return (
        <Wrapper className={`login-wrapper ${className}`}>
            <FormWrapper className={`login-form-wrapper`}>
                <h2 className={`form-title font-size-${titleSize}`}>{title || DICTIONARY.login[userLanguage]}</h2>
                <form id={'login-form'}
                      onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12}>
                            <FieldText type={'text'}
                                       label={DICTIONARY.emailAddress[userLanguage]}
                                       name={'email'}
                                       value={email}
                                       errorMessage={DICTIONARY.emailAddressWarning[userLanguage]}
                                       errorMessageActive={warningsActive && !emailValid}
                                       {...fieldTextProps}/>
                        </Col>

                        <Col xs={12} className={"position-relative"}>
                            <FieldText
                                type={'password'}
                                label={DICTIONARY.password[userLanguage]}
                                name={'password'}
                                focus={() => setWarningsActive(false)}
                                value={password}
                                errorMessage={DICTIONARY.passwordWarning[userLanguage]}
                                errorMessageActive={warningsActive && !passwordValid}
                                {...fieldTextProps}/>
                                <Link to={"forgot-password"}
                                      className={"login-forgot-link"}
                                      style={loginForgotLink}>
                                    שכחתי סיסמא
                                </Link>
                        </Col>
                    </Row>

                    {passwordValid && emailValid && warningsActive &&
                    <Element textAlign={'center'}
                             opacity={warningsActive ? 1 : 0}
                             paddingBottom={gutters.xs}>
                        {DICTIONARY.emailPasswordNotMatch[userLanguage]}
                    </Element>
                    }

                    <VBox alignItems={'center'}>
                        {/*<Link to={'register'} style={registerButton}>הירשם</Link>*/}

                        <Button width={"100px"}>
                            {isLoading ?
                                <SimpleLoader color={"#fff"}/> :
                                <Translation id={DICT.CONTINUE}/>
                            }
                        </Button>

                        <HBox alignItems={"center"}
                              flexWrap={"wrap"}
                              justifyContent={"center"}
                              marginTop={gutters.lg}>
                            <Span style={{fontSize: font_size.sm}} value={DICTIONARY.notAccount[userLanguage]}/>
                            <Button weight={'bold'}
                                    background={"transparent"}
                                    color={colors.black}
                                    colorHover={colors.black}
                                    underline={true}
                                    style={{textDecoration: "underline"}}
                                    backgroundColorHover={"transparent"}
                                    textAlign={'center'}
                                    marginTop={gutters.md}>
                                <Link to={`/${userLanguage}/register`}
                                      style={{color: "#000", fontWeight: "bold"}}>
                                    {DICTIONARY.toRegister[userLanguage]}
                                </Link>
                            </Button>
                        </HBox>
                    </VBox>

                </form>
                {/* } */}
            </FormWrapper>
        </Wrapper>
    )
}

export default Login;


const Wrapper = styled.div`
    // min-height: calc(100vh - 220px);  
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 ${gutters.sm};
    h2.form-title {
        text-align: center;
        margin-bottom: ${gutters.sm}; 
        
    }
    
`,

    FormWrapper = styled.div`
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 66rem;
        margin: ${gutters.xl} 0;
        max-width: 100%;

        form {
            margin-top: ${gutters.md}
            // max-width: 40rem;
            width: 100%;
            input {
                // height: 3rem;
            }
            .field-submit{
                width: 50%;
                // margin-right: 5px;
                input {
                    width: 100%;
                }
            }
            
            @media screen and (min-width: ${viewport.sm}) {
                // max-width: 40rem;
            }
        }
    `;