import React, {useEffect, useState} from "react";
import "./navigate-items.scss";
import classNames from "classnames";

export const NavigateItems = ({length, currentPageIndex=0, handleChange}) => {
    const [group] = useState(0);
    const [activeItem, setActiveItem] = useState(currentPageIndex);
    let items = [];
    for (let i = 0; i < length / 15; i++) {
        items.push(i);
    }

    useEffect(() => {
        if (activeItem >= 0) {
            handleChange(activeItem)
        }
    }, [handleChange, activeItem]);

    const renderItem = item => {
        return (
            <div className={classNames("navigate-items-number", {active: activeItem === item})}
                 onClick={() => setActiveItem(item)}
                 key={item}>
                {item + 1}
            </div>
        )
    };


    const prevDisable = activeItem === 0;
    const nextDisable = activeItem >= parseInt(length/15);

    return (
        <div className="navigate-items">
            <div className={classNames("navigate-items-nav", {disable: prevDisable})}
                 onClick={()=> {
                     if (!prevDisable) {
                         setActiveItem(activeItem - 1);
                     }
                 }}>
                {`< הקודם`}
            </div>
            <div className="navigate-items-numbers">
                {items.slice(group, group + 15).map(renderItem)}
            </div>


            <div className={classNames("navigate-items-nav", {disable: nextDisable})}
                 onClick={() => {
                     if (!nextDisable) {
                         setActiveItem(activeItem + 1);
                     }
                 }}>{`הבא >`}</div>
        </div>
    )
}