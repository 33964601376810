import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row} from "react-grid-system";
import {Slider} from "../../03_organisms";
import classNames from "classnames"
import "./review-mock.scss";
import _ from "lodash";
import {reviews, reviews_en} from "./mock";
import {LanguageContext} from "../../../languages";

const COLOR_BG = "https://storage.googleapis.com/assets.youdoadventures.com/website/color_bg_1.svg";


export const ReviewMocks = () => {
    const {userLanguage} = useContext(LanguageContext);
    const REVIEWS = userLanguage === "en" ? reviews_en : reviews;
    const [currentImageSrc, setCurrentImageSrc] = useState("");
    const [currentReview, setCurrentReview] = useState(REVIEWS[0]);

    const [previousImageSrc, setPreviousImageSrc] = useState(null);
    const [previousReview, setPreviousReview] = useState(REVIEWS[0]);

    const setRandomImageSrc = (newName) => {
        const womanNames = userLanguage === "en" ? ["Galit Caspi", "Liora David", "Yael Arzi Ilem", "Orit Leiboviz"] : ["גלית כספי", "ליאורה דוד", "יעל ארצי עילם", "אורית ליבוביץ"];
        const menNames = userLanguage === "en" ? ["Yaron Ben-Yehuda, Intel"] : ["ירון בן יהודה , אינטל"];
        let randomImageIndex = _.random(1, 22)

        if (menNames.includes(newName)) {
            randomImageIndex = _.random(1, 13)
        } else if (womanNames.includes(newName)) {
            randomImageIndex = _.random(14, 22)
        }

        setCurrentImageSrc(require(`../../../assets/images/reviews-people/avatar_${randomImageIndex}.png`))
    }

    useEffect(() => {
        setRandomImageSrc();
    }, []);

    const onReviewChange = (currentReviewIndex) => {
        setPreviousImageSrc(currentImageSrc);
        setPreviousReview(currentReview);

        setCurrentReview(REVIEWS[currentReviewIndex]);
        setRandomImageSrc(REVIEWS[currentReviewIndex].name);

        setTimeout(() => {
            setPreviousImageSrc(null);
            setPreviousReview(null);
        }, 500)
    }

    const renderReviewImages = () => {
        const isSwitchingImage = (previousImageSrc && previousReview)
        return (
            <div className={"review-image-container d-none d-sm-block"}>
                {isSwitchingImage &&
                <div className={classNames("review-image-wrapper", {fadeOut: isSwitchingImage})}>
                    <img src={previousImageSrc} alt={"review"} className={"review-image"}/>
                    <p className={"review-image-name"}>{previousReview.name}</p>
                </div>
                }
                <div className={classNames("review-image-wrapper", {fadeIn: isSwitchingImage})}>
                    <img src={currentImageSrc} alt={"review"} className={"review-image"}/>
                    <p className={"review-image-name"}>{currentReview.name}</p>
                </div>
            </div>
        )
    }

    const renderReview = (item, index) => {
        return <Row className={" review-mock"} key={index}>
            <Col className="review-mocks-description">
                <p className={"color-black"}>{item.description}</p>
            </Col>
        </Row>
    };

    return (
        <Container className={"review-mocks"} style={{backgroundImage: `url(${COLOR_BG}`}}>
            <div className={"review-mocks-wrapper"}>
                {renderReviewImages()}
                <Slider items={REVIEWS.map(renderReview)}
                        noArrows={true}
                        speed={4}
                        endSlideDirection={"Left"}
                        onChange={onReviewChange}
                        noIndicators={true}
                        autoNavigate={true}/>
            </div>
        </Container>
    )
};