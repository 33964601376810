import React from 'react';
import {MdClose} from "react-icons/md";
import {Table, Td, Tr} from "../../../03_organisms/table";
import {FieldText, FieldVideo, FilesUploader} from "../../../02_molecules";
import {ColorPickerBgText} from "../../../03_organisms";
import "./audio-list-create-style.scss";

export const AudioListCreateSection = (
    {
        name,
        title,
        audio,
        image,
        imageSize,
        backgroundImage,
        textColor,
        bgColor,
        handleChange,
        removeSection,
        index
    }) => {

    return (
        <Tr>
            <Td style={{borderBottom: "1px solid #333"}}>
                <Table>
                    <tbody>
                    <Tr>
                        <Td vAlign="middle">
                            <FieldVideo triggerValue={'העלה הקלטת אודיו'}
                                        controlStyle={{...controlStyle, borderWidth: 0}}
                                        name={'audio'}
                                        value={audio}
                                        videoType={2}
                                        hideTextField={true}
                                        triggerStyle={{fontSize: "15px"}}
                                        changeHandler={handleChange}
                                        index={index}/>
                        </Td>

                        <Td xs={2} vAlign="middle">
                            <FilesUploader name="backgroundImage"
                                           triggerValue="תמונה"
                                           value={backgroundImage}
                                           previewWidth={100}
                                           multiple={false}
                                           changeHandler={handleChange}/>
                        </Td>

                        <Td xs={2} vAlign="middle">
                            <FilesUploader name="image"
                                           triggerValue="איקון"
                                           value={image}
                                           previewWidth={60}
                                           previewHeight={60}
                                           multiple={false}
                                           changeHandler={handleChange}/>
                            {image &&
                            <FieldText placeholder={`גודל איקון`}
                                       value={imageSize}
                                       index={index}
                                       controlStyle={{...controlStyle, maxWidth: "130px"}}
                                       inputStyle={inputStyle}
                                       name={'imageSize'}
                                       changeHandler={handleChange}/>}
                        </Td>

                        <Td vAlign="middle">
                            <FieldText placeholder={`שם`}
                                       value={name}
                                       index={index}
                                       controlStyle={{...controlStyle, maxWidth: "130px"}}
                                       inputStyle={inputStyle}
                                       name={'name'}
                                       changeHandler={handleChange}/>
                        </Td>

                        <Td vAlign="middle">
                            <FieldText placeholder={`כותרת`}
                                       value={title}
                                       index={index}
                                       controlStyle={{...controlStyle, maxWidth: "130px"}}
                                       inputStyle={inputStyle}
                                       name={'title'}
                                       changeHandler={handleChange}/>
                        </Td>

                        <Td xs={2}>
                            <ColorPickerBgText handleChange={handleChange}
                                               textColor={textColor}
                                               bgColor={bgColor}/>
                        </Td>

                        <Td vAlign="middle">
                            <MdClose onClick={removeSection} style={{cursor: "pointer"}}/>
                        </Td>
                    </Tr>
                    </tbody>
                </Table>
            </Td>
        </Tr>
    )
};

const controlStyle = {width: '100%', marginBottom: 0, padding: `0 15px`};
const inputStyle = {backgroundColor: '#fff'};