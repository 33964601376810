import React, {useContext, useEffect, useState} from "react";
import {MdMoreVert, MdRemoveRedEye} from "react-icons/md";
import {AuthContext} from "../../../../providers/auth-provider";
import {dateFormat} from "../../../../helpers/dates/date-format";
import {FieldCheckbox, FieldText, FieldTextArea, FilesUploader} from "../../../02_molecules";
import {TableInnerMenu, Td, TdContent, Tr} from "../../../03_organisms/table";
import {ToggleIcon} from "../../../03_organisms";
import {colors} from "../../../00_base/variables";
import * as categoriesActions from "../../../../store/actions/categories-actions";
import {setActiveCategory} from "../../../../store/actions/categories-actions";
import {useDispatch} from "react-redux";
import {updateRiddle} from "../../../../store/actions/riddle-actions";
import {Switcher} from "../../../01_atoms";

export const RiddleItem = (
    {
        item,
        index,
        innerMenuItems,
        setInnerMenuActive,
        menuActive,
        toggleMarkItem,
    }) => {
    const {isTom} = useContext(AuthContext);
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();

    const handleChange = (name, value) => dispatch(updateRiddle({...item, [name]: value}));

    const toggle = () => setIsOpen(!isOpen);

    return (
        <>
            <Tr key={index} className={"riddle-item"}>
                <Td>
                    <TdContent>
                        {isTom ?
                            <Switcher name={"show_in_store"}
                                      checked={item.show_in_store}
                                      changeHandler={handleChange}/>
                            :
                            item.id
                        }

                    </TdContent>
                </Td>

                <Td>
                    <TdContent>
                        <FieldText name="title"
                                   changeHandler={handleChange}
                                   value={item.title}
                                   controlStyle={controlStyle}
                                   inputStyle={inputStyle}/>
                    </TdContent>
                </Td>

                <Td className="p-0">
                    <TdContent className="bg-white">
                        {/*<Image height={50} block className={"mx-auto"} src={item.icon}/>*/}
                        <FilesUploader name="thumbnail"
                                       triggerValue={"50 * 50"}
                                       value={item.thumbnail}
                                       multiple={false}
                                       previewWidth={60}
                                       triggerStyle={{cursor:"pointer"}}
                                       previewHeight={70}
                                       changeHandler={handleChange}/>
                    </TdContent>
                </Td>
                <Td>
                    <TdContent>
                        <Switcher name={"is_published"}
                                  checked={item.is_published}
                                  changeHandler={handleChange}/>
                    </TdContent>
                </Td>

                <Td>
                    <TdContent>
                        {item.user_email}
                    </TdContent>
                </Td>

                <Td>
                    <TdContent>
                        <FieldText name={"created_at"}
                                   changeHandler={handleChange}
                                   value={dateFormat(item.created_at)}
                                   controlStyle={controlStyle}
                                   inputStyle={inputStyle}/>
                    </TdContent>
                </Td>

                <Td>
                    <TdContent>
                        <div className={"d-flex align-items-center justify-content-end"}>

                            <MdRemoveRedEye onClick={() => dispatch(setActiveCategory(item))}
                                            cursor={"pointer"}
                                            className={"ml-4"}/>

                            <ToggleIcon toggle={toggle}
                                        size={23}
                                        isOpen={isOpen}/>

                            <MdMoreVert size={20} onClick={setInnerMenuActive}
                                        className="inner-menu-trigger mr-4"
                                        cursor={"pointer"} color={"#d2d2d2"}/>
                        </div>
                        {menuActive && <TableInnerMenu items={innerMenuItems} itemId={item.id}/>}
                    </TdContent>
                </Td>
            </Tr>
            {isOpen && <Tr>
                <Td colSpan={10} height="auto" className="riddle-description">
                    <FieldTextArea name="description_creator"
                                   changeHandler={(name, value) => handleChange("templatedata",{...item.templatedata, [name]: value})}
                                   wysiwyg={true}
                                   toolbarHidden={false}
                                   controlStyle={{marginBottom: 0}}
                                   inputStyle={{backgroundColor: colors.white}}
                                   value={item.templatedata?.description_creator}/>
                </Td>
            </Tr>}
        </>
    )
}

const controlStyle = {margin: 0, height: "100%"};
const inputStyle = {border: 0, height: "100%", padding: 0};