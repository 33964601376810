import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FrameContext} from "../screen-tool";
import {Image, Label} from "../../../01_atoms";
import "./tool-password.scss";
import {Modal} from "../../../03_organisms";
import {ToolCoder} from "../../../tools/coder/tool-coder";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

export const ToolPassword = ({password, successHandler, label, isPasswordModal, modalIcon, coderData = {}}) => {
    const isFrameFinish = useContext(FrameContext).isFrameFinish;
    const gameData = useSelector(state => state.gameData);
    const {adventureData={}, adventureType} = gameData || {};
    const [success, setSuccess] = useState(isFrameFinish);
    const [value, setValue] = useState(isFrameFinish ? password : "");

    useEffect(() => {
        if (value && value?.toLowerCase() === password?.toLowerCase()) {
            setSuccess(true);
            successHandler();
        }
    }, [password, successHandler, value]);

    const modal = (
        <Modal triggerStyle={{backgroundColor: "transparent"}} triggerValue={<Image src={modalIcon}/>}>
            <ToolCoder {...coderData} handleFrameFinish={successHandler}/>
            {isFrameFinish && <Link to={`/menu/${adventureData?.id}`}>
                <button className={"tool-password-continue"}>{adventureType?.id === 332 ? "Continue" : "המשך"}</button>
            </Link>}
        </Modal>
    );

    const field = (
        <>
            {label && <Label value={label} className="tool-password-label"/>}
            <div className="tool-password-input">
                <input onChange={e => setValue(e.target.value)}
                       className="tool-password-input"
                       value={value}
                       placeholder="הכנס סיסמא"
                       disabled={success}
                       type="text"/>
            </div>
        </>
    );

    return (
        <div className="tool-password-field">
            {isPasswordModal ? modal : field}
        </div>
    )
};

ToolPassword.propTypes = {
    password: PropTypes.string.isRequired,
    successHandler: PropTypes.func.isRequired
}