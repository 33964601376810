import React from "react";
import SVG_CLOSE from "../../../assets/images/svg-icons/svg-close-blue.svg";
import "./filter-indicators.scss";

export const FilterIndicators = ({items, removeAllFilters, length}) => {
    return (
        <div className="filter-indicators">
            <label>{`סינון (נמצאו ${length} רשומות):`}</label>
            {items.map((item, index) => {
                return (
                    <div className="filter-indicators-item" key={index}>
                        <img src={SVG_CLOSE} alt="close" onClick={item.removeFilter}/>
                        <span key={index}>{item.name}</span>
                    </div>
                )
            })}
            <div className="filter-indicators-item" onClick={removeAllFilters}>
                <span>נקה הכל</span>
            </div>
        </div>
    )
}