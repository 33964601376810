import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {colors, gutters} from '../../../00_base/variables';

const CardHeader = ({ children, style }) => {
    return (
        <Header className={'card-header'} {...style}>{children}</Header>
    )
};

const styles = {
    wrapper: {
        backgroundColor: 'transparent',
        borderBottom: `1px solid ${colors.border}`,
        padding: `${gutters.xs} ${gutters.sm}`
    }
};

const Header = styled.header`
    ${props => `
        background-color: ${props.backgroundColor};
        border-bottom: ${props.borderBottom};
        padding: ${props.padding};
    `}
`;

CardHeader.defaultProps = {
    style: styles.wrapper
}

CardHeader.propTypes = {
    style: PropTypes.object
};

export default CardHeader;