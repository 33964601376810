import React, {useState, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import arrayMove from 'array-move';
import {RiddleRow} from "./adventure-type-sequence-riddle";
import {Button, SimpleLoader} from "../../../01_atoms";
import {SortableList} from "../../../03_organisms";
import {MdAdd} from "react-icons/md";
import classNames from "classnames";
import {createRiddle} from "../../../../store/actions/riddle-actions";
import {
    createAdventureData,
    reorderAdventureDataRiddles,
    updateAdventureData
} from "../../../../store/actions/adventure-data-actions";
import {
    selectActiveFrame,
    selectCurrentAdventureData,
    selectCurrentAdventureType,
    selectCurrentFrames,
    selectCurrentRiddles
} from "../../../../store/selectors";
import "./adventure-type-sequence.scss";
import {AuthContext} from "../../../../providers/auth-provider";
import FieldText from "../../../02_molecules/form-fields/text/m-field-text";

export const AdventureTypeSequence = ({adventureDataId, handleChange}) => {
    const adventureType = useSelector(selectCurrentAdventureType);
    const adventureData = useSelector(selectCurrentAdventureData);
    const riddles = useSelector(selectCurrentRiddles);
    const frames = useSelector(selectCurrentFrames);
    const activeFrame = useSelector(selectActiveFrame);

    const {isTom} = useContext(AuthContext)
    const [activeRiddleMenu, setActiveRiddleMenu] = useState(null);
    const [newRiddleLoading, setNewRiddleLoading] = useState(false);
    const [newSceneTitle, setNewSceneTitle] = useState("");

    const dispatch = useDispatch();

    const removeRiddle = (riddleId) => {
        if (window.confirm("למחוק את הסצנה?")) {
            const updatedRiddles = adventureData.riddles.filter(riddle => riddle !== riddleId);
            const updatedAdventureData = {...adventureData, riddles: updatedRiddles};
            dispatch(updateAdventureData(updatedAdventureData));
        }
    };


    const createNewAdventureData = () => {
        dispatch(createAdventureData({}, response => handleChange("adventuredata_id", response.id))
        )
    };

    const addRiddleHandler = () => {
        setNewRiddleLoading(true);
        const newRiddleData = {title: newSceneTitle || "שם הסצנה"};
        const finishLoading = () => setNewRiddleLoading(false);
        dispatch(createRiddle(newRiddleData, adventureData)).then(finishLoading);
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        const updatedRiddles = arrayMove(riddles, oldIndex, newIndex);
        dispatch(reorderAdventureDataRiddles(updatedRiddles.map(riddle => riddle.id)));
    };

    const riddlesToRender = adventureData.riddles?.map((riddleId, index) => {
        const riddle = riddles.find(item => item.id === riddleId);
        const indexStart = riddles.slice(0, index).map(riddle => riddle.riddlestemplates?.length).reduce((a, b) => a + b, 0);
        const riddleFrames = riddle?.riddlestemplates.map(templateId => frames.find(frame => frame.id === templateId)) || [];
        if(riddle) {
            return <RiddleRow riddle={riddle}
                              frames={riddleFrames}
                              index={index}
                              indexStart={indexStart}
                              menuActive={activeRiddleMenu === riddle?.id}
                              setActiveRiddleMenu={setActiveRiddleMenu}
                              removeRiddle={removeRiddle}
                              key={riddle?.id}/>
        }
        return <div/>
    });


    if (!adventureType?.adventuredata_id && !adventureDataId) {
        return (
            <div className="adventure-type-tab-item adventure-type-sequence">
                <Button className="add-riddle no-riddles" onClick={createNewAdventureData}>
                    התחל להוסיף חידות
                </Button>
            </div>
        )
    }

    const TITLE = (
        <h4 className="adventure-type-sequence-title">רצף ההרפתקה</h4>
    );

    const ADVENTURE_DATA_ID_FIELD = (
        isTom && <FieldText disabled={true}
                            name="adventuredata_id"
                            label="adventureDataId"
                            value={adventureDataId || adventureType.adventuredata_id}
                            changeHandler={handleChange}/>
    );

    const RIDDLES = (
        riddlesToRender && <SortableList items={riddlesToRender}
                                         itemClassName={"scene-wrapper"}
                                         ItemTag="li"
                                         ListTag="ul"
                                         useDragHandle={true}
                                         onSortEnd={onSortEnd}/>
    );

    const NEW_RIDDLE_LOADER = newRiddleLoading && <SimpleLoader/>

    const ADD_RIDDLE = (
        <div className="add-riddle-wrapper">
            <button className="add-riddle" onClick={addRiddleHandler}>
                <MdAdd color="#fff" size={20}/>
            </button>
            <input type="text"
                   className="add-riddle-input"
                   placeholder="שם הסצנה"
                   value={newSceneTitle}
                   onKeyDown={e => e.key === "Enter" && addRiddleHandler()}
                   onChange={(e) => setNewSceneTitle(e.target.value)}/>
        </div>
    );

    const className = classNames("adventure-type-tab-item adventure-type-sequence", {frameActive: !!activeFrame})

    return (
        <div className={className}>
            {TITLE}
            {ADVENTURE_DATA_ID_FIELD}
            {RIDDLES}
            {NEW_RIDDLE_LOADER}
            {ADD_RIDDLE}
        </div>
    )
}