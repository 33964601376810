import React, {useReducer} from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import {MdInsertEmoticon, MdPalette, MdTextFormat} from "react-icons/md";
import {StyledButton, StyledDiv} from "../../ui/css-general/css-styled";
import {CssGeneral} from "../../ui/css-general/css-general";
import {FieldColor, FieldOptions, FieldText, FilesUploader} from "../../../02_molecules";
import "./create-tool-coder.scss";

const COLORS = "colors";
const DIGITS = "digits";
const ICONS = "icons";

const coderTypes = [
    {id: 0, label: <MdTextFormat size={20}/>, value: DIGITS},
    {id: 1, label: <MdPalette size={20}/>, value: COLORS},
    {id: 2, label: <MdInsertEmoticon size={20}/>, value: ICONS},
];

const initialColorsList = ["#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000", "#000"];
const initialIconsList = ["", "", "", "", "", "", "", "", "", "", "", ""];

const CreateToolCoder = (
    {
        password,
        colors = initialColorsList,
        icons = initialIconsList,
        type = DIGITS,
        passwordEditable,
        buttonsStyle,
        lineStyle,
        handleToolDataChange
    }) => {
    const coderReducer = (state, action) => {
        state[action.index] = action.value;
        handleToolDataChange([action.name], state);
        return state;
    };

    const [colorsList, setColorsList] = useReducer(coderReducer, colors);
    const [iconsList, setIconsList] = useReducer(coderReducer, icons);

    // const handleIconsChange = (list) => {
    //     if (list.length > 0 && list.length < 12) {
    //         for (let i = 0; i < 12; i++) {
    //             iconsList[i] = list[i] || defaultIcon;
    //         }
    //         setIconsList(iconsList);
    //         return;
    //     }
    //     setIconsList(list);
    // };

    const findCoderType = value => {
        return coderTypes.find(item => item.value === value)
    };

    const handleButtonStyleChange = (name, value) => {
        handleToolDataChange("buttonsStyle", {...buttonsStyle, [name]: value});
    };

    const handleLineStyleChange = (name, value) => {
        handleToolDataChange("lineStyle", {...lineStyle, [name]: value});
    };

    const ButtonPreviewComponent = () => {
        return (
            <StyledButton {...buttonsStyle} className="coder-create-preview-button">1</StyledButton>
        )
    };

    const LinePreviewComponent = () => {
        return (
            <StyledDiv {...lineStyle} className="coder-create-preview-line"/>
        )
    };

    const renderColor = (color, index) => {
        const changeHandler = (name, value) => setColorsList({index: index, value: value, name: "colors"});
        const label = index === 9 ? "#" : index === 10 ? "0" : index === 11 ? "*" : index + 1;
        return <div className="coder-color-item">
            <FieldColor changeHandler={changeHandler}
                        label={label}
                        dir="horizontal"
                        labelStyle={{width: "100%"}}
                        controlStyle={{flexWrap: "wrap"}}
                        inputStyle={{marginRight: "5px", maxWidth: "100px"}}
                        name="coder-color"
                        value={color}/>
        </div>
    };

    const uploadIcons = (
        <div className="create-coder-icons-preview">
            {iconsList.map((icon, index) => {
                const changeHandler = (name, value) => {
                    setIconsList({index: index, value: value, name: "icons"});
                };
                const triggerValue = index === 9 ? "#" : index === 10 ? "0" : index === 11 ? "*" : index + 1;
                return <CreateCoderIcon {...buttonsStyle} className="create-coder-icon" key={index}>
                    <FilesUploader name="icon"
                                   multiple={false}
                                   index={index}
                                   value={icon}
                                   triggerValue={triggerValue}
                                   previewWidth={70}
                                   previewHeight={70}
                                   changeHandler={changeHandler}
                                   controlStyle={{width: "100%"}}/>
                </CreateCoderIcon>
            })}
        </div>
    );


    return (
        <div className="create-tool coder-create">
            <FieldText changeHandler={handleToolDataChange}
                       value={password}
                       label="סיסמת הקודן"
                       controlStyle={{maxWidth: "250px"}}
                       name="password"/>

            <FieldOptions label={`סוג הקודן: `}
                          name="type"
                          options={coderTypes}
                          dir={"horizontal"}
                          controlStyle={{display:"flex", alignItems:"center"}}
                          value={findCoderType(type)}
                          changeHandler={(name, value) => {
                              handleToolDataChange(name, value)
                          }}/>

            {/*<FieldSelect options={coderTypes}*/}
            {/*             label="סוג הקודן"*/}
            {/*             name="type"*/}
            {/*             value={findCoderType(type)}*/}
            {/*             controlStyle={{maxWidth: "250px"}}*/}
            {/*             changeHandler={(name, value) => {*/}
            {/*                 handleToolDataChange(name, findCoderType(value.id).value)*/}
            {/*             }}/>*/}

            {type === ICONS && <div className="coder-icons">{uploadIcons}</div>}

            {type === COLORS && <div className="coder-colors">{colorsList.map(renderColor)}</div>}


            <CssGeneral title="עיצוב כפתור"
                        className="coder-style-section"
                        changeHandler={handleButtonStyleChange}
                        PreviewComponent={ButtonPreviewComponent}
                        defaultProps={buttonsStyle}/>

            <CssGeneral title="עיצוב קו שדה סיסמא"
                        className="coder-style-section"
                        changeHandler={handleLineStyleChange}
                        PreviewComponent={LinePreviewComponent}
                        defaultProps={lineStyle}/>
        </div>
    )
};


const CreateCoderIcon = styled.div`
    img {
        width: ${props => props.width || "40"}px;
    }
    // height: ${props => props.height || "40"}px;
`;

CreateToolCoder.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolCoder.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func,
    defaultProps: PropTypes.object
};

export default CreateToolCoder;