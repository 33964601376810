import React from "react";
import {RiddleSingleTemplate} from "./riddle-single-template";
import "./riddle-template.scss";
import {SortableList} from "../../../03_organisms/sortable/o-sortable";
import Button from "../../../01_atoms/button/a-button";

export const RiddleTemplates = ({indexStart, addNewFrame, frames, templatedata, reorderFrames, riddle_id, removeRiddleFrame}) => {
    const renderTemplateRow = (frame, index) => {
        return (
            <RiddleSingleTemplate frame={frame}
                                  templatedata={templatedata}
                                  removeFrame={() => removeRiddleFrame(frame.id)}
                                  index={indexStart + index + 1}
                                  riddle_id={riddle_id}
                                  key={frame.id}/>
        )
    };

    return (
        <div className="riddle-frames">
            <SortableList items={frames.map(renderTemplateRow)}
                          useDragHandle={true}
                          onSortEnd={reorderFrames}/>
            {addNewFrame &&
            <Button onClick={addNewFrame}>הוסף פריים</Button>
            }
        </div>
    )
};