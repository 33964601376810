import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from "react-redux";
import {colors} from "../../../00_base/variables";
import Timer from "../../../03_organisms/timer/timer";
import {Button, Heading, Span} from "../../../01_atoms";
import {MdKeyboardArrowRight} from 'react-icons/md';
import {arrowBackWrapper, clueTrigger, container, timerWrapper, toolTitle, wrapper} from "./tool-top-bar-style";

export const ToolTopBar = (
    {
        riddleData = {},
        tool = {},
        topBarTitle,
        topBarBgColor,
        topBarTextColor,
        topBarTitleSize,
        setClueActive,
        previewMode,
        frameModal,
        hasClues
    }) => {
    const gameData = useSelector(state => state.gameData);
    const {adventureType = {}, adventureData = {}, adventure = {}, riddles = []} = gameData || {};

    // const language = adventure.language;
    const adventureName = adventure.json_data && adventure.json_data.adventure_name;
    const isEnglish = adventureType?.id === 332;
    const noLobby = adventureType?.id === 324 || adventureType?.id === 330 || adventureType?.id === 332;
    const employeeBirthday = adventureType?.id === 324 || adventureType?.id === 330 || adventureType?.id === 332;

    return (
        <div id='tool-top-bar' style={{
            ...wrapper,
            backgroundColor: topBarBgColor || "#1e1d3d",
            position: previewMode ? 'absolute' : 'static'
        }}>

            <div style={{...arrowBackWrapper, visibility: employeeBirthday ? "hidden" : "visible"}}>
                <Link to={previewMode ? window.location.href : (noLobby ? `/riddle/${riddles[0].id}/1` : `/menu/${adventureData.id}`)}
                      style={{display: "flex", alignItems: "center"}}>
                    <MdKeyboardArrowRight size={30} color={colors.white}/>
                    <Span value={isEnglish ? "Go back" : "חזור"} color={colors.white}
                          style={{fontSize: "20px", paddingLeft: "15px"}}/>
                </Link>
            </div>

            {frameModal}

            {topBarTitle &&
            <Heading tag={'h1'}
                     value={topBarTitle?.replace("{name}", adventureName) || ""}
                     color={topBarTextColor}
                     style={{...toolTitle, fontSize: `${topBarTitleSize}px`}}/>}

            <div style={container}>
                {hasClues && <Button onClick={() => setClueActive(true)}
                                     style={clueTrigger}>
                    {isEnglish ? "Clue" : "רמז"}
                </Button>}
                <div style={timerWrapper}>
                    <Timer/>
                </div>
            </div>

        </div>
    )
};
