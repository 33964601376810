import React, {useContext} from "react";
import {Col, Container, Row} from "react-grid-system";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {LanguageContext, Translation} from "../../../languages";
import {DICT} from "../../../languages/dictionary-names";
import {ThemeContext} from "../../../providers/theme-provider";
import {colors} from "../../00_base/variables";
import {Button, Image} from "../../01_atoms";
import "./adventures-for-business-card.scss";

const IMAGE = "https://storage.googleapis.com/assets.youdoadventures.com/website/Illustration_Business.png";

export const AdventuresForBusinessCard = ({title, description}) => {
    const {userLanguage} = useContext(LanguageContext);
    const {mode} = useContext(ThemeContext);
    const textColor = colors[mode === "light" ? "black" : "white"];

    return (
        <Container className="adventures-for-business-card">
            <Row>
                <Col xs={12} md={6}>
                    <h3><Translation id={DICT.ADVENTURES_FOR_BUSINESSES_TITLE}/></h3>
                    <p style={{color:textColor}}><Translation id={DICT.ADVENTURES_FOR_BUSINESSES_DESCRIPTION}/></p>
                    <Button>
                        <Link to={`/${userLanguage}/businesses`}>
                        <Translation id={DICT.CLICK_HERE}/>
                        </Link>
                    </Button>
                </Col>
                <Col xs={12} md={6}>
                    <Image src={IMAGE} width={400}/>
                </Col>
            </Row>
        </Container>
    )
};

AdventuresForBusinessCard.defaultProps = {
    title: "הרפתקאות לעסקים",
    description: "פתרונות יצירתיים לעסקים הרפתקנים. משחקי בריחה שמתאימים בדיוק לצרכים שלכם עם התוכן, הערכים והמסרים שלכם."
};

AdventuresForBusinessCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string
};