import React, {useState} from "react";
import {MdClose, MdArrowDropDown, MdArrowDropUp} from 'react-icons/md';
import {Switcher} from "../../../01_atoms";
import {FieldColor, FieldSelect, FieldText} from "../../../02_molecules";
import {Td, Tr} from "../../../03_organisms/table";
import FilesUploader from "../../../02_molecules/form-fields/files-uploader/m-files-uploader";
import {ToggleIcon} from "../../../03_organisms";
import {ColorPickerBgText} from "../../../03_organisms/color-picker-bg-text/color-picker-bg-text";
import Label from "../../../01_atoms/text/a-label";

export const CreateToolSwitchesItem = (
    {
        id,
        index,
        removeSection,
        handleChange,
        topTitle,
        bottomTitle,
        trueLocation,
        status,
        answer,
        wrapperColor,
        buttonColor,
        fullOnImage,
        fullOffImage,
    }
) => {
    const [isOpen, setIsOpen] = useState(false);
    const changeHandler = (name, value) => handleChange(name, value, id);
    const options = [{id: 0, value: "top", label: "Top"},
        {id: 1, value: "bottom", label: "Bottom"},
        {id: 2, value: "left", label: "Left"},
        {id: 3, value: "right", label: "Right"},
    ];
    return (
        <>
            <Tr>
                <Td>{index + 1}</Td>
                <Td>

                    <FieldText name="topTitle"
                               value={topTitle}
                               placeholder='כותרת עליונה'
                               index={index}
                               controlStyle={controlStyle}
                               inputStyle={{...inputStyle, maxWidth: "110px"}}
                               changeHandler={changeHandler}/>

                    <FieldText name="bottomTitle"
                               value={bottomTitle}
                               placeholder='כותרת תחתונה'
                               index={index}
                               controlStyle={controlStyle}
                               inputStyle={{...inputStyle, maxWidth: "110px"}}
                               changeHandler={changeHandler}/>

                </Td>
                <Td>
                    <FieldSelect name="trueLocation"
                                 value={trueLocation}
                                 options={options}
                                 index={index}
                                 controlStyle={controlStyle}
                                 inputStyle={inputStyle}
                                 changeHandler={changeHandler}/>
                </Td>
                <Td>
                    <Switcher name="status"
                              checked={status}
                              changeHandler={changeHandler}/>
                </Td>
                <Td>
                    <Switcher name="answer"
                              checked={answer}
                              changeHandler={changeHandler}/>
                </Td>
                <Td>
                    <div className="d-flex">
                        <div className="px-3">
                            <ToggleIcon toggle={() => setIsOpen(!isOpen)}/>
                        </div>
                        <div className="px-3">
                            <MdClose onClick={() => removeSection(id)}/>
                        </div>
                    </div>
                </Td>
            </Tr>
            {isOpen &&
            <Tr>
                <Td>{""}</Td>
                <Td>
                    <Label>רקע/כפתור</Label>
                    <ColorPickerBgText textName="buttonColor"
                                       bgName="wrapperColor"
                                       textColor={buttonColor}
                                       bgColor={wrapperColor}
                                       handleChange={changeHandler}/>
                </Td>
                <Td>
                    <FilesUploader label="כפתור ON"
                                   triggerValue={"כפתור ON"}
                                   multiple={false}
                                   previewWidth={100}
                                   previewHeight={100}
                                   name={"fullOnImage"}
                                   value={fullOnImage}
                                   changeHandler={changeHandler}/>
                </Td>
                <Td>
                    <FilesUploader label="כפתור OFF"
                                   triggerValue={"כפתור OFF"}
                                   multiple={false}
                                   previewWidth={100}
                                   previewHeight={100}
                                   name={"fullOffImage"}
                                   value={fullOffImage}
                                   changeHandler={changeHandler}/>

                </Td>
            </Tr>
            }
        </>
    )
};

const controlStyle = {
    margin: 0,
    paddingLeft: "5px",
    paddingRight: "5px"
};

const inputStyle = {
    height: '3rem',
};
//
// {key: "topTitle", title: "כותרת עליונה", type: "String"},
// {key: "bottomTitle", title: "כותרת תחתונה", type: "String"},
// {key: "trueLocation", title: "מיקום האמת", type: "Dropdown", options: ['Top', 'Right', 'Bottom', 'Left']},
// {key: "status", title: "סטטוס דיפולטי", type: "Boolean"},
// {key: "answer", title: "תשובה רצויה", type: "Boolean"},
// {key: "wrapperColor", title: "צבע רקע", type: "Color"},
// {key: "buttonColor", title: "צבע כפתור", type: "Color"},