import React, {useContext} from 'react';
import {MdCropRotate, MdDelete, MdOpenInNew} from 'react-icons/md';
import {AuthContext} from "../../../../providers/auth-provider";

const FileActions = ({deleteHandler, imageSrc, cropToggleHandler, smallPreview, fileIndex}) => {
    const {isAdmin} = useContext(AuthContext);

    return (
        <div className={"image-preview-actions"}>

            {!smallPreview &&
            <div className="image-preview-actions-btn">
                <button onClick={() => cropToggleHandler('' + fileIndex)}>
                    <MdCropRotate size={20} className="trigger"/>
                </button>
            </div>
            }

            <div className="image-preview-actions-btn">
                <button onClick={() => deleteHandler('' + fileIndex)}>
                    <MdDelete size={smallPreview ? 18 : 20} className="trigger"/>
                </button>
            </div>

            {isAdmin && !smallPreview &&
            <div className="image-preview-actions-btn">
                <button onClick={() => window.open(imageSrc)}>
                    <MdOpenInNew size={20} className="trigger"/>
                </button>
            </div>
            }

        </div>
    )
};

export default FileActions;