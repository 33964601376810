import React from "react";
import {MdClose, MdDragHandle} from 'react-icons/md';
import {SortableHandle} from "react-sortable-hoc";
import {FieldColor, FieldText, FilesUploader} from "../../../02_molecules";
import {Table, Td, Tr} from "../../../03_organisms/table/";

export const CreateToolSliderItem = (
    {
        id,
        index,
        removeSection,
        handleChange,
        image,
        title,
        description,
        color
    }
) => {
    const DraggableHandle = SortableHandle(() => {
        return (
            <div className="tool-slider-drag-handle">
                <MdDragHandle size={20} color={"#282626"}/>
            </div>
        )
    });
    const changeHandler = (name, value) => handleChange(name, value, id);
    return (

                <Table className="slider-create-section">
                    <tbody>
                    <Tr>
                        <Td><DraggableHandle/></Td>
                        <Td>{index + 1}</Td>
                        <Td width="100px">
                            <FilesUploader value={image}
                                           placeholder={index + 1}
                                           index={index}
                                           name="image"
                                           previewWidth={100}
                                           previewHeight={100}
                                           multiple={false}
                                           changeHandler={changeHandler}/>
                        </Td>
                        <Td>
                            <FieldText name="title"
                                       value={title}
                                       placeholder='כותרת'
                                       index={index}
                                       controlStyle={controlStyle}
                                       inputStyle={inputStyle}
                                       changeHandler={changeHandler}/>
                        </Td>
                        <Td>
                            <FieldText name="description"
                                       value={description}
                                       placeholder='תיאור'
                                       index={index}
                                       controlStyle={controlStyle}
                                       inputStyle={inputStyle}
                                       changeHandler={changeHandler}/>
                        </Td>
                        <Td style={{width: "30px"}}>
                            <FieldColor changeHandler={changeHandler}
                                        name={"color"}
                                        controlStyle={controlStyle}
                                        inputStyle={inputStyle}
                                        value={color}/>
                        </Td>
                        <Td className="trivia-create-section-remove">
                            <MdClose onClick={() => removeSection(id)}/>
                        </Td>
                    </Tr>
                    </tbody>
                </Table>
    )
};

const controlStyle = {
    margin: 0,
    paddingLeft: "5px",
    paddingRight: "5px"
};

const inputStyle = {
    height: '3rem'
};