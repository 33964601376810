import Adventure from "./adventure";
import AdventureData from "./adventureData";
import Riddle from "./riddle";
import AdventureType from "./adventureType";
import Category from "./category";
import Coupon from "./coupon";
import Affiliate from "./affiliate";
import Blog from "./blog";
import Language from "./language";

export const adventureObject = (adventure) => {
    return new Adventure(
        adventure.id,
        adventure.title,
        adventure.adventure_name,
        adventure.adventuretype,
        adventure.adventuredata_id,
        adventure.identifier,
        adventure.is_generic,
        adventure.is_main,
        adventure.is_paid,
        adventure.is_published,
        adventure.json_data,
        adventure.passcode,
        adventure.thumbnail,
        adventure.user_email,
        adventure.yearend_csv_batch,
        adventure.yearend_email_send,
        adventure.calculated_price,
        adventure.language,
        adventure.created_at,
    );
};

export const adventureTypeObject = (adventureType) => {
    if (adventureType) {
        return new AdventureType(
            adventureType.id,
            adventureType.name,
            adventureType.description,
            adventureType.logo_url,
            adventureType.game_subdomain,
            adventureType.theme_id,
            adventureType.guide_id,
            adventureType.base_price,
            adventureType.opening_video_url,
            adventureType.final_video_url,
            adventureType.storyboard_id,
            adventureType.game_board,
            adventureType.answers_file,
            adventureType.is_active,
            adventureType.show_in_site,
            adventureType.description_short,
            adventureType.description_homepage,
            adventureType.description_inner,
            adventureType.tags,
            adventureType.adventuredata_id,
            adventureType.adventuretemplate_id,
            adventureType.age,
            adventureType.players,
            adventureType.duration,
            adventureType.instructions_main,
            adventureType.instructions_title,
            adventureType.instructions_image,
            adventureType.site_image_main,
            adventureType.site_image_mobile,
            adventureType.site_image_tablet,
            adventureType.site_image_desktop,
            adventureType.entrance_cta,
            adventureType.entrance_color,
            adventureType.entrance_background,
            adventureType.entrance_image_desktop,
            adventureType.entrance_image_mobile,
            adventureType.lobby_title,
            adventureType.lobby_subtitle,
            adventureType.lobby_image_desktop,
            adventureType.lobby_image_mobile,
            adventureType.game_title,
            adventureType.game_subtitle,
            adventureType.personal_customize,
            adventureType.user_id,
            adventureType.faq,
            adventureType.welcome_title_size,
            adventureType.bg_color,
            adventureType.text_color,
            adventureType.continue_bg_color,
            adventureType.continue_text_color,
            adventureType.logo_type,
            adventureType.welcome_pre_title
        );
    }
    return null


};

export const adventureDataObject = (adventureData) => {
    return new AdventureData(
        adventureData.id,
        adventureData.adventurename,
        adventureData.description,
        adventureData.description_homepage,
        adventureData.themeid,
        adventureData.istimeron,
        adventureData.riddles,
        adventureData.thumbnail,
        adventureData.thumbnail_mobile,
        adventureData.thumbnail_tablet,
        adventureData.thumbnail_desktop,
        adventureData.welcome_screen_url_mobile,
        adventureData.welcome_screen_url_tablet,
        adventureData.welcome_screen_url_desktop,
        adventureData.adventure_logo,
        adventureData.adventure_type,
        adventureData.is_main,
        adventureData.title_label,
        adventureData.icon_label,
        adventureData.icon,
        adventureData.video_url,
        adventureData.created_at,
    );
};

export const riddlesObject = (riddles) => {
    return riddles.map(riddle => new Riddle(
        riddle.id,
        riddle.title,
        riddle.riddle_name,
        riddle.subtitle,
        riddle.description,
        riddle.description_short,
        riddle.templatedata,
        riddle.thumbnail,
        riddle.videourl,
        riddle.user_email,
        riddle.is_published,
        riddle.keywords,
        riddle.accessories,
        riddle.payment_type,
        riddle.print_files,
        riddle.riddlestemplates,
        riddle.tags,
        riddle.created_at,
        riddle.instructions,
        riddle.is_main,
        riddle.show_in_store,
        riddle.editable,
    ));
};

export const categoriesObject = (category) => {
    return new Category(
        category.id,
        category.title,
        category.category_name,
        category.icon,
        category.description,
        category.description_short,
        category.mock_data,
        category.created_at
    )
};

export const couponObject = (coupon) => {
    return new Coupon(
        coupon.id,
        coupon.name,
        coupon.type,
        coupon.sum,
        coupon.max_uses,
        coupon.is_active,
        coupon.adventuretype_id,
        coupon.count,
        coupon.created_at
    )
};

export const affiliateObject = (affiliate) => {
    return new Affiliate(
        affiliate.id,
        affiliate.name,
        affiliate.description,
        affiliate.logo,
        affiliate.colors
    )
};

export const blogObject = (blog) => {
    return new Blog(
        blog.id,
        blog.title,
        blog.subtitle,
        blog.description,
        blog.description_short,
        blog.url,
        blog.ordinal_position,
        blog.main_image,
        blog.thumbnail_image,
        blog.language_id,
        blog.is_active,
        blog.created_at
    )
};

export const languageObject = (language) => {
    return new Language(
        language.id,
        language.title,
        language.name,
        language.translations,
        language.flag
    )
}