import React, {useEffect, useState} from 'react';
import {Element, VBox} from 'react-stylesheet';
import {colors, font_size, gutters} from '../../../00_base/variables';
import FilesUploader from '../files-uploader/m-files-uploader';
import Iframe from '../../../01_atoms/iframe';
import FieldText from '../text';
import {Circle, Label, Span} from '../../../01_atoms';
import SimpleLoader from "../../../01_atoms/loaders/simple-loader/simple-loader";
import {MdClose} from "react-icons/md";
import {getYoutubeId, getYoutubeUrl, validateYouTubeUrl} from "../../../../helpers/youtube-helper";

const FieldVideo = (
    {
        index,
        videoType,
        value,
        changeHandler,
        name,
        height,
        labelStyle = {width: "100%", textAlign: "center", margin: "6px 0", fontSize: "16px"},
        fileUploaded,
        textControlStyle,
        maxHeight,
        triggerValue,
        triggerStyle = {width: "100%"},
        alertButton,
        buttonWidth,
        controlStyle,
        special,
        width,
        label,
        buttonColor,
        buttonBackgroundColor,
        fileLabel,
        block,
        center,
        videoStyle,
        disable,
        loop,
        muted,
        hideTextField,
        PreviewComponent,
        hideButtonTrigger,
        triggerTip,
        fileCta = 'לחץ',
        urlLabel = 'הכניסו קישור',
        controls = true,
        autoPlay = false,
        previewWidth = '200px',
        previewHeight = '100px'
    }) => {
    const [url, setUrl] = useState(value || "");
    const [isLoading, setIsLoading] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [sourceType, setSourceType] = useState( validateYouTubeUrl(value) ? 1 : 0);

    useEffect(() => {

        setIsLoading(true);

        setTimeout(() => {
            setIsLoading(false);
        }, 1000)
    }, [url]);

    const handleFileChange = (name, value, isValid) => {
        setUploadingFile(true);
        setUrl(typeof value === "string" ? value : value[0]);
        changeHandler(name, value, isValid, index && index);
    };


    const handleLinkChange = (name, value, isValid) => {
        const isYoutube = validateYouTubeUrl(value);
        let url = isYoutube ? getYoutubeUrl(getYoutubeId(value)) : value;
        if (!uploadingFile) {
            setUrl(url);
            setSourceType(isYoutube ? 1 : 0);
            changeHandler(name, url, isValid)
        } else {
            // setUploadingFile(false)
        }
    };

    const isUrlValid = !!url && typeof url === 'string';

    const defaultControlStyle = {
        padding: "10px",
        border: `2px dashed ${colors[isUrlValid ? "black" : "gray_3"]}`
    };

    const fieldTextSideIcon = isLoading ?
        <SimpleLoader style={{marginLeft: "10px"}} size={15}/> : isUrlValid ?
            <Circle background={"#000000"} onClick={() => handleFileChange(name, "", true)}>
                <MdClose color="#fff"/>
            </Circle> : "";
    return (
        <Element style={{...defaultControlStyle, ...controlStyle && controlStyle}} width={'100%'}>

            {!!label && <Label value={label} style={{...labelStyle}}/>}

            {!disable &&
            <VBox cursor={'pointer'} alignItems={"center"} justifyContent={'center'}>
                {PreviewComponent && value ?
                    <PreviewComponent handleFileChange={handleFileChange}/>
                    :
                    !!url && sourceType === 0 &&
                    <video width={width || '100%'}
                           id={'audio'}
                           style={{
                               ...videoStyle && videoStyle,
                               maxHeight: maxHeight || '50vh',
                               maxWidth: '100%',
                               height: height || 'auto',
                               width: width || '100%',
                               display: block && 'block',
                               border: '0',
                               textAlign: center ? 'center' : 'initial'
                           }}
                           autoPlay={autoPlay}
                           muted={muted}
                           loop={loop}
                           controls={controls}>
                        <source src={url} type="video/mp4"/>
                    </video>
                }

                {sourceType === 1 && isUrlValid &&
                <Iframe src={url}
                        width={width || 560}
                        height={height || 315}
                        center={center}/>
                }

                {sourceType === 2 && isUrlValid &&
                <audio controls>
                    <source src={url} type="audio/ogg"/>
                    <source src={url} type="audio/mpeg"/>
                </audio>
                }

                {!hideButtonTrigger && !isUrlValid &&
                <FilesUploader maxFiles={1}
                               label={fileLabel}
                               multiple={false}
                               shrinkImage={false}
                               fileUploaded={fileUploaded}
                               triggerValue={triggerValue}
                    // triggerHeight={'36px'}
                               accept={'video/*,audio/*'}
                               cta={videoType === 1 ? fileCta : url ? url : fileCta}
                               changeHandler={handleFileChange}
                               value={url || ''}
                               initalValue={url || ''}
                               videoField={true}
                               center={center}
                               triggerStyle={triggerStyle}
                               previewWidth={previewWidth}
                               previewHeight={previewHeight}
                               buttonBackgroundColor={buttonBackgroundColor || 'transparent'}
                               buttonColor={buttonColor && buttonColor}
                               name={name}
                               triggerSize={15}
                               alertButton={alertButton}
                               buttonWidth={buttonWidth}
                               controlStyle={{width: "100%"}}
                    // hideEditButton={true}
                               triggerTip={triggerTip}
                />
                }

                {!hideTextField && !hideButtonTrigger &&
                <Span fontSize={font_size.md}
                      style={{
                          padding: `0 ${gutters.sm}`,
                          height: '36px',
                          display: 'flex',
                          alignItems: 'center',
                          textAlign: 'center',
                          visibility: isUrlValid ? "hidden" : "visible"
                      }}>או</Span>}

                {!hideTextField &&


                <FieldText placeholder={urlLabel}
                           changeHandler={handleLinkChange}
                           value={url}
                           labelStyle={{opacity: 0}}
                           height={height ? height : 'auto'}
                           controlStyle={{...textControlStyle, opacity: isLoading ? .6 : 1}}
                           special={special}
                           border={special ? '1px solid' : '0'}
                           name={name}
                           sideIcon={fieldTextSideIcon}
                           center={center}/>
                }
            </VBox>
            }


        </Element>
    )
}

export default FieldVideo;