import React, {useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import classNames from "classnames";
import PropTypes from "prop-types";
import {AuthContext} from "../../../providers/auth-provider";
import {LanguageSelector} from "../../../languages/language-selector";
import {LanguageContext, Translation} from "../../../languages";
import {DICT} from "../../../languages/dictionary-names";
import {colors, font_size, gutters} from '../../00_base/variables';
import {Button, Image, Switcher} from "../../01_atoms";
import {AppLogo} from "../../02_molecules";
import {IconBars, IconCloseX} from "../../02_molecules/icons";
import {Navigation, UserProgressAdventures} from "../../03_organisms";
import {adminNavigation, mainNavigation, userNavigation} from "./header-navigation-lists";
import {useSelector} from "react-redux";
import {selectCurrentAdventure, selectUserAdventures} from "../../../store/selectors";
import "./app-header.scss";
import {RouteContext} from "../app-content/l-app-content";
import {ScrollContext} from "../scroll-to-top/l-scroll-to-top";
import {MdBrightnessHigh, MdBrightnessLow, MdBrightnessMedium} from "react-icons/md";
import {ThemeContext} from "../../../providers/theme-provider";

const AppHeader = props => {
    const {isLogin, isAdmin, logout, userData} = useContext(AuthContext);
    const {userLanguage, dictionary} = useContext(LanguageContext);
    const {mode, toggle} = useContext(ThemeContext);
    const {pathname} = useContext(RouteContext);
    const {isScrolled} = useContext(ScrollContext);
    const userAdventures = useSelector(selectUserAdventures);
    const currentAdventure = useSelector(selectCurrentAdventure);
    const history = useHistory();
    const userName = userData.name;

    const [sideMenuActive, setSideMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);

    const mainNavigationItems = mainNavigation(userLanguage);
    const adminNavigationItems = adminNavigation(isAdmin, userLanguage);
    const userNavigationItems = userNavigation(isLogin, logout, userLanguage).concat(adminNavigationItems);
    const mobileItems = mainNavigationItems.slice(1).concat(userNavigationItems);
    const progressAdventures = userAdventures.filter(adventure => !adventure.is_paid);
    const hasProgressAdventures = isLogin && progressAdventures.length > 0;
    const lightMode = mode === "light";


    const itemStyle = {
        display: "flex",
        alignItems: "center",
        fontSize: font_size.base,
        padding: `0 ${gutters.md}`,
        color: colors.gray_10,
        textTransform: 'uppercase',
    };
    const adventureDetailsStyle = {
        opacity: isScrolled ? 1 : 0,
        transition: "opacity .3s "
    }
    useEffect(() => {
        setMobileMenuActive(false);
        setSideMenuActive(false);
    }, [history.location.pathname])

    const minimize = history.location.pathname.includes("create-adventure");

    const toggleTheme = () => toggle(lightMode ? "dark" : "light");
    const ThemeIcon = lightMode ? MdBrightnessLow : MdBrightnessHigh;
    const textColor = lightMode ? colors.black : colors.white;

    const MAIN_NAVIGATION = !minimize && (
        <Navigation className={"app-header-min-nav"}
                    items={mainNavigationItems}
                    childNavigationStyle={{
                        border: `1px solid ${lightMode ? colors.black : colors.white}`,
                        backgroundColor: lightMode ? colors.white : colors.black
                    }}
                    itemStyle={itemStyle}/>
    );

    const USER_MENU = (
        <div className={classNames("app-header-side-menu", {active: sideMenuActive})}>
            {(hasProgressAdventures) && <UserProgressAdventures/>}
            <Navigation dir={hasProgressAdventures ? "column" : "column"}
                        items={userNavigationItems.map(item => {
                            return {...item, style: {justifyContent: "center", color: lightMode ? "#000" : "#fff"}}
                        })}
                        listStyle={{alignItems: "center"}}
                        itemStyle={itemStyle}/>
        </div>
    );

    const IconBurger = mobileMenuActive ? IconCloseX : IconBars;
    const BURGER_MENU = (
        <div className="app-header-mobile-trigger">
            <IconBurger style={{cursor: "pointer"}}
                        color={textColor}
                        onClick={() => setMobileMenuActive(!mobileMenuActive)}/>
        </div>
    );

    const MOBILE_MENU = (
        <Navigation className={classNames("mobile-navigation", {active: mobileMenuActive})}
                    dir='column'
                    navActiveStyle={{backgroundColor: 'red'}}
                    items={mobileItems}/>
    );

    const CREATE_ADVENTURE_DETAILS = pathname.includes("create-adventure") && (
        <div className={"d-none d-md-flex align-items-center"} style={adventureDetailsStyle}>
            <Image src={currentAdventure.thumbnail} height={30}/>
            <h4 className={"my-0 mx-3"} style={{whiteSpace: "nowrap", color: textColor}}>{currentAdventure.title}</h4>
        </div>
    );

    const LEFT = (
        <div className="app-header-item app-header-left">
            <AppLogo width={50} className="d-none d-md-block"/>
            {MAIN_NAVIGATION}
            {BURGER_MENU}
        </div>
    );

    const CENTER = (
        <div className="app-header-item app-header-center">
            <AppLogo width={50} className="d-md-none"/>
            {CREATE_ADVENTURE_DETAILS}
        </div>
    );

    const RIGHT = (
        <div className="app-header-item app-header-right px-3 px-sm-5">
            {isLogin && <ThemeIcon onClick={toggleTheme}
                                   color={textColor}
                                   cursor="pointer"
                                   className={`ml-${(minimize || !isAdmin) ? 3 : 0}`}/>}

            {isAdmin && !minimize && <LanguageSelector/>}

            <Button
                onClick={() => isLogin ? setSideMenuActive(!sideMenuActive) : history.push(`/${userLanguage}/login`)}
                background={"transparent"}
                className={"px-0"}
                style={{whiteSpace: "nowrap"}}
                size={"xs"}
                color={textColor}>
                {!!isLogin ? `${dictionary.hello}, ${userName}` : <Translation id={DICT.LOGIN}/>}
            </Button>
        </div>
    );

    const HEADER_ROW = (
        <div className="app-header-row position-relative">
            {LEFT}
            {CENTER}
            {RIGHT}
            {USER_MENU}
        </div>
    );

    return (
        <>
            <header {...props} className={`app-header ${props.className}`}>
                <div className={"container"}>
                    {HEADER_ROW}
                </div>
            </header>

            {MOBILE_MENU}
        </>
    )
};

AppHeader.defaultProps = {
    className: '',

};
AppHeader.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,

};

export default AppHeader;