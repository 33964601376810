import React, {useContext} from "react";
import {CreateAdventureContext} from "../screen-adventure-create";
import "./riddle-instructions.scss";

export const RiddleInstructions = ({title, subtitle, description}) => {
    const adventure_name = useContext(CreateAdventureContext).adventure_name;
    return (
        <div className="riddle-instructions">
            {title && <p className="riddle-instructions-title color-black">{title.replaceAll("{name}", adventure_name)}</p>}
            {subtitle && <p className='riddle-instructions-subtitle color-black'>{subtitle.replaceAll("{name}", adventure_name)}</p>}
            {description && <p className='riddle-instructions-description color-black'>{description.replaceAll("{name}", adventure_name)}</p>}
        </div>
    )
}