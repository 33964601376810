class AdventureData {
    constructor(
        id,
        adventurename,
        description,
        description_homepage,
        themeid,
        istimeron,
        riddles,
        thumbnail,
        thumbnail_mobile,
        thumbnail_tablet,
        thumbnail_desktop,
        welcome_screen_url_mobile,
        welcome_screen_url_tablet,
        welcome_screen_url_desktop,
        adventure_logo,
        general_details,
        adventure_type,
        is_main,
        title_label,
        icon_label,
        icon,
        created_at
    ) {
         this.id = id;
        this.title = adventurename;
        this.adventurename = adventurename;
        this.description = description;
        this.description_homepage = description_homepage;
        this.themeid = themeid;
        this.istimeron = istimeron;
        this.riddles = riddles;
        this.thumbnail = thumbnail;
        this.thumbnail_mobile = thumbnail_mobile;
        this.thumbnail_tablet = thumbnail_tablet;
        this.thumbnail_desktop = thumbnail_desktop;
        this.welcome_screen_url_mobile = welcome_screen_url_mobile;
        this.welcome_screen_url_tablet = welcome_screen_url_tablet;
        this.welcome_screen_url_desktop = welcome_screen_url_desktop;
        this.adventure_logo = adventure_logo;
        this.general_details = general_details;
        this.adventure_type = adventure_type;
        this.is_main = is_main;
        this.title_label = title_label;
        this.icon_label = icon_label;
        this.icon = icon;
        this.created_at = created_at;
    }
};

export default AdventureData;