import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as couponsActions from '../../../../store/actions/coupons-actions';
import {FieldCheckbox} from '../../../02_molecules';
import {
    Table,
    TableContainer,
    tableHeader,
    TableTitle,
    TableWrapper,
    Td,
    TdContent,
    Tr
} from "../../../03_organisms/table";
import {LoaderBulletList} from "../../../01_atoms";
import {NavigateItems} from "../../../03_organisms/navigation-groups/navigate-items";
import {Modal} from "../../../03_organisms";
import CreateCoupon from "../../coupon";

const ScreenCouponsGallery = () => {
    const [allCoupons, setAllCoupons] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [checkboxLoading, setCheckboxLoading] = useState(null);
    const [pageIndex, setPageIndex] = useState(0);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    useEffect(() => {

    }, [error]);

    useEffect(() => {
        try {
            dispatch(couponsActions.getAllCoupons(pageIndex)).then(response => {
                setAllCoupons(response);
                setIsLoading(false);
            });
        } catch (err) {
            setError(err.message);
        }

    }, [dispatch, pageIndex]);

    // const CouponDetails = ({coupon}) => {
    //     const type = coupon.type === 'sum' ? '₪' : '%';
    //     return (
    //         <HBox alignItems={'center'} height={'100%'}>
    //             <Paragraph style={{padding: `0 ${gutters.sm}`}}>
    //                 <Span fontWeight={'bold'} style={{whiteSpace: "nowrap", paddingLeft: '10px'}}>סוג הרפתקה:</Span>
    //                 {coupon.adventuretype_id}
    //             </Paragraph>
    //             <Paragraph style={{padding: `0 ${gutters.sm}`}}>
    //                 <Span fontWeight={'bold'} style={{paddingLeft: '10px'}}>סכום:</Span>
    //                 {coupon.sum}{type}
    //             </Paragraph>
    //             <Paragraph style={{padding: `0 ${gutters.sm}`}}>
    //                 <Span fontWeight={'bold'} style={{paddingLeft: '10px'}}>שימושים:</Span>
    //                 {coupon.max_uses}
    //             </Paragraph>
    //         </HBox>
    //     )
    // };

    const toggleItemActive = (id, isActive) => {
        setCheckboxLoading(id);
        dispatch(couponsActions.updateCoupon({
                id: id,
                is_active: isActive === 'פעיל' || isActive[0] === 'פעיל' ? 1 : 0
            },
            success => {
                setCheckboxLoading(null)
            }))
    };

    const tableHeaderItems = ["#", "שם קופון", "סוג הנחה", "סכום הנחה", "שימושים", "סטטוס"];

    const couponRow = (coupon, index) => {
        return (
            <Tr>
                <Td><TdContent backgroundColor="#fff">{coupon.id}</TdContent></Td>
                <Td><TdContent backgroundColor="#fff">{coupon.name}</TdContent></Td>
                <Td><TdContent backgroundColor="#fff">{coupon.type === 'sum' ? '₪' : '%'}</TdContent></Td>
                <Td><TdContent backgroundColor="#fff">{coupon.sum}</TdContent></Td>
                <Td><TdContent backgroundColor="#fff">{coupon.max_uses}</TdContent></Td>
                <Td><TdContent backgroundColor="#fff">
                    <FieldCheckbox checkboxLoading={checkboxLoading === coupon.id}
                                   hideOptionLabel={true} name={'isActive'} options={['פעיל']}
                                   value={!!coupon.is_active ? ['פעיל'] : []}
                                   size={18}
                                   controlStyle={{marginBottom: 0}}
                                   changeHandler={(name, value) => toggleItemActive(coupon.id, value)}/>
                </TdContent></Td>
            </Tr>
        )
    };

    const loaders = <><LoaderBulletList/><LoaderBulletList/></>
    return (
        <TableWrapper>
            <TableTitle>
                <h1>קופונים</h1>
            </TableTitle>
            <TableContainer fluid style={{width: "100%"}}>
                <Modal triggerValue={"צור קופון חדש"}>
                    <CreateCoupon/>
                </Modal>
                <Table border={0}>
                    <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                    {!isLoading && <tbody>{allCoupons.map(couponRow)}</tbody>}
                </Table>
                {isLoading ?
                    loaders
                    :
                    <NavigateItems length={1000}
                                   currentPageIndex={pageIndex}
                                   handleChange={setPageIndex}/>
                }
            </TableContainer>
        </TableWrapper>
    )
}
export default ScreenCouponsGallery;