import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {CreateToolTriviaSection} from "./create-tool-trivia-section";
import {Button} from "../../../01_atoms";
import {FrameUiPicker} from "../../../03_organisms";
import {Table} from "../../../03_organisms/table";
import "./create-tool-trivia.scss";
import {CreateToolTriviaFinal} from "./create-tool-trivia-final";

const initialSection = {
    id: 0,
    questionLabel: '',
    question: '',
    answer: 0,
    options: ['', '', '', ''],
    color: "#999"
};
export const createNewId = (arr) => Math.max(...arr.map(item => item.id)) + 1;

export const CreateToolTrivia = (
    {
        items = [],
        correctTitle,
        correctSubTitle,
        correctDescription,
        finalImageUrl,
        handleToolDataChange,
        sections_view,
    }) => {
    const [triviaItems, setTriviaItems] = useState(items);
    const [triviaSectionsView, setTriviaSectionsView] = useState(sections_view);

    const addSection = () => {
        const newId = createNewId(triviaItems);
        const updatedItems = triviaItems.concat({...initialSection, id: triviaItems.length > 0 ? newId : 0});
        setTriviaItems(updatedItems);
        handleToolDataChange("items", updatedItems);
    };

    const removeSection = (itemId) => {
        console.log("remove")
        const updatedItems = triviaItems.filter(item => item.id !== itemId);
        setTriviaItems(updatedItems);
        handleToolDataChange("items", updatedItems);
    };

    const handleChange = (name, value, id) => {
        triviaItems.find(item => item.id === id)[name] = value;
        setTriviaItems(triviaItems);
        handleToolDataChange("items", triviaItems)
    };

    const renderSection = (item, index) => {
        return <CreateToolTriviaSection {...item}
                                        index={index}
                                        handleChange={handleChange}
                                        removeSection={removeSection}
                                        key={item.id}/>
    };


    return (
        <div className="create-tool trivia-create">
            <div className="mr-auto">
                <FrameUiPicker value={triviaSectionsView}
                               name="sections_view"
                               label="אופן תצוגת השאלות: "
                               changeHandler={(name, value) => setTriviaSectionsView(value)}/>
            </div>
            <Table>
                <tbody>{triviaItems.map(renderSection)}</tbody>
            </Table>

            <Button outline className="tool-add-section" onClick={addSection}>הוסף שאלה</Button>

        </div>
    )
};

CreateToolTrivia.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData),
};

CreateToolTrivia.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            questionLabel: PropTypes.string,
            question: PropTypes.string,
            questionImageUrl: PropTypes.string,
            options: PropTypes.arrayOf(PropTypes.string),
            answer: PropTypes.number
        })
    ),
    submitHandler: PropTypes.func
};
