import React, {useRef} from "react";
import {applyMiddleware, createStore} from 'redux';
import {setConfiguration, useScreenClass} from 'react-grid-system';

import ReduxThunk from 'redux-thunk'
import {Provider} from 'react-redux';
import {BrowserRouter as Router} from "react-router-dom";
import '@fortawesome/fontawesome-svg-core/styles.css'
import {config, library} from '@fortawesome/fontawesome-svg-core'
import {faBars, faTimes, faUser} from '@fortawesome/free-solid-svg-icons'
import {faFacebook, faTwitter} from '@fortawesome/free-brands-svg-icons'
import adventureReducer from "./store/reducers/adventure-reducers";
import {breakpoints, containerWidths, gridColumns, gutterWidth,} from './components/00_base/configurations';
import {AppContent} from "./components/04_layout";
import {LanguageProvider} from "./providers/language-provider";
import {AuthProvider} from "./providers/auth-provider";
import "./app.scss";
import ScrollToTop from "./components/04_layout/scroll-to-top/l-scroll-to-top";
import {ThemeProvider} from "./providers/theme-provider";

config.autoAddCss = false;
library.add(faBars, faUser, faTimes, faTwitter, faFacebook);

const store = createStore(adventureReducer, applyMiddleware(ReduxThunk));

setConfiguration({
    defaultScreenClass: 'sm',
    maxScreenClass: 'lg',
    breakpoints: breakpoints,
    containerWidths: containerWidths,
    gutterWidth: gutterWidth,
    gridColumns: gridColumns,
});

export const addClassNameToRoot = (className) => {
    document.getElementById("root").setAttribute("class", className);
};

const App = () => {
    const elementRef = useRef(null);
    const screenClass = useScreenClass(elementRef);
    const wrapperClassName = `app-wrapper screen-${screenClass}`;

    return (
        <Provider store={store}>
            <Router>
                <LanguageProvider>
                    <AuthProvider>
                            <ScrollToTop>
                                <div id={'app-wrapper'} className={wrapperClassName}>
                                    <AppContent/>
                                </div>
                            </ScrollToTop>
                    </AuthProvider>
                </LanguageProvider>
            </Router>
        </Provider>
    )
};


export default App;