import React, {createContext, useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import {useScrollPosition} from "../../../hooks/scroll-hook";
import classNames from "classnames";

export const ScrollContext = createContext({
    isScrolled:null,
    y:0
})

const ScrollToTop = ({history, children}) => {
    const [scrolling, setScrolling] = useState(false)
    const [y, setY] = useState(0);
    useScrollPosition(({prevPos, currPos}) => {
        const targetScrollValue = history.location.pathname.includes("create-adventure") ? -130 : -50;
        setY(currPos.y);
        setScrolling(currPos.y < targetScrollValue);
    }, [scrolling]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            if (!history.location.pathname.includes("create-adventure")) {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
            }
        });
        return () => {
            unlisten();
        }
    }, [history]);

    return <ScrollContext.Provider value={{isScrolled: scrolling, y:y}}>
        <div className={classNames({scrolling})}>{children}</div>
    </ScrollContext.Provider>;
}

export default withRouter(ScrollToTop);

 