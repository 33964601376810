import {
    SVG_ADVENTURES,
    SVG_COUPON,
    SVG_CUSTOMERS,
    SVG_DASHBOARD,
    SVG_RESERVATIONS,
    SVG_SETTINGS,
    SVG_TEMPLATES
} from "../../../assets/images/svg-icons/";
import {MdAssignment, MdDashboard, MdLanguage, MdLaptopMac, MdVideogameAsset} from "react-icons/md";


export const sidebarCreateItems = [{
    id: 0,
    label: 'דשבורד',
    name: 'main',
    href: '/he/admin/dashboard',
    icon: SVG_DASHBOARD,
}, {
    id: 1,
    label: 'הרפתקאות',
    singleLabel: "הרפתקה",
    name: 'adventures',
    href: '/he/admin/adventures',
    icon: SVG_ADVENTURES,

}, {
    id: 2,
    label: 'תבניות',
    singleLabel: "תבנית הרפתקה",
    name: 'adventureTemplate',
    adminsOnly: true,
    href: '/he/admin/adventure-types',
    icon: SVG_TEMPLATES
}, {
    id: 3,
    label: 'חידות',
    name: 'riddles',
    adminsOnly: true,
    href: '/he/admin/riddles',
    MdIcon: MdVideogameAsset
}, {
    id: 4,
    label: 'כלים דיגיטליים',
    name: 'categories',
    adminsOnly: true,
    href: '/he/admin/categories',
    icon: SVG_DASHBOARD
}, {
    id: 5,
    label: 'הגדרות כלליות',
    name: 'settings',
    adminsOnly: true,
    href: '/he/admin/settings',
    icon: SVG_SETTINGS
}];

export const sidebarSupportItems = [{
    id: 6,
    label: 'לקוחות',
    name: 'customers',
    adminsOnly: true,
    href: '/he/admin/customers',
    icon: SVG_CUSTOMERS
}, {
    id: 7,
    label: 'הזמנות',
    singleLabel: "הזמנה",
    name: 'reservations',
    href: '/he/admin/reservations',
    icon: SVG_RESERVATIONS
},  {
    id: 8,
    label: 'קופונים',
    singleLabel: "קופון",
    name: 'coupons',
    adminsOnly: true,
    href: '/he/admin/coupons',
    icon: SVG_COUPON
}];

export const sidebarManagementItems = [{
    id: 9,
    label: 'תרגומים',
    singleLabel: "תרגום",
    name: 'translations',
    adminsOnly: true,
    href: '/he/admin/translations',
    MdIcon: MdLanguage
}, {
    id: 10,
    label: 'בלוג',
    singleLabel: "בלוג",
    name: 'blog',
    adminsOnly: true,
    href: '/he/admin/blogs',
    MdIcon: MdAssignment
}, {
    id: 11,
    label: 'לאתר',
    name: 'website',
    href: '/he/',
    MdIcon: MdLaptopMac,
    target:"_blank"
}];

export const tabsItems = [{
    id: 0,
    label: 'דשבורד',
    name: 'main',
    href: '/he/admin/dashboard',
    icon: SVG_DASHBOARD
}, {
    id: 1,
    label: 'הרפתקאות',
    singleLabel: "הרפתקה",
    name: 'adventures',
    href: '/he/admin/adventures',
    icon: SVG_ADVENTURES
}, {
    id: 2,
    label: 'תבניות',
    singleLabel: "תבנית הרפתקה",
    name: 'adventureTemplate',
    adminsOnly: true,
    href: '/he/admin/adventure-types',
    icon: SVG_TEMPLATES
}, {
    id: 3,
    label: 'חידות',
    name: 'riddles',
    adminsOnly: true,
    href: '/he/admin/riddles',
    icon: SVG_SETTINGS
}, {
    id: 4,
    label: 'כלים דיגיטליים',
    name: 'categories',
    adminsOnly: true,
    href: '/he/admin/categories',
    icon: SVG_DASHBOARD
}, {
    id: 5,
    label: 'הגדרות כלליות',
    name: 'settings',
    adminsOnly: true,
    href: '/he/admin/settings',
    icon: SVG_SETTINGS
}, {
    id: 6,
    label: 'לקוחות',
    name: 'customers',
    adminsOnly: true,
    href: '/he/admin/customers',
    icon: SVG_CUSTOMERS
}, {
    id: 7,
    label: 'הזמנות',
    singleLabel: "הזמנה",
    name: 'reservations',
    href: '/he/admin/reservations',
    icon: SVG_RESERVATIONS
},  {
        id: 8,
        label: 'קופונים',
        singleLabel: "קופון",
        name: 'coupons',
        adminsOnly: true,
        href: '/he/admin/coupons',
        icon: SVG_COUPON
    }, {
        id: 9,
        label: 'תרגומים',
        singleLabel: "תרגום",
        name: 'translations',
        adminsOnly: true,
        href: '/he/admin/translations',
        icon: SVG_COUPON
    }, {
        id: 10,
        label: 'בלוג',
        singleLabel: "בלוג",
        name: 'blog',
        adminsOnly: true,
        href: '/he/admin/blogs',
        icon: SVG_COUPON
    }, {
        id: 11,
        label: 'לאתר',
        name: 'website',
        href: '/he/',
        icon: SVG_COUPON
    }
];