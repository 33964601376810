import React, {useContext, useState} from 'react';
import {Col, Row} from 'react-grid-system';
import {VBox} from 'react-stylesheet';
import {useHistory} from "react-router-dom";
import styled from 'styled-components';
import {AuthContext} from "../../../../providers/auth-provider";
import {DICTIONARY} from "../registration-dictionary";
import {LanguageContext, Translation} from "../../../../languages";
import {gutters, viewport} from '../../../00_base/variables';
import {Button, SimpleLoader} from '../../../01_atoms';
import {FieldText} from '../../../02_molecules';
import {DICT} from "../../../../languages/dictionary-names";


const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ForgotPassword = () => {
    const [emailAddress, setEmailAddress] = useState("");
    const {isLogin, forgotPasswordRequest} = useContext(AuthContext);

    const {userLanguage} = useContext(LanguageContext);

    const [warningsActive, setWarningsActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [requestSuccess, setRequestSuccess] = useState(false);

    const history = useHistory();

    if (isLogin) {
        history.push("/");
    }

    const emailValid = emailRegex.test(String(emailAddress).toLowerCase());


    const handleChange = (name, value) => setEmailAddress(value);

    const handleSubmit = (event) => {
        event && event.preventDefault();
        if (emailValid) {
            setIsLoading(true);
            forgotPasswordRequest(
                emailAddress,
                () => {
                    setIsLoading(false);
                    setRequestSuccess(true);
                }
            )
        } else {
            setWarningsActive(true)
        }
    };

    // const handleError = (error) => {
    //     console.log('error', error);
    //     this.setState({
    //         showValidation: true,
    //         isLoading: false
    //     })
    // }

    const controlStyle = {marginBottom: gutters.md};
    const inputStyle = {};
    const fieldTextProps = {
        borderRadius: 0,
        border: 0,
        marginBottom: 0,
        controlStyle: controlStyle,
        inputStyle: inputStyle,
        changeHandler: handleChange,
        focus: () => setWarningsActive(false)
    };

    return (
        <Wrapper className={`forgot-password-wrapper`}>
            <FormWrapper className={`forgot-password-form-wrapper`}>
                <h2 className={`form-title font-size-xxl `}>{DICTIONARY.forgotPassword[userLanguage]}</h2>
                <p>הכניסו את כתובת המייל שאיתה נרשמתם למערכת</p>
                <form id={'login-form'} onSubmit={handleSubmit}>
                    <Row>
                        <Col xs={12}>
                            <FieldText type={'text'}
                                       label={DICTIONARY.emailAddress[userLanguage]}
                                       name={'email'}
                                       value={emailAddress}
                                       errorMessage={DICTIONARY.emailAddressWarning[userLanguage]}
                                       errorMessageActive={warningsActive && !emailValid}
                                       {...fieldTextProps}/>
                        </Col>
                    </Row>
                    <VBox alignItems={'center'}>
                        {requestSuccess ?
                            <p>בידקו את תיבת הדואר הנכנס ב <span
                                style={{fontWeight: "bold"}}>{emailAddress}</span> ועיקבו אחר ההוראות</p>
                            :
                            <Button width={"100px"} onClick={handleSubmit}>
                                {isLoading ? <SimpleLoader color={"#fff"}/> : <Translation id={DICT.CONTINUE}/>}
                            </Button>
                        }
                    </VBox>
                </form>
            </FormWrapper>
        </Wrapper>
    )
}

export default ForgotPassword;


const Wrapper = styled.div`
    // min-height: calc(100vh - 220px);  
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 ${gutters.sm};
    h2.form-title {
        text-align: center;
        margin-bottom: ${gutters.sm}; 
        
    }
    
`,

    FormWrapper = styled.div`
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 66rem;
        margin: 0 0 ${gutters.xl} 0;
        max-width: 100%;

        form {
            margin-top: ${gutters.md}
            width: 100%;
            input {

            }
            .field-submit{
                width: 50%;
                input {
                    width: 100%;
                }
            }
            
            @media screen and (min-width: ${viewport.sm}) {

            }
        }
    `;