import React, {useState} from "react";
import {Button, MobileLgHidden} from "../../../01_atoms";
import {FieldText} from "../../../02_molecules";
import {FilterIndicators} from "../../filter-indicators/filter-indicators";
import "./table-search-bar.scss";

export const TableSearchBar = (
    {filterIndicators, filterLength, removeAllFilters, handleSearchClick, handleAddClick, addLabel, filterByTitle}
) => {
    const [searchValue, setSearchValue] = useState("");
    const [titleSearch, setTitleSearch] = useState("");

    return <div className="table-items-header">
        <div className={"table-items-header-row"}>
            <div className="table-items-header-search">
                <FieldText placeholder="חיפוש"
                           name="search"
                           controlStyle={controlStyle}
                           changeHandler={(name, value) => setSearchValue(value)}
                           inputStyle={inputStyle}/>
                <Button value="חפש"
                        onClick={() => handleSearchClick(searchValue)}
                        className="table-items-add"/>

                {filterByTitle &&
                <MobileLgHidden display={"flex"} style={{alignItems:"center", marginRight:"20px", height: "100%"}}>
                    <FieldText placeholder="חפש על פי שם הזמנה"
                               name="search"
                               controlStyle={controlStyle}
                               inputStyle={inputStyle}
                               changeHandler={(name, value) => setTitleSearch(value)}/>
                    <Button value="שלח"
                            onClick={() => filterByTitle(titleSearch)}
                            className="table-items-add"/>
                </MobileLgHidden>
                }
            </div>

            {handleAddClick &&
            <Button value={addLabel}
                    onClick={handleAddClick}
                    className="table-items-add"/>}
        </div>

        {filterIndicators &&
        filterIndicators.length > 0 &&
        <FilterIndicators items={filterIndicators}
                          length={filterLength}
                          removeAllFilters={removeAllFilters}/>}
    </div>
};

const inputStyle = {backgroundColor: "#fff", border: 0, height:"100%", borderRadius: "2px"};
const controlStyle = {margin: '0 0 0 20px', width: "180px", height:"100%"};