import React from "react";
import {gutters} from "../../00_base/variables";
import {FieldText, FilesUploader, FieldTextArea} from "../../02_molecules/form-fields";
import {TagsSelector} from "../../03_organisms/";

export const RiddleMainContent = ({handleRiddleChange, riddle}) => {
    const {title, description, description_short, tags, thumbnail} = riddle;

    return (
        <div className="py-3">
            <FieldText name="title"
                       value={title}
                       changeHandler={handleRiddleChange}/>

            <FilesUploader name="thumbnail"
                           value={thumbnail}
                           multiple={false}
                           previewWidth={200}
                           controlStyle={{marginBottom: gutters.sm}}
                           changeHandler={handleRiddleChange}/>

            <FieldTextArea label="תיאור החידה"
                           wysiwyg={true}
                           toolbarHidden={false}
                           name="description"
                           value={description}
                           changeHandler={handleRiddleChange}/>

            <FieldTextArea label="תיאור מקוצר לגלריה"
                           wysiwyg={true}
                           toolbarHidden={false}
                           name="description_short"
                           value={description_short}
                           changeHandler={handleRiddleChange}/>

            <TagsSelector value={tags}
                          name="tags"
                          handleChange={handleRiddleChange}/>
        </div>
    )
};