import React, {useEffect} from "react";
import {ContentState, convertToRaw, EditorState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styled from "styled-components";
import Video from "../../01_atoms/video";
import {Image} from "../../01_atoms";
import Slider from "../../03_organisms/slider";
import "./tool-content.scss";
import {Element} from "react-stylesheet";

const
    CARROUSEL = "carrousel",
    TABS = "tabs";


export const ToolContent = (
    {
        toolData,
        items = [],
        content_view = "blocks",
        indicators_location = "down",
        handleFrameFinish,
        indicatorStyle = {},
        elementBackgroundColor,
        elementBorderColor = "#000",
        elementBorderSize = 0
    }) => {

    useEffect(() => {
        // if (!toolData.categorydata.innerPassword && ) {
        //     handleFrameFinish();
        // }
    }, []);

    const renderItems = items.map((item, index) => {
        const {type, value, imageSize = "100%", bgColor, textColor} = item;
        const className = `tool-content-${type}`;
        const contentBlock = htmlToDraft(value);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        // for (let i = 0; i < contentBlock.split("").length; i++) {
        //     const letter = contentBlock.split("")[i];
        //     const replaceble = indexes.some(index => index === i);
        //
        //     str += replaceble ? <span style={{backgroundColor: "#00a2ff"}}>${letter}</span> : letter;
        // }
        //
        // let comp = () => {
        //     const renderItem = (letter, i) => {
        //             const replaceble = indexes.some(index => index === i);
        //             return  replaceble ? <span style={{backgroundColor: "#00a2ff"}}>${letter}</span> : letter;
        //     };
        //     return (
        //         <div>
        //             {value.split("").map(renderItem)}
        //         </div>
        //     )
        // }
        // str = `${str.substring(0, index)}<span style="background-color: red">${str[0]}</span>${str.substring(index + 1)}`;

        let element;
        switch (type) {
            case "text":
                element = <p className={className} dangerouslySetInnerHTML={{__html:  content}}/>;
                break;
            case "image":
                element = <Image className={className} src={value} style={{margin: "0 auto"}} block width={imageSize}/>;
                break;
            case "video":
                element = <Video className={className} videoStyle={{position:"static", transform:"none"}} source={value}/>;
                break;
            default:
                element = <div/>;
        }
        return <Element background={bgColor} color={textColor} className="tool-content-item" key={index}>{element}</Element>;
    });

    const isTabs = content_view === TABS;
    const isCarrousel = content_view === CARROUSEL;
    const showSlider = isCarrousel || isTabs;
    const className = `tool-content tool-content-${content_view}`;
    const wrapperProps = {className:className, backgroundColor:elementBackgroundColor, borderColor:elementBorderColor, borderSize:elementBorderSize};

    return (
        <ToolContentWrapper {...wrapperProps}>
            {showSlider ?
                <Slider items={renderItems}
                        indicators={items.map(item => item.indicator)}
                        indicatorStyle={indicatorStyle}
                        indicatorsLocation={indicators_location}
                        speed={isTabs ? 0 : 500}
                        noArrows={isTabs}/>
                :
                renderItems
            }

        </ToolContentWrapper>
    )
};

const ToolContentWrapper = styled.div`
    ${props => `
        ${props.backgroundColor && `background-color: ${props.backgroundColor}`};
        border-color: ${props.borderColor};
        border-width: ${props.borderSize}px;
        border-style: solid;
    `}
`;