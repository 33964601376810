import React, {useState} from "react";
import Switch from "react-switch";
import "./switcher.scss";
// https://www.npmjs.com/package/react-switch

export const Switcher = (
    {
        checked,
        label = "",
        className = "",
        changeHandler = (name, isChecked) => {},
        name,
        onColor="#8cc63f",
        offColor="#ff0000",
        handleDiameter=12,
        height=19,
        width=38,
        checkedIcon=null,
        uncheckedIcon=null
    }) => {
    const [isChecked, setIsChecked] = useState(checked);

    const handleChange = (checked) => {
        setIsChecked(checked);
        changeHandler(name, checked);
    }
    return (
        <label className={`switcher ${className}`}>
            {label && <span>{label}</span>}
            <Switch onChange={handleChange}
                    checkedIcon={checkedIcon}
                    uncheckedIcon={uncheckedIcon}
                    onColor={onColor}
                    offColor={offColor}
                    handleDiameter={handleDiameter}
                    height={height}
                    width={width}
                    checked={isChecked}/>
        </label>
    );
}