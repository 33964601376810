import React from 'react';
import PropTypes from 'prop-types';
import {Translation} from "../../../../../languages";
import {DICT} from "../../../../../languages/dictionary-names";
import {FilesUploader} from '../../../../02_molecules';

const checkIsValid = ({puzzle_image}) => {
    if (puzzle_image) {
        return 100
    } else {
        return 0;
    }
};

const CreateToolPuzzle = ({toolData, handleToolDataChange}) => {
    const {puzzle_image, puzzle_default_images} = toolData;

    return (
        <div className={'create-puzzle'}>
            <FilesUploader minItems={1}
                           maxItems={1}
                           previewWidth={230}
                           previewHeight={230}
                           justifyContent={"center"}
                           name={'puzzle_image'}
                           multiple={false}
                           triggerValue={<Translation id={DICT.UPLOAD_IMAGE}/>} //<Placeholders />
                           expectedLength={1}
                           changeHandler={(name, value) => handleToolDataChange(checkIsValid, name, value)}
                           value={puzzle_image}
                           initialValue={puzzle_image}
                           optionalImages={puzzle_default_images}/>
        </div>
    )
};

CreateToolPuzzle.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData)
};

CreateToolPuzzle.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func
};


export default CreateToolPuzzle;