class Template {
    constructor(
        id,
        title,
        description,
        categories,
        categorydata,
        description_short,
        printimageurl,
        riddle_name,
        show_in_store,
        thumbnail,
        user_email,
        videourl,
        is_published,
        tags,
        created_at,
        clues,
        instructions,
        editable
    ) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.categories = categories;
        this.categorydata = categorydata;
        this.description_short = description_short;
        this.printimageurl = printimageurl;
        this.riddle_name = riddle_name;
        this.show_in_store = show_in_store;
        this.thumbnail = thumbnail;
        this.user_email = user_email;
        this.videourl = videourl;
        this.is_published = is_published;
        this.tags = tags;
        this.created_at = created_at;
        this.clues = clues;
        this.instructions = instructions
        this.editable = editable;
    }
}

export default Template;