import React, {useCallback, useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classNames from "classnames";
import _ from 'lodash';
import {ENGLISH_CODER, getInitialCoderStatus, HEBREW_CODER, NUMBERS_CODER} from "./coder-status";
import {NumbersCoder} from "./numbers-coder/numbers-coder";
import {LettersCoder} from "./letters-coder/letters-coder";
import "./coder-styles.scss";
import {MdBackspace, MdLanguage, MdSend} from "react-icons/all";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import {StyledDiv} from "../../ui/css-general/css-styled";
import {Heading, Image} from "../../01_atoms";
import THUMBNAIL from '../../../assets/images/default_thumbnail.jpg';
import {useSelector} from "react-redux";

export const ToolCoder = (
    {
        startNumber,
        endNumber,
        numbersButtonsPerRow,
        buttonsBackgroundColor,
        buttonsTextColor,
        numbersButtonsBorderRadius,
        numbersButtonSize,
        buttonTextSize,
        password,
        errorText,
        errorTextColor,
        validateOnPasswordFull,
        showSubmitButton,
        submitButtonColor,
        submitButtonSize,
        submitButtonBorderRadius,
        isButtons3D,
        numbersButtonsColors,
        showOnlyNumbersInput,
        buttonsStyle,
        lineStyle,
        colors,
        icons = [],
        type
    }) => {

    const frameContext = useContext(FrameContext);
    const isResolve = frameContext.isFrameFinish;
    const handleFrameFinish = frameContext.handleFrameFinish;
    const gameData = useSelector(state => state.gameData);
    const {adventure, adventureType} = gameData || {};
    const isEmployee = adventureType?.id === 324 || adventureType?.id === 330 || adventureType?.id === 332;
    if (isEmployee) {
        password = adventure.json_data?.employee_year_of_birth || "1234";
    }
    const [userPassword, setUserPassword] = useState(isResolve ? password.split("").map((digit, index) => {
        return {value: digit}
    }) : []);
    const [isErrorHidden, setIsErrorHidden] = useState(true);
    const [showSuccess, setShowSuccess] = useState(isResolve);
    const [coderStatus, setCoderStatus] = useState(getInitialCoderStatus(password));
    const [secret, setSecret] = useState(0);
    const isHebrew = getInitialCoderStatus(password) === HEBREW_CODER;

    const handleSubmit = useCallback(() => {
        if (!isResolve) {
            const userPasswordStr = userPassword.map(({value}) => value).join("");
            if (_.isEqual(userPasswordStr, password.toLowerCase().replace(/\s/g, ""))) {
                handleFrameFinish();
                setShowSuccess(true);
            } else {
                setIsErrorHidden(false)
                setTimeout(() => {
                    setIsErrorHidden(true);
                    setUserPassword([])
                }, 2000)
            }
        }
    }, [handleFrameFinish, isResolve, password, userPassword]);

    useEffect(() => {
        if (validateOnPasswordFull && password.length > 0 && userPassword.length === password.length) {
            handleSubmit();
        }
    }, [handleSubmit, password.length, userPassword, validateOnPasswordFull]);

    const onInputAdd = (newInput, color) => {
        if (!isResolve) {
            const newUserCode = [...userPassword, {value: _.toString(newInput), color: _.toString(color)}]
            setUserPassword(newUserCode);
        }
    };

    const onRemove = () => {
        if (!isResolve) {
            userPassword.pop();
            setUserPassword(JSON.parse(JSON.stringify(userPassword)))
        }
    };

    const renderUserPassword = () => {
        const renderValue = (userPassword, index) => {
            let itemIndex;
            switch (userPassword?.value) {
                case "*":
                    itemIndex = "12";
                    break;
                case "0":
                    itemIndex = "11";
                    break;
                case "#":
                    itemIndex = "10";
                    break;
                default:
                    itemIndex = userPassword?.value;
            }
            const isColors = type === "colors";
            const isIcons = type === "icons";
            const isEmpty = password[index] === "";
            const borderColor= lineStyle?.borderColor || "#fff";
            const borderWith = lineStyle?.borderWidth || 2;
            if (userPassword) {
                if (isColors || isIcons) {
                    const value = isIcons ? <Image src={icons[itemIndex - 1] || THUMBNAIL}/> : "";
                    const color = isColors && colors[itemIndex - 1];
                    return (
                        <StyledDiv className="single-user-password" key={index}>
                            <SingleUserPasswordValue className="single-user-password-color-value"
                                                     color={color}>
                                {value}
                            </SingleUserPasswordValue>
                        </StyledDiv>)
                }
                return <StyledDiv key={index}
                                  className="single-user-password"
                                  {...lineStyle}
                                  borderWidth={borderWith}
                                  borderColor={borderColor}>{userPassword.value}</StyledDiv>
            }
            return <StyledDiv key={index}
                              className="single-user-password"
                              {...lineStyle}
                              borderWidth={borderWith}
                              borderColor={borderColor}>
                {""}
                </StyledDiv>

        };

        const passwordList = Array.from(Array(password.length));

        return (
            <div className={classNames("coder-user-password", {hebrew: isHebrew})}>
                {passwordList.map((key, index) => {
                    return renderValue(userPassword[index], index)
                })}
            </div>
        )
    };

    const renderInput = () => {
        switch (coderStatus) {


            case ENGLISH_CODER:
            case HEBREW_CODER:
                return (<LettersCoder isButtons3D={isButtons3D}
                                      onInputAdd={onInputAdd}
                                      textColor={buttonsTextColor}
                                      buttonsBackgroundColor={buttonsBackgroundColor}
                                      isHebrew={coderStatus === HEBREW_CODER}/>)
            default:
                return (<NumbersCoder startNumber={startNumber}
                                      endNumber={endNumber}
                                      buttonsPerRow={numbersButtonsPerRow}
                                      buttonsBackgroundColor={buttonsBackgroundColor}
                                      buttonsTextColor={buttonsTextColor}
                                      buttonsBorderRadius={numbersButtonsBorderRadius}
                                      buttonSize={numbersButtonSize}
                                      buttonTextSize={buttonTextSize}
                                      buttonsColors={numbersButtonsColors}
                                      dynamicStyle={buttonsStyle}
                                      onInputAdd={onInputAdd}
                                      colors={colors}
                                      icons={icons}
                                      isButtons3D={isButtons3D}
                                      type={type}/>)
        }
    };

    const switchInput = () => {
        if (!isResolve) {
            let inputs = [NUMBERS_CODER, HEBREW_CODER, ENGLISH_CODER];
            let inputIndex = _.indexOf(inputs, coderStatus)
            const newCoderStatus = inputIndex === inputs.length - 1 ? inputs[0] : inputs[inputIndex + 1];
            setCoderStatus(newCoderStatus);
        }
    };

    const secretHandler = () => {
        setSecret(secret + 1);
        if (secret > 10) {
            handleFrameFinish();
        }
        // setTimeout(() => {
        //     setSecret(0)
        // }, 2000)
    };
    const isEnglish = adventureType?.id === 332;

    return (
        <CoderContainer className={classNames("coder-container", {disabled: isResolve})}>

            {isEmployee &&
            <Heading tag={"h4"} className="coder-top-title" textAlign={"center"} color={"#fff"}>
                {adventureType?.id === 324 ?
                    "לחץ על הנרות וגלה את הקוד" :
                    adventureType?.id === 330 ?
                        "לחצי על הנרות וגלי את הקוד" :
                        adventureType?.id === 332 ? "Click on the candies to find code" :
                            ""}
            </Heading>}

            {renderUserPassword()}

            <div className="input-container">
                {renderInput()}
                {/*<div className="input-container-space" onClick={() => onInputAdd(" ")}/>*/}
            </div>

            <div onClick={secretHandler}>
                <ErrorText
                    className={classNames("coder-error-text", {
                        active: !isErrorHidden || showSuccess,
                        fade: !isErrorHidden
                    })}
                    textColor={showSuccess ? lineStyle?.backgroundColor || "green" : errorTextColor}>
                    {showSuccess ? (isEnglish ? "Correct!" : "סיסמא נכונה") : errorText}
                </ErrorText>
            </div>

            {!isResolve &&
            <div className="coder-bottom-buttons">
                <div className="coder-bottom-button" onClick={onRemove}>
                    <MdBackspace size={numbersButtonSize} color={lineStyle?.borderColor || "#fff"}/>
                </div>

                {showSubmitButton &&
                <SubmitButton className="coder-submit-button" size={submitButtonSize} onClick={handleSubmit}
                              borderRadius={submitButtonBorderRadius} backgroundColor={submitButtonColor}>
                    <MdSend color="#fff" size={24} style={{transform: "rotate(180deg)"}}/>
                </SubmitButton>}

                {!showOnlyNumbersInput &&
                <div className="coder-bottom-button" onClick={switchInput}>
                    <MdLanguage size={numbersButtonSize} color={lineStyle?.borderColor || "#fff"}/>
                </div>}
            </div>
            }

            {/*{nextFrameButton && nextFrameButton}*/}

        </CoderContainer>
    )
};

const SingleUserPasswordValue = styled.div`
     ${({color}) => `
        background-color: ${color};
    `}
`;
const ErrorText = styled.div`
    ${({textColor}) => `
        color: ${textColor};
    `}
`;


const SubmitButton = styled.div`
    ${({size, backgroundColor, borderRadius}) => `
        height: ${size}px;
        width: ${size}px;
        background-color: ${backgroundColor};
        border-radius: ${borderRadius}%;
    `}
`;

const CoderContainer = styled.div`
    // margin-top: 10px; 
    ${({backgroundColor}) => `
        background-color: ${backgroundColor};
    `}
`;


ToolCoder.defaultProps = {
    startNumber: 0,
    endNumber: 9,
    numbersButtonsPerRow: 3,
    buttonsBackgroundColor: "white",
    buttonsTextColor: "black",
    numbersButtonsBorderRadius: 50,
    numbersButtonSize: 50,
    buttonTextSize: 25,
    topTitle: "הכנס סיסמא",
    topTitleColor: "black",
    password: ["1", "2", "3", "4"],
    backgroundColor: "white",
    errorText: "סיסמא שגוייה",
    errorTextColor: "red",
    validateOnPasswordFull: false,
    showSubmitButton: true,
    submitButtonColor: "Green",
    submitButtonSize: 60,
    submitButtonBorderRadius: 50,
    isButtons3D: true,
    hideNumbers: false,
    renderNumbersAsColors: false,
    showOnlyNumbersInput: false,
    numbersButtonsColors: [],
};

ToolCoder.propTypes = {
    startNumber: PropTypes.number,
    endNumber: PropTypes.number,
    hideNumbers: PropTypes.bool,
    numbersButtonsPerRow: PropTypes.number,
    buttonsBackgroundColor: PropTypes.string,
    buttonsTextColor: PropTypes.string,
    numbersButtonsBorderRadius: PropTypes.number,
    numbersButtonSize: PropTypes.number,
    buttonTextSize: PropTypes.number,
    topTitle: PropTypes.string,
    topTitleColor: PropTypes.string,
    password: PropTypes.string,
    backgroundColor: PropTypes.string,
    errorText: PropTypes.string,
    errorTextColor: PropTypes.string,
    validateOnPasswordFull: PropTypes.bool,
    showSubmitButton: PropTypes.bool,
    submitButtonColor: PropTypes.string,
    submitButtonSize: PropTypes.number,
    submitButtonBorderRadius: PropTypes.number,
    handleFinish: PropTypes.func,
    isButtons3D: PropTypes.bool,
    numbersButtonsColors: PropTypes.array,
    renderNumbersAsColors: PropTypes.bool,
    showOnlyNumbersInput: PropTypes.bool,
};