import React from 'react';
import PropTypes from "prop-types";
import {Element} from 'react-stylesheet';
import {CardBody} from '../card-layout';
import {BackgroundImage, Paragraph} from '../../../01_atoms';
import CardActions from '../card-layout/m-card-actions';
import {gutters} from '../../../00_base/variables';


const Card4 = ({
    title,
    image,
    actions,
    titleStyle,
    bodyStyle,
    actionsStyle,
    height
}) => {
     return (
        <Element>
            <BackgroundImage url={image} height={height} width={'100%'}>
                <CardBody
                    backgroundColor={'transparent'}
                    style={{
                        ...bodyStyle,
                        display: 'flex',
                        justifyContent:'space-between',
                        background: `linear-gradient(0deg, rgba(0,0,0,0.7625272331154684) 0%, rgba(2,0,36,0.5533769063180828) 47%, rgba(255,255,255,0) 100%`,
                        padding: `${gutters.lg} ${gutters.sm} 0`,
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        left: 0
                    }}>
                    {!!title && <Paragraph style={titleStyle} value={title} />}
                    {!!actions && <CardActions 
                        style={actionsStyle} 
                        itemStyle={{display:'flex', alignItems:'center'}} 
                        items={actions} />}
                </CardBody>
            </BackgroundImage>
        </Element>
    )
}


Card4.defaultProps = {
    height: '200px',
};
Card4.propTypes = {
    /**
     * define the height of the card
     */
    height: PropTypes.string
};

export default Card4;