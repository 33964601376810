import React, {useEffect, useState} from 'react';
import _ from "lodash";
import {CreateTruthOrLieSection} from "./create-tool-truth-or-lie-section";
import {TruthOrLieStyle} from "./truth-or-lie-item-style";
import {createNewId} from "../../../00_base/helpers/create-new-id";
import {Button} from '../../../01_atoms';
import {Table, tableHeader, Tr} from "../../../03_organisms/table";
import {colors} from "../../../00_base/variables";

const initialSection = {
    id: 0,
    sentence: '',
    sentence_type: 0,
    icon_truth: '',
    icon_lie: '',
};

const CreateToolTruthOrLie = (
    {
        items = [],
        colorTruth, colorLie, colorSentence = colors.black, bgSentence,
        truthLabel, lieLabel,
        handleToolDataChange
    }) => {

    const [truthOrLieItems, setTruthOrLieItems] = useState(items);

    // const fixeOldVersion = () => {
    //     const confirm = window.confirm("Are you sure?");
    //     if (confirm) {
    //         if (templatedata?.items) {
    //             handleToolDataChange("items", templatedata.items);
    //         }
    //     }
    // };
    useEffect(() => {
        if (!_.isEqual(truthOrLieItems, items)) {
            handleToolDataChange("items", truthOrLieItems)
        }
    }, [handleToolDataChange, items, truthOrLieItems]);

    const addSection = () => {
        const newId = createNewId(truthOrLieItems);
        setTruthOrLieItems(truthOrLieItems.concat({...initialSection, id: truthOrLieItems.length > 0 ? newId : 0}))
    };

    const removeSection = (itemId) => {
        setTruthOrLieItems(truthOrLieItems.filter(item => item.id !== itemId));
    };

    const handleChange = (name, value, id, index) => {
        const updatedItems = truthOrLieItems.map(item => {
            if (item.id !== id) {
                return item
            }
            if (index >= 0) {
                item[name][index] = value;
                return {...item, [name]: item[name]}
            }
            return {...item, [name]: value}
        });
        setTruthOrLieItems(updatedItems)
    };

    const renderSection = (item, index) => {
        return <CreateTruthOrLieSection {...item}
                                        index={index}
                                        handleChange={handleChange}
                                        removeSection={() => removeSection(item.id)}
                                        key={item.id}/>
    }
    const tableHeaderItems = ["#", "משפט", "אמת/שקר", "איקון אמת", "איקון שקר"];

    return (
        <div className="create-tool truth-lie">
            {/*<button onClick={fixeOldVersion} style={{marginRight:"auto"}}>FIX OLD VERSION BUG</button>*/}

            <Table>
                <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>

                <tbody>{truthOrLieItems.map(renderSection)}</tbody>
            </Table>

            <Button className="tool-add-section"
                    value="הוסף משפט"
                    outline
                    onClick={addSection}/>

             <TruthOrLieStyle handleToolDataChange={handleToolDataChange}
                              colorTruth={colorTruth}
                              colorLie={colorLie}
                              truthLabel={truthLabel}
                              lieLabel={lieLabel}
                              colorSentence={colorSentence}
                              bgSentence={bgSentence}/>

        </div>
    )
};

CreateToolTruthOrLie.defaultProps = {

};

CreateToolTruthOrLie.propTypes = {

};

export default CreateToolTruthOrLie;