import React, {useState} from "react";
import {SortableHandle} from "react-sortable-hoc";
import {MdClose, MdDragHandle} from "react-icons/md";
import {Switcher} from "../../../01_atoms";
import {FieldSelect, FilesUploader} from "../../../02_molecules";
import {Td, Tr} from "../../../03_organisms/table";
import {CreateGalleryAdvancedData} from "./advanced-data/gallery-advanced-data";
import {ToggleIcon} from "../../../03_organisms/toogle-icon/toogle-icon";

const contentTypeOptions = [
    {label: "טקסט", value: "text", id: 0},
    {label: "פיסקה", value: "textarea", id: 3},
    {label: "רשימה", value: "list", id: 1},
    {label: "תמונה", value: "image", id: 2},
    {label: "סרטון", value: "video", id: 4},
];

export const CreateToolGalleryImagesItem = (
    {
        id,
        index,
        image,
        frame,
        editable,
        galleryView,
        icon,
        removeItem,
        advancedData,
        advancedDataType,
        handleChange
    }
) => {
    const [isOpen, setIsOpen] = useState(false);
    const changeHandler = (name, value) => handleChange(name, value, id);

    const currentAdvancedType = contentTypeOptions.find(item => item.value === advancedDataType);

    const isMatching = galleryView === "matching";

    const tdStyle = {padding: "0 5px", verticalAlign: "middle"};
    const DraggableHandle = SortableHandle(() => {
        return (
            <Td className="gallery-item-drag-handle" style={{...tdStyle, display: "table-cell", width: "110px"}}>
                <MdDragHandle size={20} color={"#d2d2d2"}/>
            </Td>
        )
    });
    return (
        <>
            <Tr>
                {/*<Td style={{...tdStyle}}>*/}
                {/*<DraggableHandle/>*/}
                {/*</Td>*/}
                <Td style={{...tdStyle}}>
                    {index + 1}
                </Td>
                <Td style={{...tdStyle, width: "120px"}}>
                    <FilesUploader name="image"
                                   value={image}
                                   triggerValue={"תמונה"}
                                   previewWidth={100}
                                   previewHeight={100}
                                   multiple={false}
                                   changeHandler={changeHandler}/>
                </Td>
                <Td style={{...tdStyle, padding: 0, width: "120px"}}>
                    <FilesUploader name="frame"
                                   triggerValue={"מסגרת"}
                                   value={frame}
                                   multiple={false}
                                   previewWidth={100}
                                   previewHeight={100}
                                   changeHandler={changeHandler}/>
                </Td>

                <Td style={tdStyle}>
                    <Switcher checked={editable}
                              name={"editable"}
                              changeHandler={(name, checked) => {
                                  if (editable !== checked) {
                                      changeHandler(name, checked)
                                  }
                              }}/>
                </Td>

                {isMatching &&
                <Td style={{...tdStyle}}>
                    <FilesUploader name="icon"
                                   value={icon}
                                   previewWidth={35}
                                   previewHeight={35}
                                   triggerValue={"איקון"}
                                   multiple={false}
                                   changeHandler={changeHandler}/>
                </Td>
                }
                <Td style={{...tdStyle, textAlign: "left"}}>
                    <div className={"d-flex align-items-center"}>
                        <ToggleIcon size={25} toggle={() => setIsOpen(!isOpen)} isOpen={isOpen}/>
                        <MdClose onClick={removeItem} cursor={"pointer"}/>
                    </div>
                </Td>
            </Tr>
            {isOpen &&
            <Tr>

                {/*<Td style={{...tdStyle, verticalAlign:"top"}}>*/}
                {/**/}
                {/*</Td>*/}
                <Td colSpan={5} style={{...tdStyle, width: "110px"}}>
                    <FieldSelect options={contentTypeOptions}
                                 name="advancedDataType"
                                 label={"תוכן בלחיצה על כפתור"}
                                 value={currentAdvancedType}
                                 controlStyle={{marginBottom: 0, width: "100%"}}
                                 customControlStyle={{height: "40px", width: "100%"}}
                                 changeHandler={(name, value) => changeHandler(name, value.value)}/>
                    <CreateGalleryAdvancedData advancedDataType={advancedDataType}
                                               advancedData={advancedData}
                                               handleChange={changeHandler}/>
                </Td>
            </Tr>}
        </>
    )
};