import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectAllAdventureTemplates, selectCurrentAdventureType} from "../../../../store/selectors";
import * as adventureTemplateActions from '../../../../store/actions/adventure-template-actions';
import {colors} from "../../../00_base/variables";
import {Switcher} from "../../../01_atoms";
import {FieldSelect, FieldText, FieldTextArea} from "../../../02_molecules";
import {ChooseItemLanguage} from "../../../03_organisms/choose-item-language/choose-item-language";
import {TdContent} from "../../../03_organisms/table";

export const AdventureTypeMainDetails = ({handleChange}) => {
    const adventureType = useSelector(selectCurrentAdventureType);
    const adventureTemplates = useSelector(selectAllAdventureTemplates);
    const currentAdventureTemplate = adventureTemplates.find(item => item.id === adventureType.adventuretemplate_id) || {};

    const dispatch = useDispatch();

    const createNewTemplate = (name) => {
        dispatch(adventureTemplateActions.createAdventureTemplate({name}))
    };

    useEffect(() => {
        dispatch(adventureTemplateActions.setAllAdventureTemplates())
    }, [dispatch]);

     return (
        <div className="adventure-type-tab-item adventure-type-main-details">

            <Switcher label={"פעיל"}
                      changeHandler={handleChange}
                      name={"is_active"}
                      checked={!!adventureType.is_active}
                      className={"adventure-type-main-details-isActive"}/>

            <div className="adventure-type-main-details-form">
                <FieldText name="name"
                           placeholder="כותרת ההרפתקה"
                           value={adventureType.name}
                           inputStyle={{border:0, fontSize:"18px", borderBottom:"1px solid #e5e4e5", borderRadius: 0, paddingRight: 0}}
                           changeHandler={handleChange}/>

                <ChooseItemLanguage value={adventureType.language_id}
                                    changeHandler={handleChange}
                                    className={"mb-3"}
                                    label={"שפה"}/>

                <FieldTextArea name="description_inner"
                               label="תיאור הרפתקה (פנימי)"
                               value={adventureType.description_inner}
                               rows={4}
                               changeHandler={handleChange}/>

                {adventureTemplates &&
                <FieldSelect label="תבנית"
                             name={"adventuretemplate_id"}
                             options={adventureTemplates.map(item => {
                                 return {label: item.name, value: item.id, id: item.id}
                             })}
                             value={{value: currentAdventureTemplate.id, label: currentAdventureTemplate.name}}
                             changeHandler={(name, value) => handleChange(name, value.id)}
                             controlStyle={{position:"relative", zIndex:11}}
                             customControlStyle={{ border: `1px solid ${colors.gray_5}`, cursor:"pointer", borderRadius: "4px", display:"flex", paddingRight:"10px", height: "40px", flexDirection:"row-reverse", justifyContent: "center"}}
                             inputStyle={{height: "100%", border: "1px solid red"}}
                             createOptionHandler={(value, handleChange) => {
                                 handleChange(value);
                                 createNewTemplate(value);
                             }}
                />}
                <div className="adventure-type-subdomain">
                    <span>youdoadventures.com.</span>
                    <FieldText name="game_subdomain"
                               value={adventureType.game_subdomain}
                               changeHandler={handleChange}
                               controlStyle={{marginBottom: "0px", width: "100px"}}
                               labelStyle={{marginBottom: 0}}
                               placeholder="play"/>
                </div>




                {/*<FieldSelect label="תגיות"*/}
                {/*             name="tags"*/}
                {/*             options={allTags.map(tag => {*/}
                {/*                 return {...tag, label: tag.title, value: tag.id}*/}
                {/*             })}/>*/}

            </div>
        </div>
    )
};