import React from 'react';
import './slider.scss';
import classNames from "classnames";
import {MdChevronLeft, MdChevronRight} from 'react-icons/md/'
import {Circle} from '../../01_atoms';

const SliderView = (props) => {
    const { className, height, items, activeItem, nextItem, prevItem, noArrows, noIndicators, arrowBackgroundColor, debug, endSlideDirection } = props,
        { prev, next} = props,
        { circlesBackground, circlesColor, circleArrowSize, circleSize } = props;

    return (
        <div className={`slider-view ${className} ${debug ? 'slider-debug' : ''}`} style={{ height: height }}>
            <div className={'slider_wrapper'}>

                {!noArrows && items.length > 1 && <div className={'slider_arrow slider_arrow-left'} onClick={prev} style={{ backgroundColor: arrowBackgroundColor }}>
                    <Circle size={circleSize} background={circlesBackground || '#333333'}>
                        <MdChevronLeft size={`${circleArrowSize}px`} color={circlesColor || '#ffffff'} />
                    </Circle>
                </div>}

                <div className={'slider_content'}>
                    {items.map((item, i) => {
                        const itemClass =
                            activeItem === i ? ' slider_item-active' :
                                prevItem === i ? ' slider_item-prev' :
                                    nextItem === i && ' slider_item-next';

                        return (
                            <div key={i} className={classNames(`slider_item${itemClass || ''}`, {[endSlideDirection] : prevItem === i})}
                                style={{ transitionDuration: `0.8s` }}>
                                {item}
                            </div>
                        )
                    })}
                </div>

                {!noIndicators &&
                    <div className={'slider_indicators'}>
                        <ul>
                            {items.length > 1 && items.map((item, i) => {
                                return (
                                    <li key={i}
                                        className={`slider_indicators_item ${activeItem === i ? 'slider_indicators_item-active' : ''}`}
                                        onClick={() => props.goToIndex(i)} />
                                )
                            })}

                        </ul>
                    </div>
                }
                {!noArrows && items.length > 1 && <div className={'slider_arrow slider_arrow-right'} onClick={next} style={{ backgroundColor: arrowBackgroundColor }}>
                    <Circle size={circleSize} background={circlesBackground ? circlesBackground : '#333333'}>
                        <MdChevronRight size={circleArrowSize} color={circlesColor ? circlesColor : '#ffffff'} />
                    </Circle>
                </div>}

            </div>
        </div>
    )
};

export { SliderView };