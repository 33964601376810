import React, {useContext, useReducer, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import styled from 'styled-components';
import {Col, Row} from 'react-grid-system';
import {HBox} from 'react-stylesheet';
import {AuthContext} from "../../../../providers/auth-provider";

import {colors, gutters, viewport} from '../../../00_base/variables';
import {Button, SimpleLoader} from '../../../01_atoms';
import {FieldText} from '../../../02_molecules';


const reducer = (state, action) => {
    return {...state, [action.name]: action.value}
};

const defaultForm = {
    password: "",
    passwordRepeat: ""
};


const ResetPassword = () => {
    const {resetPasswordRequest} = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false)
    const [form, dispatchForm] = useReducer(reducer, defaultForm);
    const [warningsActive, setWarningsActive] = useState(false);
    const [resetSuccessfully, setResetSuccessfully] = useState(false);
    const [tokenInvalidMessage, setTokenInvalidMessage] = useState(false);
    const history = useHistory();
    const token = history.location.search?.replace("?token=", "");

    if (!token) {
        history.push("/")
    }

    const {password, passwordRepeat} = form;

    const passwordValid = !!password
    const passwordMatch = password === passwordRepeat;

    const checkValidation = () => {
        const allValid = passwordValid && passwordMatch;
        setWarningsActive(!allValid);
        return allValid;
    };

    const handleChange = (name, value) => {
        dispatchForm({
            type: "change",
            name: name,
            value: value
        })
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const handleSuccess = () => setResetSuccessfully(true);
        const handleError = () => {
            window.alert("הקישור אינו תקף");
            setTokenInvalidMessage(true);
        }
        if (checkValidation()) {
            resetPasswordRequest(token, password, handleSuccess, handleError);
        } else {
            setIsLoading(false);
        }
    };

    const controlStyle = {marginBottom: gutters.md};
    const inputStyle = {};
    const fieldTextProps = {
        borderRadius: 0,
        border: 0,
        marginBottom: 0,
        controlStyle: controlStyle,
        inputStyle: inputStyle,
        changeHandler: handleChange
    };
    return (
        <Wrapper>
            <FormWrapper>
                <h2 className='form-title responsive-text'>איפוס סיסמא</h2>

                <p className='form-subtitle'>
                    {tokenInvalidMessage ? "הקישור אינו תקף" : resetSuccessfully ? "הסיסמא שונתה בהצלחה" :"הכניסו סיסמא חדשה"}
                </p>

                {!resetSuccessfully && !tokenInvalidMessage &&
                    <form id='reset-password-form' onSubmit={handleSubmit}>
                        <Row>
                            <Col sm={12}>
                                <FieldText type='password'
                                           label="סיסמה חדשה"
                                           name='password'
                                           value={password}
                                           {...fieldTextProps}
                                           errorMessage="יש להזין סיסמה"
                                           errorMessageActive={warningsActive && !passwordValid}/>
                            </Col>
                            <Col sm={12}>
                                <FieldText type='password'
                                           label="סיסמא בשנית"
                                           name='passwordRepeat'
                                           value={passwordRepeat}
                                           {...fieldTextProps}
                                           errorMessage="הסיסמאות לא תואמות"
                                           errorMessageActive={warningsActive && !passwordMatch}/>
                            </Col>
                        </Row>

                        <HBox justifyContent='center' marginTop="25px">
                            <Button width="100px" type='submit'>
                                {isLoading ? <SimpleLoader size={18} color={colors.white}/> : "המשך"}
                            </Button>
                        </HBox>
                    </form>
                }

                {(resetSuccessfully || tokenInvalidMessage) &&
                <HBox justifyContent='center' marginTop="25px">
                    <Button><Link to={tokenInvalidMessage ? "/he/forgot-password" : "/he/login"}>המשך</Link></Button>
                </HBox>}

            </FormWrapper>
        </Wrapper>
    )
}

export default ResetPassword;

const Wrapper = styled.div`
    // min-height: calc(100vh - 220px);  
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 ${gutters.sm};
    h2.form-title {
        text-align: center;
        margin-bottom: ${gutters.sm}; 
    }
`,

    FormWrapper = styled.div`
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center; 
        width: 66rem;  
        max-width: 100%; 
        form {
            margin-top: ${gutters.md} 
            width: 100%;
            .field-submit{
                width: 50%;
                input {
                    width: 100%;
                }
            }            
            @media screen and (min-width: ${viewport.sm}) {

            }
        }
    `;