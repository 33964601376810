import React, {useContext, useEffect, useState} from "react";
import SingleRotary from "./single-rotary/single-rotary"
import PropTypes from 'prop-types';
import styled from 'styled-components'
import _ from 'lodash';
import "./tool-rotaries-styles.scss";
import {FrameContext} from "../../05_screens/tool/screen-tool";

export const ToolRotaries = (
    {
        items,
        direction,
        rotaryBorderWidth,
        rotaryBorderColor,
        rotaryBorderRadius,
        size,
        rotarySize,
        rotaryWidth,
        rotaryDotStyle,
        showNumbers,
        numbersColor,
        numbersSize,
        degressAddOnClick,
        hideZero,
        numbersScale,
        numbersInside,
        startLocation,
        rotaryColor,
        nextFrameButton,
        itemBackgroundImage,
        dotCustomImage,
    }) => {
    const {handleFrameFinish, isFrameFinish} = useContext(FrameContext);

    const [userStatus, setUserStatus] = useState(items.map(({initialRotateValue}) => initialRotateValue || 0))

    useEffect(() => {
        if (!isFrameFinish) {
            setUserStatus(items.map(({status}) => status))
        }
    }, [isFrameFinish, items]);

    const handleChange = (index, status) => {
        let userStatusCopy = JSON.parse(JSON.stringify(userStatus))
        userStatusCopy[index] = status
        setUserStatus(userStatusCopy);

        if (_.isEqual(userStatusCopy, items.map(item => parseInt(item.answer)))) {
            setTimeout(handleFrameFinish, 100)
        }
    }

    const renderRotaries = () => {
        return items.map((singleRotary, index) => {
                return <SingleRotary {...singleRotary}
                                     backgroundImage={singleRotary.backgroundImage || itemBackgroundImage}
                                     degressAddOnClick={degressAddOnClick}
                                     showNumbers={showNumbers}
                                     startLocation={startLocation}
                                     numbersInside={numbersInside}
                                     hideZero={hideZero}
                                     numbersColor={numbersColor}
                                     numbersSize={numbersSize}
                                     numbersScale={numbersScale}
                                     isResolved={isFrameFinish}
                                     initialRotateValue={singleRotary[isFrameFinish ? "answer" : "initialRotateValue"]}
                                     key={index}
                                     rotaryBorderWidth={rotaryBorderWidth}
                                     rotaryBorderColor={rotaryBorderColor}
                                     rotaryBorderRadius={rotaryBorderRadius}
                                     size={size}
                                     rotaryDotStyle={rotaryDotStyle}
                                     rotaryWidth={rotaryWidth}
                                     rotarySize={singleRotary.rotarySize || rotarySize}
                                     dotCustomImage={singleRotary.dotCustomImage || dotCustomImage}
                                     onChange={degrees => handleChange(index, degrees)}/>
            }
        )
    }

    return (
        <>
            <RotariesContainer className={"rotaries-container"}
                               direction={direction}>
                {renderRotaries()}
            </RotariesContainer>
        </>
    )
}

const RotariesContainer = styled.div`
    &.rotaries-container {
        ${props => `
            flex-direction: ${props.direction};
        `}
    }
`;

ToolRotaries.defaultProps = {
    items: [],
    showNumbers: false,
    hideZero: true,
    numbersInside: true,
    startLocation: "top",
    degressAddOnClick: 4,
    color: "green",
    dotColor: "green",
    backgroundColor: "rgba(0,0,0,0.7)",
    rotaryColor: "rgba(0,0,0,0.7)",
    size: 100,
    rotarySize: 15,
    rotaryWidth: 5,
    rotaryBorderWidth: 0,
    rotaryBorderColor: "black",
    rotaryBorderRadius: 50,
    rotaryDotStyle: "dot"

};

ToolRotaries.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            degressAddOnClick: PropTypes.number,
            color: PropTypes.string,
            onChange: PropTypes.func,
            backgroundColor: PropTypes.string,
            isResolved: PropTypes.string,
            initialRotateValue: PropTypes.number,
            size: PropTypes.number,
            answer: PropTypes.number.isRequired,
        })
    ).isRequired,
    handleFinish: PropTypes.func,
    direction: PropTypes.oneOf(["row", "column"]),
    showNumbers: PropTypes.bool,
    hideZero: PropTypes.bool,
    numbersInside: PropTypes.bool,
    startLocation: PropTypes.oneOf(["top", "bottom"]),
    rotaryDotStyle: PropTypes.oneOf(["dot", "arrow", "outArrow", "line"]),
    degressAddOnClick: PropTypes.number,
    rotarySize: PropTypes.number,
    rotaryBorderWidth: PropTypes.number,
    rotaryBorderColor: PropTypes.string,
    rotaryBorderRadius: PropTypes.number,
    rotaryWidth: PropTypes.number,
    rotaryColor: PropTypes.string,
};