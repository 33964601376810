import {
    CREATE_ADVENTURE,
    DELETE_ADVENTURE,
    SET_ADVENTURES,
    SET_LOGIN_USER_ADVENTURES,
    SET_SINGLE_ADVENTURE,
    UPDATE_ADVENTURE
} from "../actions/adventure-actions";
import {SET_SINGLE_ADVENTURE_DATA, UPDATE_ADVENTURE_DATA} from "../actions/adventure-data-actions";
import {CREATE_FRAME, DELETE_FRAME, SET_ACTIVE_FRAME, SET_FRAMES, UPDATE_FRAME} from "../actions/frames-actions";
import {
    CREATE_RIDDLE,
    SET_ACTIVE_RIDDLE,
    SET_RIDDLES_AND_FRAMES,
    UPDATE_RIDDLE,
    SET_SHOW_IN_STORE_RIDDLES
} from "../actions/riddle-actions";
import {
    CREATE_ADVENTURE_TYPE,
    DELETE_ADVENTURE_TYPE,
    SET_ALL_ADVENTURE_TYPES,
    SET_SHOW_IN_SITE_ADVENTURE_TYPES,
    SET_SINGLE_ADVENTURE_TYPE,
    UPDATE_ADVENTURE_TYPE,
} from "../actions/adventure-type-actions";
import {
    CREATE_CATEGORY,
    DELETE_CATEGORY,
    SET_ACTIVE_CATEGORY,
    SET_CATEGORIES,
    UPDATE_CATEGORY
} from "../actions/categories-actions";
import {
    CREATE_ADVENTURE_TEMPLATE,
    DELETE_ADVENTURE_TEMPLATE,
    SET_ADVENTURE_TEMPLATES,
    UPDATE_ADVENTURE_TEMPLATE
} from "../actions/adventure-template-actions";
import AdventureTemplate from "../models/adventureTemplate";
import {
    CREATE_BLOG,
    DELETE_BLOG,
    SET_ACTIVE_BLOG,
    SET_ALL_BLOGS,
    UPDATE_BLOG
} from "../actions/blogs-actions";
import {
    CREATE_LANGUAGE,
    DELETE_LANGUAGE,
    SET_ACTIVE_LANGUAGE,
    SET_ALL_LANGUAGES,
    UPDATE_LANGUAGE
} from "../actions/languages-actions";

import {
    SET_ALL_TAGS,
    SET_SINGLE_TAG,
    CREATE_TAG,
    UPDATE_TAG,
    DELETE_TAG,
} from "../actions/tags-actions";

const initialState = {
    adventure: {},
    adventureData: {},
    adventureType: {},
    riddles: [],
    frames: [],
    activeFrame: null,
    activeRiddle: null,
    allAdventures: [],
    allAdventureTypes: [],
    showInSiteAdventureTypes: [],
    userAdventures: [],
    isUserAdventuresLoaded: false,
    activeCategory: {},
    allCategories: [],
    allAdventureTemplates: [],
    allAffiliates: [],
    allBlogs: [],
    activeBlog: null,
    allLanguages: [],
    allTags: [],
    showInStoreRiddles: []
};

const adventureReducer = (state = initialState, action) => {
    switch (action.type) {

        // ADVENTURES //
        case SET_ADVENTURES:
            return {
                allAdventures: action.allAdventures
            };

        case SET_LOGIN_USER_ADVENTURES:
            return {
                ...state,
                userAdventures: (state.userAdventures || []).concat(action.adventures),
                userAdventuresPageIndex: action.pageIndex,
                isUserAdventuresLoaded: true
            }
        case SET_SINGLE_ADVENTURE :
            return {
                ...state,
                ...action
            };
        case CREATE_ADVENTURE:
            return {
                ...state,
                adventure: action
            };
        case UPDATE_ADVENTURE:
            return {
                ...state,
                adventure: action
            };
        case DELETE_ADVENTURE:
            const filter = adventure => adventure.id !== action.id;
            return {
                ...state,
                allAdventures: state.allAdventures.filter(filter),
                userAdventures: state.userAdventures?.filter(filter),
                isUserAdventuresLoaded: true
            };


        // ADVENTURE TEMPLATES //
        case SET_ADVENTURE_TEMPLATES:
            return {
                ...state,
                allAdventureTemplates: action.allAdventureTemplates
            };
        case CREATE_ADVENTURE_TEMPLATE:
            const newItem = new AdventureTemplate(action.id, action.name);

            return {
                ...state,
                allAdventureTemplates: state.allAdventureTemplates.concat([newItem])
            };
        case UPDATE_ADVENTURE_TEMPLATE:
            const templateIndex = state.allAdventureTemplates.findIndex(adventure => adventure.id === action.id);
            const updatedItem = new AdventureTemplate(action.id, action.name);

            const allAdventureTemplates = state.allAdventureTemplates;
            allAdventureTemplates[templateIndex] = updatedItem;

            return {
                ...state,
                allAdventureTemplates: allAdventureTemplates
            };
        case DELETE_ADVENTURE_TEMPLATE:
            return {
                ...state,
                allAdventureTemplates: state.allAdventureTemplates.filter(
                    template => template.id !== action.id
                ),
            };


        // ADVENTURE TYPE //
        case SET_ALL_ADVENTURE_TYPES:
            return {
                ...state,
                allAdventureTypes: action.adventureTypes
            };
        case SET_SHOW_IN_SITE_ADVENTURE_TYPES: {
            return {
                ...state,
                showInSiteAdventureTypes: action.adventureTypes
            }
        }
        case CREATE_ADVENTURE_TYPE:
            return {
                ...state,
                allAdventureTypes: [action.adventureType].concat(state.allAdventureTypes)
            }
        case UPDATE_ADVENTURE_TYPE:
            return {
                ...state,
                allAdventureTypes: state.allAdventureTypes?.filter(type => type.id === action.adventureType.id ? action.adventureType.id : type),
                adventureType: action.adventureType
            }
        case SET_SINGLE_ADVENTURE_TYPE:
            return {
                ...state,
                adventureType: action.adventureType
            };
        case DELETE_ADVENTURE_TYPE:
            return {
                ...state,
                allAdventureTypes: state.allAdventureTypes?.filter(type => type.id !== action.adventureTypeId)
            }


        // ADVENTURE DATA //
        case SET_SINGLE_ADVENTURE_DATA:
            return {
                ...state,
                adventureData: action.adventureData
            };
        case UPDATE_ADVENTURE_DATA:
            return {
                ...state,
                adventureData: action.adventureData
            };


        // RIDDLES //
        case SET_RIDDLES_AND_FRAMES:
            return {
                ...state,
                riddles: action.riddles,
                frames: action.frames
            };
        case SET_ACTIVE_RIDDLE:
            return {
                ...state,
                activeRiddle: action.riddleId
            };
        case CREATE_RIDDLE:
            return {
                ...state,
                riddles: state.riddles.concat(action.riddle)
            };
        case UPDATE_RIDDLE:
            return {
                ...state,
                riddles: action.riddles
            };
        case SET_SHOW_IN_STORE_RIDDLES:
            return {
                ...state,
                showInStoreRiddles: action.show_in_store
            };

        // FRAMES //
        case    SET_FRAMES:
            return {
                ...state,
                frames: action.frames
            };
        case SET_ACTIVE_FRAME:
            return {
                ...state,
                activeFrame: action.frameId
            };
        case CREATE_FRAME:
            return {
                ...state,
                frames: state.frames.concat(action.frame)
            };
        case UPDATE_FRAME:
            const updateFrame = frame => {
                if (frame.id === action.frameId) {
                    return {...frame, [action.name]: action.value}
                }
                return frame;
            };
            return {
                ...state,
                frames: state.frames.map(updateFrame)
            };
        case DELETE_FRAME: {
            return {
                ...state,
                frames: state.frames.filter(frame => frame.id !== action.frameId)
            }
        }


        // CATEGORIES //
        case SET_CATEGORIES:
            return {
                ...state,
                allCategories: action.allCategories
            };
        case SET_ACTIVE_CATEGORY:
            return {
                ...state,
                activeCategory: action.category
            };
        case CREATE_CATEGORY:
            return {
                ...state,
                allCategories: state.allCategories.concat([action.category])
            };
        case UPDATE_CATEGORY:
            let itemIndex = state.allCategories.findIndex(category => category.id === action.id);
            const allCategories = state.allCategories;
            allCategories[itemIndex] = action.category;
            return {
                ...state,
                activeCategory: state.activeCategory?.id === action.category.id ? action.category : state.activeCategory,
                allCategories: allCategories
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                allCategories: state.allCategories.filter(
                    category => category.id !== action.id
                ),
            };

        // BLOGS //
        case SET_ALL_BLOGS:
            return {
                ...state,
                allBlogs: action.allBlogs
            };
        case SET_ACTIVE_BLOG:
            return {
                ...state,
                activeBlog: action.blog
            };
        case CREATE_BLOG:
            return {
                ...state,
                allBlogs: state.allBlogs.concat([action.blog])
            };
        case UPDATE_BLOG:
            const blogIndex = state.allBlogs.findIndex(blog => blog.id === action.id);
            const allBlogs = state.allBlogs;
            allBlogs[blogIndex] = action.blog;
            return {
                ...state,
                // activeBlog: state.activeBlog?.id === action.blog.id ? action.blog : state.activeBlog,
                allBlogs: allBlogs
            };
        case DELETE_BLOG:
            return {
                ...state,
                allBlogs: state.allBlogs.filter(
                    blog => blog.id !== action.id
                ),
            };

        // LANGUAGES //
        case SET_ALL_LANGUAGES:
            return {
                ...state,
                allLanguages: action.allLanguages
            };
        case SET_ACTIVE_LANGUAGE:
            return {
                ...state,
                activeLanguage: action.activeLanguage
            };
        case CREATE_LANGUAGE:
            return {
                ...state,
                allLanguages: state.allLanguages.concat([action.language])
            };
        case UPDATE_LANGUAGE:
            const langIndex = state.allLanguages.findIndex(language => language.id === action.id);
            const allLanguages = state.allLanguages;
            allLanguages[langIndex] = action.language;
            return {
                ...state,
                activeLanguage: state.activeLanguage?.id === action.language.id ? action.language : state.activeLanguage,
                allLanguages: allLanguages
            };
        case DELETE_LANGUAGE:
            return {
                ...state,
                allLanguages: state.allLanguages.filter(
                    language => language.id !== action.id
                ),
            };

        // TAGS //
        case SET_ALL_TAGS:
            return {
                ...state,
                allTags: action.allTags
            };
        case CREATE_TAG:
            return {
                ...state,
                allTags: state.allTags.concat([action.tag])

            };
        case UPDATE_TAG:
            let tagItemIndex = state.allTags.findIndex(tag => tag.id === action.id);
            const allTags = state.allTags;
            allTags[tagItemIndex] = action.tag;
            return {
                ...state,
                activeCategory: state.activeCategory?.id === action.category.id ? action.category : state.activeCategory,
                allCategories: allTags
            };
        case DELETE_TAG:
            return {
                ...state,
                allTags: state.allTags.filter(
                    tag => tag.id !== action.id
                ),
            };
        default:
            return state;
    }
};

export default adventureReducer;