import React, {useEffect, useState} from 'react';
import {Element} from 'react-stylesheet';
import {gutters} from '../../../00_base/variables';
import {createNewId} from "../../../00_base/helpers/create-new-id";
import {Button} from '../../../01_atoms';
import {FieldSelect, FieldText, FilesUploader} from '../../../02_molecules';
import {CreateToolGallerySection} from "./create-tool-gallery-section";
import "./create-tool-gallery.scss";

const initialSection = {
    id: 0,
    icon: "",
    images: [],
    frames: [],
    hints: [],
    add_to_treasure: false,
    editable: ''
};

const CreateToolGallery = (
    {
        items = [],
        images_max,
        images_min,
        gallery_view = "blocks",
        userData,
        advancedDataType = "text",
        handleToolDataChange,
    }) => {
    const [galleryItems, setGalleryItems] = useState(items);
    const [galleryView, setGalleryView] = useState(gallery_view);
    const [advancedData, setAdvancedData] = useState(items ? items.some(item => (item.hints && item.hints.length > 0)) : []);

    useEffect(() => {
        handleToolDataChange("items", galleryItems)
    }, [handleToolDataChange, items, galleryItems]);

    useEffect(() => {
        handleToolDataChange("gallery_view", galleryView);
    }, [handleToolDataChange, gallery_view, galleryView]);

    const addSection = () => {
        const newId = createNewId(galleryItems);
        setGalleryItems(galleryItems.concat({...initialSection, id: galleryItems.length > 0 ? newId : 0}));
    };

    const removeSection = (itemId) => {
        setGalleryItems(galleryItems.filter(item => item.id !== itemId));
    };

    const handleChange = (name, value, id) => {
        const current = galleryItems.find(item => item.id === id) || {};
        current[name] = value;
        setGalleryItems(galleryItems);
        handleToolDataChange("items", galleryItems)
    };

    const toggleNumbers = () => {
        setAdvancedData(!advancedData);
        for (let i = 0; i < galleryItems.length; i++) {
            if (!advancedData) {
                handleChange('hints', galleryItems[i].images.map((item, index) => index + 1), i, -1)
            } else {
                handleChange('hints', null, i)
            }
        }
    };

    const renderGroup = (gallery, index) => {
        return <CreateToolGallerySection key={index}
                                         gallery={gallery}
                                         index={index}
                                         advancedData={advancedData}
                                         setAdvancedData={setAdvancedData}
                                         handleToolDataChange={handleToolDataChange}
                                         toggleNumbers={toggleNumbers}
                                         handleChange={handleChange}
                                         removeSection={() => removeSection(gallery.id)}
                                         advancedDataType={advancedDataType}
                                         galleryItems={galleryItems}/>
    }

    const galleryViewOption = (option) => {
        return {id: option, value: option, label: option}
    }

    return (
        <div className="create-tool-gallery">
            <div className="gallery-details">
                <div className="gallery-details-fields">
                    <Element padding={`${gutters.sm} 0 ${gutters.sm} ${gutters.sm}`}>
                        <FieldText label="מינימום תמונות"
                                   placeholder="מספר תמונות מינימלי"
                                   name="images_min"
                                   value={images_min}
                                   changeHandler={(name, value) => handleToolDataChange(name, value)}/>
                    </Element>
                    <Element padding={`${gutters.sm}`}>
                        <FieldText label="מקסימום תמונות"
                                   placeholder="מספר תמונות מקסימלי"
                                   name="images_max"
                                   value={images_max}
                                   changeHandler={(name, value) => handleToolDataChange(name, value)}/>
                    </Element>
                    <Element marginRight="auto">
                        <FieldSelect options={["blocks", "grid", "gallery"].map(galleryViewOption)}
                                     name="gallery_view"
                                     label="אופן תצוגת הגלריה"
                                     value={galleryViewOption(galleryView)}
                                     changeHandler={(name, value) => setGalleryView(value.value)}/>
                    </Element>
                </div>

                {userData &&
                <FilesUploader name={'userData'}
                               previewStyle={{width: '100px', height: '100px', marginTop: 0, marginBottom: 0,}}
                               previewWidth={'140px'}
                               previewHeight={'140px'}
                               value={userData}
                               label={'תמונות של היוזר'}
                               triggerValue={'העלה תמונות של היוזר'}
                               setIsFileLoading={data => console.log('data', data)}
                               changeHandler={(name, value) => handleToolDataChange(name, value, -1)}/>
                }
                {items.map(renderGroup)}
            </div>

            <div className="gallery-add">
                <Button block
                        onClick={addSection}
                        value='הוסף גלריה'/>
            </div>
        </div>
    )
};

CreateToolGallery.defaultProps = {};

CreateToolGallery.propTypes = {};


export default CreateToolGallery;