import React, {useEffect, useState} from 'react';
import {HBox} from 'react-stylesheet';
import {Link} from "react-router-dom";
import classNames from "classnames";
import UserReview from "./user-review";
import {Button, LoaderPageContent, SimpleLoader} from "../../../01_atoms";
import {Modal} from "../../../03_organisms";
import {Translation} from "../../../../languages/";
import {DICT} from "../../../../languages/dictionary-names";
import {fetchData} from "../../../../store/actions/fetch-data";
import './screen-gallery.scss';

const ScreenGallery = ({homepage}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [allReviews, setAllReviews] = useState([]);
    const [pageIndex, setPageIndex] = useState(0);
    const [activeReviewId, setActiveReviewId] = useState(-1);

    useEffect(() => {
        const url = `reviews?page=${pageIndex}` // adventureId ? `reviewByAdventure/${adventureId}/?page=${pageIndex}` :
        setIsLoading(true);

        fetchData(
            'get',
            url,
            "",
            success => {
                setAllReviews(allReviews.concat(success));
                setIsLoading(false);
            }
        )
    }, [pageIndex, setAllReviews]);

    const activeReview = allReviews.find(review => review.id === activeReviewId);


    return (
        <div className={classNames("screen-gallery", {container: !homepage})}>
            {activeReview &&
            <Modal isActive={activeReview && "active"}
                   hideTrigger={true}
                   contentStyle={{width: "90%", height: "90%"}}
                   closePreActions={() => setActiveReviewId(-1)}>
                <UserReview review={activeReview}
                            isSingle={true}/>
            </Modal>
            }
            <h1 className={"fs-8 fs-md-10 text-center"}><Translation id={DICT.GALLERY_TITLE}/></h1>

            <div className={'screen-gallery-wrapper'}>
                {allReviews.slice(0, homepage ? 3 : 100).map((review, index) => {
                    return <UserReview key={index}
                                       homepage={homepage}
                                       setActiveReview={setActiveReviewId}
                                       review={review}/>
                })}
            </div>
            {isLoading ?
                <div className={'py-3'}>
                    <LoaderPageContent/>
                </div>
                :
                <HBox className={'py-3'} justifyContent={'center'}>
                    {homepage ?
                        <Button>
                            <Link to="/he/gallery" color={"white"}>
                                <Translation id={DICT.GALLERY_MORE_IMAGES}/>
                            </Link>
                        </Button>
                        :
                        <Button onClick={() => setPageIndex(pageIndex + 1)}

                                value={<Translation id={DICT.GALLERY_MORE_IMAGES}/>}/>
                    }
                </HBox>
            }
        </div>
    )
};

export default ScreenGallery;