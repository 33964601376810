import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Element} from 'react-stylesheet';
import {colors, font_size, zIndex} from '../../00_base/variables';
import {Button, Icon} from '../../01_atoms';
import "./navigation.scss";
import {Link} from "react-router-dom";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/all";
import {ThemeContext} from "../../../providers/theme-provider";

const Navigation = ({
                        items,
                        defaultActiveItem,
                        dir,
                        childDir,
                        style,
                        listStyle,
                        itemStyle,
                        childNavigationStyle,
                        activeItemStyle,
                        spaceBetweenItems,
                        hiddenNavigation,
                        navWrapperStyle,
                        navWrapperHiddenStyle,
                        navigationComponent,
                        className = ""
                    }) => {
    const [activeItemId, setActiveItemId] = useState(defaultActiveItem);
    const [hiddenNavigationActive, setHiddenNavigationActive] = useState(false);
    const activeItem = items.find(item => item.id === activeItemId);
    const {mode} = useContext(ThemeContext);
    const textColor = mode === "light" ? colors.black : colors.white;
    const renderItem = item => {
        const isActive = activeItemId > 0 && activeItemId === item.id;

        let NavLink = () => {

            if (item.route && !isActive) {
                return <Button className="px-0"
                               color={textColor}
                               onClick={item.onClick}
                               background={'transparent'}>
                    <Link to={item.href} style={{...navLink, ...item.style}}>
                        {item.label}
                    </Link>
                </Button>;

            } else if (item.onClick) {
                return <Button className="px-0"
                               color={textColor}
                               background="transparent"
                               onClick={item.onClick}
                               style={{...navLink, ...item.style}}>{item.label}</Button>;
            } else {
                return <Button className="px-0 d-flex align-items-center"
                               color={textColor}
                               background='transparent'
                               style={{...navLink, ...item.style}}>
                    {item.childNavigation && (activeItem && activeItem.childNavigation) ? <MdArrowDropUp color={textColor} size={20}/> :
                        item.childNavigation && <MdArrowDropDown size={20} color={textColor}/>}
                    {(!!item.childNavigation || isActive) ?
                        <span style={{...navLink, fontWeight: isActive && "bold", color:textColor}}>{item.label}</span> :
                        <a href={item.href}
                           target={item.target}
                           style={{...navLink, fontWeight: isActive && "bold", color:textColor}}>
                            {item.label}
                        </a>
                    }
                </Button>;
            }
        };

        return (
            <li key={item.id}
                onClick={() => setActiveItemId(isActive ? -1 : item.id)}
                className="nav-item"
                style={{...item.style, ...isActive && activeItemStyle}}>
                <NavLink/>
            </li>
        )
    };


    const hiddenWrapperStyle = {
        width: '100%',
        zIndex: zIndex.base,
        ...hiddenNavigation && navWrapperDefaultStyle,
        ...navWrapperStyle,
        ...!hiddenNavigationActive && hiddenNavigation && navWrapperHiddenStyle
    };

    return (
        <Nav style={style} className={`navigation ${className}`}>

            {!!hiddenNavigation &&
            <Element width={'55px'}>
                <Icon
                    style={{position: 'relative', zIndex: zIndex.medium}}
                    icon={hiddenNavigationActive ? hiddenNavigation.closeIcon : hiddenNavigation.openIcon}
                    onClick={() => setHiddenNavigationActive(!hiddenNavigationActive)}
                    color={'#fff'}/>
            </Element>
            }


            <Element style={{...hiddenNavigation && hiddenWrapperStyle, width: '100%'}}>
                {navigationComponent ? navigationComponent :
                    <>
                        <NavList dir={dir} listStyle={listStyle}>
                            {items.map(renderItem)}
                        </NavList>

                        {activeItem && activeItem.childNavigation &&
                        <ChildNavList style={childNavigationStyle} dir={childDir}>
                            {activeItem.childNavigation.map(renderItem)}
                        </ChildNavList>
                        }
                    </>
                }
            </Element>
        </Nav>
    )
}

const navLink = {
    color: 'inherit',
    textDecoration: 'none',
    fontSize: font_size.xs,
    cursor: "pointer",
    whiteSpace: "nowrap"
}
const Nav = styled.nav`
`;

const NavList = styled.ul`
    display: flex;
    flex-direction: ${props => props.dir};
    list-style: none;
    padding: 0;
    margin: 0;
`;

const ChildNavList = styled.ul`
    display: flex;
        background-color:transparent;
    position: absolute;
    flex-direction: ${props => props.dir};
    list-style: ${props => props.listStyle || 'none'};
    padding: 0;
    margin: 0;
`;


const navWrapperDefaultStyle = {
    position: 'fixed',
    left: 0,
    width: 0,
    top: 0,
    bottom: 0,
    padding: '50px',
    transform: 'translateX(0)',
    transition: 'transform .5s',
    overflow: 'hidden'
};

Navigation.defaultProps = {
    dir: 'row',
    childDir: 'column',
    listStyle: 'none',
    spaceBetweenItems: '0',
    itemStyle: {
        padding: '10px'
    },
    activeItemStyle: {
        fontWeight: 'bold'
    },
    childNavigationStyle: {
        backgroundColor: colors.white,
        top: "62px",
        border: "1px solid #000",
        borderRadius: "2px"
    },
    navWrapperStyle: {
        width: '100%',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        padding: '50px',
        transform: 'translateX(0)',
        transition: 'transform .5s',
        overflow: 'hidden'
    },
    navWrapperHiddenStyle: {
        transform: 'translateX(-100%)'
    }
};
Navigation.propTypes = {
    items: PropTypes.array.isRequired,
    dir: PropTypes.oneOf(['row', 'column']),
    listStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    spaceBetweenItems: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    itemStyle: PropTypes.object,
    activeItemStyle: PropTypes.object,
    childDir: PropTypes.oneOf(['row', 'column']),
    childNavigationStyle: PropTypes.object,
    hiddenNavigation: PropTypes.object,
    navWrapperStyle: PropTypes.object,
    navWrapperHiddenStyle: PropTypes.object,
};

export default Navigation;