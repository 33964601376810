import React, {useState} from "react";
import {Button} from "../../../01_atoms";
import {FieldText} from "../../../02_molecules";
import {FilterIndicators, Modal} from "../../../03_organisms";
import Register from "../../../05_screens/authentication/register/register";
import "./table-items-header.scss";

export const TableItemsHeader = ({clickHandler, filterLength, removeAllFilters}) => {
    const [searchValue, setSearchValue] = useState("");
    const cleanFilter = () => {
        removeAllFilters();
        setSearchValue("");
    };
    return (
        <>
            <div className="table-items-header" style={{display: "flex", justifyContent: "space-between"}}>
                <div className="table-items-header-search" style={{width: "300px"}}>
                    <FieldText placeholder="שם / משפחה / טלפון / אימייל"
                               name={"search"}
                               controlStyle={controlStyle}
                               changeHandler={(name, value) => setSearchValue(value)}
                               inputStyle={inputStyle}/>
                    <Button value="חיפוש"
                            onClick={() => clickHandler(searchValue)}
                            className="table-items-search"/>
                </div>

                <Modal triggerClassName="table-items-add"
                       triggerComponent={Button}
                       triggerValue={"+ צור משתמש חדש"}>
                    <Register mode={"register"}
                              title="יצירת משתמש חדש"/>
                </Modal>

            </div>
            {searchValue &&
            <FilterIndicators items={[{name: searchValue, removeFilter: cleanFilter}]}
                              length={filterLength}
                              removeAllFilters={cleanFilter}/>}
        </>
    )
}

const inputStyle = {backgroundColor: "#fff", border: 0, borderRadius: "2px"};
const controlStyle = {margin: '0 0 0 20px'};