import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import Wrapper from './screen-transitions/transitions';
import * as adventureActions from '../../../store/actions/adventure/adventure-actions';
import {TransitionGroup, CSSTransition} from "react-transition-group";
import ScreenHomepage from "../../05_screens/homepage";
import ScreenInstructions from "../../05_screens/instructions";
import ScreenMenu from "../../05_screens/menu";
import ScreenRiddle from "../../05_screens/riddle/screen-riddle";
import ScreenHomepageFilming from "../../05_screens/homepage/screen-homepage-filming";


const AppContent = ({location, history}) => {
    const theme = JSON.parse(localStorage.getItem("theme"));
    const adventureId = localStorage.getItem("adventureId");


    const dispatch = useDispatch();

    useEffect(() => {
         if (adventureId && adventureId !=="undefined" && history.location.pathname !== "/") {
             const setAdventureFrame = (adventureStore) => {
                 dispatch(adventureActions.setAdventureFrames(adventureStore, () => {}))
             };
            dispatch(adventureActions.setSingleAdventure(adventureId, setAdventureFrame))
        } else {
            if (!adventureId &&
                !history.location.pathname.includes("around-the-world") &&
                !history.location.pathname.includes("tikshoret-mekademet")) {
                history.push("/");
            }
        }
    }, [adventureId, dispatch, history]);

    useEffect(() => {
        if (!localStorage.getItem('adventure-language') && localStorage.getItem('adventure-language') !== "null") {
            localStorage.setItem('adventure-language', "Hebrew")
        }
    }, []);

    return (
        <Wrapper id={'app-routes'}>
            <TransitionGroup>
                <CSSTransition key={location.key} timeout={{enter: 300, exit: 300}} classNames={'fade'}>
                    <section className="route-section">
                        <Switch location={location}>
                            <Route exact path={'/:id?'} component={(props) => <ScreenHomepage {...props} theme={theme}/>}/>
                            <Route path={'/tikshoret-mekademet'} component={ScreenHomepageFilming} theme={theme}/>
                            <Route path={'/rosh-ha-shana/:id'} component={(props) => <ScreenHomepage {...props} theme={theme}/>}/>
                            <Route path={'/around-the-world/:id'} component={(props) => <ScreenHomepage {...props} theme={theme}/>}/>
                            <Route path={'/instructions'} component={(props) => <ScreenInstructions {...props} theme={theme}/>}/>
                            <Route path={'/menu/:id'} component={(props) => <ScreenMenu {...props} theme={theme}/>}/>
                            <Route path={'/menu'} component={(props) => <ScreenMenu {...props} theme={theme}/>}/>
                            <Route path={'/riddle/:id/:frameIndex?'} component={(props) => <ScreenRiddle {...props} theme={theme}/>}/>
                        </Switch>
                    </section>
                </CSSTransition>
            </TransitionGroup>
        </Wrapper>
    );
};

export default withRouter(AppContent); 