import React, {useEffect, useReducer, useState} from "react";
import classNames from "classnames";
import {FieldText, FieldTextArea} from "../../02_molecules";
import {MdArrowDropDown, MdArrowDropUp, MdClose} from "react-icons/all";
import {Button, SimpleLoader} from "../../01_atoms";
import "./create-faq.scss";
import {colors} from "../../00_base/variables";

const EDIT = "EDIT";
const ADD = "ADD";
const REMOVE = "REMOVE";
const SET = "SET";

const defaultSection = {
    q: "",
    a: ""
}

const reducer = (state, action) => {
    if (action.type === SET) {
        return action.sections;
    }
    if (action.type === ADD) {
        if (state) {
            return state.concat(defaultSection);
        }
        return [defaultSection]
    }
    if (action.type === REMOVE) {
        return state.filter((item, index) => index !== action.index);
    }
    if (action.type === EDIT) {
        state[action.index] = {...state[action.index], [action.name]: action.value};
        return state;
    }
    return state;
};

export const CreateFaq = ({sections, handleSubmit}) => {
    const [faq, dispatchFaq] = useReducer(reducer, sections);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        dispatchFaq({
            type: SET,
            sections: sections
        })
    }, [sections]);

    const renderSection = (section, index) => {
        return <RenderSection key={index}
                              index={index}
                              section={section}
                              handleChange={dispatchFaq}/>;
    };

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setIsLoading(false);
            }, 800);
        }
    }, [isLoading]);

    const addSection = () => dispatchFaq({type: ADD});

    const onSubmit = () => {
        setIsLoading(true);
        handleSubmit(faq);
    };

    return (
        <div className={"create-faq"}>
            <p>{`שאלות ותשובות ${faq?.length > 0 ? `(${faq.length})` : ""}`}</p>

            {faq?.map(renderSection)}

            <Button onClick={addSection}
                    className={"ml-3"}
                    value={"הוסף שאלה"}/>

            <Button onClick={onSubmit}
                    background={colors.action}
                    style={{width: "100px"}}
                    value={isLoading ? <SimpleLoader size={20} color={colors.white}/> : "שמור"}/>

        </div>
    )
};

const RenderSection = ({section, index, handleChange}) => {
    const [isActive, setIsActive] = useState(true);

    const onChange = (name, value) => handleChange({type: EDIT, index, name, value});
    const handleRemove = () => window.confirm("למחוק את השאלה?") && handleChange({type: REMOVE, index});
    const toggle = () => setIsActive(!isActive);

    const IconDrop = isActive ? MdArrowDropUp : MdArrowDropDown;
    return (
        <div key={index} className={classNames("create-faq-section", {active: isActive})}>
            <div className={"d-flex justify-content-between align-items-center mb-3"}>
                <FieldText changeHandler={onChange}
                           name={"q"}
                           controlStyle={{marginBottom: 0}}
                           placeholder={"הכנס שאלה"}
                           value={section.q}/>
                <div className={"d-flex align-items-center pr-3"}>
                    <MdClose size={20} cursor={"pointer"} onClick={handleRemove}/>
                    <IconDrop size={30} cursor={"pointer"} onClick={toggle}/>
                </div>
            </div>
            {isActive &&
            <FieldTextArea changeHandler={onChange}
                           label={"תשובה"}
                           name={"a"}
                           wysiwyg={true}
                           value={section.a}/>
            }
        </div>
    )
}