import React from 'react';
import {Container} from 'react-grid-system';
import {useSelector} from "react-redux";
import {Link} from 'react-router-dom';
import {AdventuresBlock} from "../../website/pages/homepage/hp-block-adventures";
import {AdventuresForBusinessCard} from "../../03_organisms";
import {selectAllAdventureTypes} from "../../../store/selectors";
import "./screen-not-found.scss"

const errorImgSrc = "https://storage.googleapis.com/assets.youdoadventures.com/website/404.png";

const ScreenNotFound = () => {
    const adventureTypes = useSelector(selectAllAdventureTypes);
    const ids = [31, 40, 302];
    const adventuresItems = adventureTypes.filter(item => ids.some(id => id === item.id));
    return (
        <Container className={"container  error-404-container"}>

            <div className={"error-404-data-container py-7 flex-md-row-reverse"}>
                <img src={errorImgSrc} alt={"404"} className={"error-404-image"}/>
                <div className={"error-404-text-container"}>
                    <h2 className={"m-0 mb-4 fs-8 fs-md-10"}>אופס.. משהו קרה</h2>
                    <h6 className={"m-0"}>
                        העמוד שחיפשתם לא קיים.
                        <br/>
                        לחצו
                        <Link to={`/`} className={"error-404-back-link"}> כאן </Link>
                        כדי לחזור לעמוד הבית
                    </h6>
                </div>
            </div>

            {adventuresItems?.length > 0 &&
            <div className="single-adventure-similar">
                <h3>אולי יעניין אותך?</h3>
                <AdventuresBlock items={adventuresItems} hideDetails={true}/>
            </div>}

            <div className="py-md-7 py-4">
                <AdventuresForBusinessCard/>
            </div>
        </Container>
    );
};
export default ScreenNotFound;