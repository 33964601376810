import {screenWidth} from "../../00_base/variables/v-breakpoints";
import styled, {keyframes} from "styled-components";
import {gutters} from "../../00_base/variables";

const Shake = keyframes`
    10%, 90% { transform: translate3d(-1px, 0, 0); }
    20%, 80% { transform: translate3d(2px, 0, 0); }
    30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
    40%, 60% { transform: translate3d(4px, 0, 0); }  
`;
export const PageMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height:100%;
    overflow: auto;
    background-color: #a6dcea;
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-track {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        display: none;
    }
    h1.lobby-title{
        color: #fff;
        text-align: center;
        font-size: 40px;
    }
    &.adventure-type-40 {
        .lobby-title {
            color:#fff;
        }
    }
    .lobby-icon-wrapper {
        text-align: center;
        &.resolve {
            opacity: 1;
        }
    }
    
    &.adventure-type-311 {
        .lobby-icon-wrapper {
            &:nth-child(odd) {
                text-align: right;
            }   
            &:nth-child(even) {
                text-align: left;
            }
            &:nth-child(5) {
                width: 100%;
                text-align: center;
            }    
        }
    }
`;


export const PageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    background-size: cover;
    // background-color: #a6dcea;
    background-image: url(${props => props.mobile || props.tablet || props.desktop}); //  || BACKGROUND_BLUE
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed
    @media (min-width: ${screenWidth.tablenMin}) {
        background-image: url(${props => props.tablet || props.mobile});
    }
    @media (min-width:  ${screenWidth.desktopMin}) {
        background-image: url(${props => props.desktop || props.tablet || props.mobile});
    }
    .adventure-4494 & {
        background-size: contain;
        background-position: top center;
    }
    .adventure-type-311 & {
        // height: auto
    }
    &::-webkit-scrollbar {
        display: none;
    }

    &::-webkit-scrollbar-track {
        display: none;
    }

    &::-webkit-scrollbar-thumb {
        display: none;
    }
`;
export const PageContainer = styled.div`
    display: flex; 
    flex-direction: column;
    justify-content: center; 
    min-height: 100%;
    flex: 1; 
    padding: 0;
`;

export const Cover = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 500;
    background-color: #a6dcea;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const IconsWrapper = styled.div`
    .shake {
        animation: ${Shake} 0.82s cubic-bezier(.36,.07,.19,.97) both;
    }`;

export const IconWrapper = styled.div`
    padding: 1rem .5rem;
    width: 50%;
    text-align: center;
    @media screen and (min-width: ${screenWidth.tabletPortraitMin}){
         width: 33.33%;
    }
`;

export const RiddleIconLabel = styled.p`
    margin: 0;
    font-size: ${props => props.fontSize || "14px"};
    text-align: center;
    color:#000
`;

export const IconImage = styled.img`
    max-width: 100%;
    @media (max-width: 640px) {
     
    }

    @media (min-width: 640px) {
        // width: 100px;
           max-width: ${props => props.style?.mobileWidth || "100%"}
    }
    @media (min-width: 768px) {
        width: 180px;
    }
`;

export const TopLogo = styled.div`
    padding: 0;
    padding-top: 15px;
    .game-logo {
        width: 40rem;
        margin: 0 0 0 auto;
    }
    
    @media screen and (min-width: ${screenWidth.tabletPortraitMin}) and (max-width: ${screenWidth.tabletPortraitMax}) {
        padding-top: 0;
        .game-logo {
             width: 50rem;
             margin: 0 auto;
        }
    }
    
    @media screen and (min-width: ${screenWidth.tabletPortraitMin}){
        .game-logo {
            margin-right: 0;
        }
    }
     
    @media screen and (min-width: ${screenWidth.tabletLandscapeMin}) and (max-width: ${screenWidth.tabletLandscapeMax}) {
        padding-top: 0;
        .game-logo {
             width: 51rem;
        }
    }
    
    @media screen and (min-width: ${screenWidth.desktopMin}) and (max-width: ${screenWidth.desktopMax}) {
       padding-top: 0;
        .game-logo {
             width: 51rem;
        }
    }
    
    @media screen and (min-width: ${screenWidth.desktopLargeMin}) and (max-width: ${screenWidth.desktopLargeMax}) {
        padding-top: 0;
        .game-logo {
             width: 51rem;
        }
    }
    
    @media screen and (min-width: ${screenWidth.desktopMaxMin}) {
        padding-top: 0;
        .game-logo {
             width: 51rem;
        }
    }
    
     @media screen and (max-height: 900px) {
        .game-logo {
             width: 65rem;
        }
     }
     @media screen and (max-height: 790px) {
        .game-logo {
             width: 55rem;
        }
     }
     @media screen and (max-height: 690px) {
        .game-logo {
             width: 33rem;
             
        }
     }
     @media screen and (max-width: 360px) {
        .game-logo {
             width: 25rem;
             margin-right: 30px;
             
        }
     }
     @media screen and (max-height: 669px) and (min-width: 640px) {
        margin-right: 170px;
     }
     
`;

export const BottomBackground = styled.div`
    display: flex;
    padding: 20px 0;
    justify-content: center;
    align-items:  center;
    background-size: cover;
    background-position: bottom left;
    position: relative; 
    overflow: visible; 
    .game-logo {
        position: relative;
        width: 16rem;
    }
    @media (min-width: 640px) {
        justify-content: flex-end;
        padding: 0 50px 50px 100px;
        .game-logo {
             width: 30rem;
            
            // top: -150px;
        }
    }
    
`;

export const wrapper = {};

export const iconsWrapper = {
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
    maxWidth: '60rem',
    display: 'flex',
    flex: 1
};
export const iconsContainer = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center'
};
export const iconWrapper = {
    paddingTop: "5px",
    paddingBottom: "5px"
}
export const iconsFlex = {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: `${gutters.sm}`,
    // alignItems: 'center',
    justifyContent: 'center'
};

export const iconLink = {
    display: 'block',
    width: '100%',
    backgroundColor: 'transparent',
    border: 0,
    cursor: 'pointer'
};