import React from 'react';
import ForgotPassword from "./forgot-password";
import {Page} from "../../../04_layout";

const ScreenForgotPassword = (props) => {
    const language = props.match.params.lang || "he";
    return (
        <Page style={{justifyContent: 'center'}} className={`forgot-password language-${language}`}>
            <ForgotPassword/>
        </Page>
    )
};

export default ScreenForgotPassword;