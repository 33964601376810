import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {MdMoreVert, MdComment, MdClear} from "react-icons/md";
import {selectAllAdventureTypes} from "../../../../store/selectors";
import {AuthContext} from "../../../../providers/auth-provider";
import {dateFormat} from "../../../../helpers/dates/date-format";
import {Circle} from "../../../01_atoms";
import {FieldCheckbox, FieldText} from "../../../02_molecules";
import {TableInnerMenu, Td, TdContent, Tr} from "../../../03_organisms/table";
import {AdminConversation, ToggleIcon} from "../../../03_organisms";

export const AdventureItem = (
    {
        item,
        index,
        innerMenuItems,
        setInnerMenuActive,
        menuActive,
        changeHandler,
        toggleMarkItem,
    }) => {
    const {isTom, isCreator} = useContext(AuthContext);
    const adventureTypes = useSelector(selectAllAdventureTypes);
    const [isOpen, setIsOpen] = useState(false);
    // const [adventureType, setAdventureType] = useState();
    const adventure_type = adventureTypes?.find(adventureType => adventureType.id === item.adventuretype);

    useEffect(() => {
        if (adventureTypes) {
            // setAdventureType(adventureTypes.find(adventureType => adventureType.id === item.adventuretype));
        }
    }, [adventureTypes, item]);

    const handleChange = (name, value) => {
        changeHandler({...item, [name]: value});
    };

    const handleAdminCommentChange = comments => {
        const updatedData = {
            ...item.json_data,
            admin_comments: comments || []
        };
        handleChange("json_data", updatedData);
    };

    const addNewAdminComment = (newComment) => {
        const updatedData = {
            ...item.json_data,
            admin_comments: item.json_data?.admin_comments?.concat(newComment) || [newComment]
        };
        handleChange("json_data", updatedData);
    };

    const handleDeleteComment = (id) => {
        const updatedData = {
            ...item.json_data,
            admin_comments: item.json_data?.admin_comments.filter((item) => item.id !== item)
        };
        handleChange("json_data", updatedData);
    };

    const price =
        item.calculated_price ? parseInt(item.calculated_price).toFixed(0) :
            adventure_type?.base_price ? parseInt(adventure_type?.base_price).toFixed(0) : "?";

    const CommentsIcon = isOpen ? MdClear : MdComment;
    const admin_comments = item.json_data?.admin_comments;
    return (
        <>
            <Tr key={index}>
                {isTom &&
                <>
                    <Td>
                        <TdContent>
                            <FieldCheckbox options={['סמן']}
                                           controlStyle={{marginBottom: 0}}
                                           changeHandler={(name, isChecked) => toggleMarkItem(item.id, isChecked)}
                                           name={`mark`}
                                           hideOptionLabel={true}/>
                        </TdContent>
                    </Td>
                    <Td>
                        <TdContent style={{fontWeight: "bold"}}>{item.id || "-"}</TdContent>
                    </Td>
                </>
                }
                <Td>
                    <TdContent>
                        <FieldText name={"user_email"}
                                   changeHandler={handleChange}
                                   value={item.user_email}
                                   controlStyle={controlStyle}
                                   inputStyle={inputStyle}/>
                    </TdContent>
                </Td>
                <Td>
                    <TdContent>
                        {/*{item.name || "-"}*/}
                        <FieldText name={"title"}
                                   changeHandler={handleChange}
                                   value={item.title}
                                   controlStyle={controlStyle}
                                   inputStyle={inputStyle}/>
                    </TdContent>
                </Td>
                <Td><TdContent style={{whiteSpace: "nowrap"}}>
                    {adventure_type?.name || "-"}
                    {/*<FieldSelect name={"adventureType"}*/}
                    {/*             value={{id: adventureType?.id, value: adventureType?.id, label: adventureType?.name}}*/}
                    {/*             changeHandler={(name, value) => changeAdventureType(value.id)}*/}
                    {/*             options={adventureTypes ? adventureTypes.map(option => {*/}
                    {/*                 return {id: option.id, value: option.id, label: option.name}*/}
                    {/*             }) : []}*/}
                    {/*             hideArrow={true}*/}
                    {/*             customControlStyle={{border: 0}}*/}
                    {/*             customInputStyle={{position: "absolute"}}*/}
                    {/*             controlStyle={{margin: 0}}*/}
                    {/*             inputStyle={{border: 0}}/>*/}
                </TdContent></Td>
                <Td><TdContent>{price}</TdContent></Td>
                <Td><TdContent>{dateFormat(item.created_at)}</TdContent></Td>
                <Td><TdContent>{item.passcode}</TdContent></Td>
                <Td><TdContent>{item.is_paid ? "שולם" : "ביצירה"}</TdContent></Td>
                <Td><TdContent>{item.coupon || "-"}</TdContent></Td>
                <Td>
                    <TdContent onClick={() => setIsOpen(!isOpen)} style={{cursor:"pointer"}}>
                        {(admin_comments?.length > 0 && !isOpen) ?
                            <span>{admin_comments.length}</span>
                            :
                            <CommentsIcon size={20}/>
                        }
                    </TdContent>
                </Td>
                <Td>
                    <TdContent>
                        <Circle background={menuActive ? "#f1f1f1" : "transparent"}
                                cursor={"pointer"} size={25} onClick={setInnerMenuActive}>
                            <MdMoreVert size={20} color={"#d2d2d2"}/>

                        </Circle>
                        {menuActive &&
                        <TableInnerMenu items={innerMenuItems.filter(item => !(isCreator && item.adminsOnly))}
                                        itemId={item.id}/>}
                    </TdContent>
                </Td>
            </Tr>
            {isOpen &&
            <Tr>
                <Td colSpan={100}>
                    <AdminConversation comments={admin_comments}
                                       handleChange={handleAdminCommentChange}/>
                </Td>
            </Tr>}
        </>
    )
}

const controlStyle = {margin: 0, height: "100%"};
const inputStyle = {border: 0, height: "100%", padding: 0};