import React from 'react';
import PropTypes from "prop-types";
import {FieldColor, FieldText, FilesUploader} from '../../../02_molecules/';
import {gutters} from "../../../00_base/variables";

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

const CreateToolPuzzle = (
    {
        puzzle_image = THUMBNAIL,
        start_title,
        title_size = 20,
        textColor = "#000",
        puzzle_default_images = [],
        success_title = "יפה מאוד",
        continue_ctn = "המשך",
        handleToolDataChange
    }
) => {
    return (
        <div className={"create-tool-puzzle"}>

            <div className="d-flex">
                <FieldText label={'כותרת'}
                           placeholder={'הכנס כותרת'}
                           name={'start_title'}
                           value={start_title}
                           changeHandler={handleToolDataChange}/>
                <FieldText type="number"
                           label={'גודל פונט'}
                           placeholder={20}
                           name="title_size"
                           value={title_size}
                           controlStyle={{marginRight: gutters.sm}}
                           inputStyle={{width:"100px"}}
                           changeHandler={handleToolDataChange}/>
                <FieldColor name={"textColor"}
                            value={textColor}
                            controlStyle={{alignItems:"flex-end"}}
                            inputStyle={{height:"30px"}}
                            changeHandler={handleToolDataChange}/>
            </div>

            <FilesUploader label={'תמונת הפאזל'}
                           triggerValue={'העלה תמונה עבור הפאזל'}
                           multiple={false}
                           maxItems={1}
                           aspect={1}
                           previewWidth={"100%"}
                           triggerStyle={{width: "100%", marginBottom:gutters.md}}
                           name={'puzzle_image'}
                           value={puzzle_image}
                           changeHandler={handleToolDataChange}/>

            <FieldText label={'כותרת הצלחת הכלי'}
                       placeholder={'הכנס הצלחת הכלי'}
                       name={'success_title'}
                       value={success_title}
                       changeHandler={handleToolDataChange}/>

            <FieldText label={'טקסט כפתור המשך'}
                       placeholder={'הכנס טקסט כפתור המשך'}
                       name={'continue_ctn'}
                       value={continue_ctn}
                       changeHandler={handleToolDataChange}/>

            <FilesUploader label={'העלו תמונות לבחירה'}
                           triggerValue={'העלו תמונות לבחירה'}
                           maxItems={1}
                           controlStyle={{margin: "20px 0"}}
                           triggerStyle={{width: "100%"}}
                           name={'puzzle_default_images'}
                           value={puzzle_default_images}
                           changeHandler={handleToolDataChange}/>

        </div>
    )
};

CreateToolPuzzle.propTypes = {
    puzzle_image: PropTypes.string,
    start_title: PropTypes.string,
    puzzle_default_images: PropTypes.array,
    success_title: PropTypes.string,
    continue_ctn: PropTypes.string,
    handleToolDataChange: PropTypes.func.isRequired,
};

export default CreateToolPuzzle;