import React, {useEffect, useState} from 'react';
import {ContactListCreateSection} from "./contact-list-create-section";
import {CssGeneral} from "../../ui/css-general/css-general";
import {StyledDiv} from "../../ui/css-general/css-styled";
import {FieldCheckbox, FilesUploader} from "../../../02_molecules";
import {Table} from "../../../03_organisms/table";
import {MOCK} from "./mock";

const initialSection = {
    name: '',
    phone_number: '',
    sentence: '',
    sentencesOptions: [],
    keyword: '',
    icon: null,
    audio: '',
    video: '',
    color: null
};

export const CreateContactList = (
    {
        items = MOCK,
        handleToolDataChange,
        templatedata,
        itemStyle,
        contactsStyle,
        playIcon,
        hideNumbers
    }) => {
    const [contactItems, setContactItems] = useState(items);

    useEffect(() => {
         handleToolDataChange("items", contactItems);
    }, [handleToolDataChange, contactItems]);

    const addFixedSentence = () => {
        const newId = Math.min(...contactItems.map(item => item.id)) - 1;
        const updated = [{
            ...initialSection,
            fixed: true,
            id: contactItems.length > 0 ? newId : 0
        }].concat(contactItems);
        setContactItems(updated);
    };

    const addSection = () => {
        const newId = Math.max(...contactItems.map(item => item.id)) + 1;
        const updated = contactItems.concat({...initialSection, id: contactItems.length > 0 ? newId : 0});
        setContactItems(updated);
    };

    const removeSection = (itemId) => {
        const updated = contactItems.filter(item => item.id !== itemId);
        setContactItems(updated);
    };

    const handleChange = (name, value, index) => {
        contactItems[index][name] = value;
        setContactItems(contactItems);
        handleToolDataChange("items", contactItems);
    };

    const handleItemStyleChange = (name, value) => {
        handleToolDataChange("itemStyle", {...itemStyle, [name]: value});
    };

    const handleContactsStyleChange = (name, value) => {
        handleToolDataChange("contactsStyle", {...contactsStyle, [name]: value});
    };

    const renderSection = (item, index) => {
        const handleItemChange = (name, value) => handleChange(name, value, index);

        return <ContactListCreateSection {...item}
                                         index={index}
                                         handleChange={handleItemChange}
                                         removeSection={() => removeSection(item.id)}
                                         key={item.id}/>
    };

    const hideNumbersLabel = "הסתר מספרי טלפון";

    return (
        <div className={"create-tool create-tool-contact-list"}>

            <button className={"tool-add-section"} onClick={addFixedSentence}>הוסף משפט קבוע</button>
            <Table>
                <tbody>{contactItems.map(renderSection)}</tbody>
            </Table>
            <button className={"tool-add-section"} onClick={addSection}>הוסף איש קשר</button>


            <FieldCheckbox changeHandler={handleToolDataChange}
                           options={[hideNumbersLabel]}
                           value={hideNumbers ? [hideNumbersLabel] : []}
                           name={"hideNumbers"}/>

            <FilesUploader changeHandler={handleToolDataChange}
                           name={"playIcon"}
                           multiple={false}
                           triggerValue={"כפתור פליי"}
                           label={"כפתור פליי"}
                           previewWidth={100}
                           previewHeight={100}
                           controlStyle={{width: "100px", marginBottom: "30px"}}
                           value={playIcon}/>

            <CssGeneral title={"רקע אנשי קשר"}
                        defaultProps={contactsStyle}
                        changeHandler={handleContactsStyleChange}/>

            <CssGeneral title={"עיצוב שורת איש קשר"}
                        defaultProps={itemStyle}
                        PreviewComponent={() => <StyledDiv className={"contact-list-item-preview"} {...itemStyle}>ישראל
                            ישראלי</StyledDiv>}
                        changeHandler={handleItemStyleChange}/>
        </div>
    )
};

CreateContactList.defaultProps = {};

CreateContactList.propTypes = {};


