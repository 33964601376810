import React, {useState} from 'react';
import {MdClose} from 'react-icons/md';
import {Paragraph} from '../../../01_atoms';
import {FieldText, FieldTextArea, FilesUploader} from "../../../02_molecules";

const initialClues = {
    title: '',
    subtitle: '',
    description: '',
    image: ''
};
const CreateClue = ({
                        clueData = initialClues,
                        index,
                        changeHandler,
                        deleteClueHandler,
                    }) => {
    const [isOpen, setIsOpen] = useState(true);

    const handleFieldChange = (name, value) => {
        changeHandler(name, value, index);
    };

    return (
        <div className="single-clue">
            <div className="single-clue-header" onClick={() => setIsOpen(!isOpen)}>
                <Paragraph margin={0}>{`רמז מספר ${index + 1}`}</Paragraph>
                <MdClose size={18} onClick={() => deleteClueHandler(index)}/>
            </div>

            {isOpen &&
            <div className="single-clue-body">
                <FieldText label="כותרת"
                           changeHandler={handleFieldChange}
                           placeholder="הכנס את כותרת הרמז"
                           name='title'
                           value={clueData.title}/>

                <FieldTextArea label="תיאור"
                               changeHandler={handleFieldChange}
                               name={"description"}
                               value={clueData.description}/>

                <FilesUploader label="תמונה"
                               triggerValue="העלה תמונה עבור הרמז"
                               name="image"
                               previewWidth="100%"
                               triggerStyle={{width:"100%"}}
                               value={clueData.image}/>
            </div>
            }

        </div>
    )
}

export default CreateClue;