import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Element, HBox} from 'react-stylesheet';
import {Link} from "react-router-dom";
import {BackgroundImage, Button, Circle, Heading, Image, Paragraph} from '../../01_atoms';
import {colors, font_family, font_size, gutters} from '../../00_base/variables';
import {Col, Container, Row} from 'react-grid-system';
import {IndicatorWrapper, OptionSquare, Sentence, Wrapper} from './truth-or-lie-styles';
import {ScreenToolIcon} from "../../05_screens/tool/screen-tool-icon";
import {RiddleContext} from "../../05_screens/riddle/screen-riddle";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import "./tool-truth-or-lie-style.scss";
import {useSelector} from "react-redux";

const TruthOrLieContext = React.createContext();


const ToolTruthOrLie = (
    {
        items = [],
        isLastFrame,
        screenBackground,
        borderColor,
        borderWidth,
        questionBackground,
        questionTextColor,
        truthButtonBackground,
        truthButtonTextColor,
        lieButtonBackground,
        lieButtonTextColor,
        continueButtonBackground,
        continueButtonTextColor,
        backButtonBackground,
        backButtonTextColor,
        bottomLogo,
        logoWidth,
        thumbnail,
        colorTruth,
        colorLie,
        truthLabel, lieLabel,
        colorSentence = "#000",
        sentenceSize = 25,
        bgSentence,
        previewMode
    }) => {
    const gameData = useSelector(state => state.gameData);
    const {adventureData} = gameData || {};

    const riddleContext = useContext(RiddleContext);
    const {savePlayerData, playerData} = riddleContext;

    const answersReducer = (state, action) => {
        const index = action.index;
        if (state[index]) {
            state[index].u = action.value;
        }
        if (savePlayerData) {
            savePlayerData(state);
        }
        return state;
    };

    const [activeSentence, setActiveSentence] = useState(0);
    const [showIncorrectAnswers, setShowIncorrectAnswers] = useState(false);

    const defaultAnswers = items.map(item => {
        return {a: item.sentence_type === "אמת" ? 0 : 1, u: -1}
    });

    const [answers, dispatchAnswers] = useReducer(answersReducer, JSON.parse(playerData) || defaultAnswers);
    const finalScreenActive = activeSentence >= items.length;

    return (
        <TruthOrLieContext.Provider value={{
            adventureDataId: adventureData?.id,
            answers,
            dispatchAnswers,
            items,
            activeSentence,
            setActiveSentence,
            showIncorrectAnswers,
            setShowIncorrectAnswers,
            screenBackground,
            borderColor,
            borderWidth,
            questionBackground,
            questionTextColor,
            truthButtonBackground,
            truthButtonTextColor,
            lieButtonBackground,
            lieButtonTextColor,
            continueButtonBackground,
            continueButtonTextColor,
            backButtonBackground,
            backButtonTextColor,
            bottomLogo,
            logoWidth,
            colorTruth,
            colorLie,
            truthLabel, lieLabel,
            bgSentence,
            colorSentence,
            sentenceSize,
            previewMode,
            isLastFrame
        }}>

            <Wrapper className="tool tool-truth-or-lie"
                     screenBackground={screenBackground}
                     borderColor={borderColor}
                     borderWidth={borderWidth}>
                <Container className="tool-truth-or-lie-container">
                    {
                        finalScreenActive ?
                            <TruthOrLieFinalScreen adventureDataId={adventureData?.id}/>
                            :
                            <>
                                {thumbnail && <ScreenToolIcon icon={thumbnail}/>}

                                {items.map((item, index) => {
                                    const isActive = index === activeSentence;

                                    return (
                                        <TruthOrLieSentence key={item.id}
                                                            isActive={isActive}
                                                            colorTruth={colorTruth}
                                                            colorLie={colorLie}
                                                            {...item}
                                                            index={index}
                                                            iconTruth={item.icon_truth}
                                                            iconLie={item.icon_lie}/>
                                    )
                                })}

                                <TruthOrLieIndicators size={items.length > 8 ? 20 : 30}/>

                                <HBox justifyContent={'center'} padding={`0 ${gutters.md}`} marginTop={gutters.lg}>

                                    <Button block
                                            value={'המשך'}
                                            onClick={() => setActiveSentence(activeSentence + 1)}
                                            pointer={'pointer'}
                                            style={{
                                                fontFamily: font_family.medium,
                                                backgroundColor: continueButtonBackground,
                                                color: continueButtonTextColor,
                                                borderRadius: "20px",
                                                fontSize: font_size.lg
                                            }}/>
                                </HBox>
                            </>
                    }
                </Container>
            </Wrapper>
        </TruthOrLieContext.Provider>
    )
};

const TruthOrLieIndicators = ({size, showAnswers}) => {
    const {items, answers, activeSentence, setActiveSentence, finalScreenActive, markIndicators, showIncorrectAnswers} = useContext(TruthOrLieContext);
    return <HBox justifyContent="center" flexWrap={showAnswers ? "wrap" : "nowrap"}
                 marginBottom={showAnswers ? "20px" : 0}>
        {answers?.map((answer, index) => {
            const isActive = activeSentence === index;
            const value = answer.u >= 0 ? answer.u + 1 : '';
            const background = isActive ? (items[index]?.bgColor || colors.black) : colors.white;
            const color = items[index]?.textColor || colors.white;
            const borderColor = isActive ? (items[index]?.bgColor || colors.black) : (finalScreenActive || markIndicators) ? answer.u === answer.a ? items[index]?.bgColor : "red" : (items[index]?.bgColor || colors.black);
            const checkIcon = markIndicators && answer.u === answer.a;
            const borderWidth = isActive ? 2 : checkIcon ? 0 : finalScreenActive ? 3 : 2;
            const isCorrect = answer.u === answer.a;
            const circleStyle = {
                cursor: "pointer",
                borderRadius: "50%",
                border: (showAnswers || showIncorrectAnswers) ? isCorrect ? "4px solid green" : "4px solid red" : `2px solid ${borderColor}`
            };

            return <IndicatorWrapper isActive={isActive} padding={items.length ? gutters.xs : gutters.sm} key={index}>
                <Circle value={value}
                        size={size || 20}
                        background={background}
                        color={color}
                        fontSize={font_size.sm}
                        fontWeight='bold'
                        border={`${borderWidth}px solid ${borderColor}`}
                        onClick={() => setActiveSentence(index)}
                        style={circleStyle}>
                    {answer.u >= 0 &&
                    <BackgroundImage
                        size="contain"
                        width={"100%"}
                        height={"100%"}
                        url={items[index][answer.u === 0 ? "icon_truth" : "icon_lie"]}/>
                    }
                </Circle>
            </IndicatorWrapper>
        })}
    </HBox>
};

const TruthOrLieSentence = ({id, index, iconLie, iconTruth, sentence_type, sentence, bgColor, textColor, isActive}) => {
    const {
        colorSentence = "#000",
        sentenceSize = font_size.lg,
        bgSentence = ""
    } = useContext(TruthOrLieContext);

    const options = [
        {label: 'נכון', icon: iconTruth},
        {label: 'לא נכון', icon: iconLie}
    ];
    return (
        <Sentence isActive={isActive}>
            <Element margin="0 10px" padding="30px 0" style={{backgroundColor: bgSentence, borderRadius: "15px"}}>
                <Paragraph textAlign={'center'}
                           fontWeight={'bold'}
                           color={colorSentence}
                           fontSize={sentenceSize + "px"}
                           style={{margin: 0}}
                           value={sentence}/>
            </Element>

            <Options sentenceId={id}
                     index={index}
                     color="#f2f2f2"
                     bgColor={bgColor}
                     textColor={textColor}
                     sentence_type={sentence_type}
                     options={options}/>


        </Sentence>
    )
};

const Options = ({index, options, bgColor, textColor}) => {
    const {
        dispatchAnswers,
        answers = [],
        colorTruth, colorLie,
        truthLabel, lieLabel,
        truthButtonTextColor,
        lieButtonTextColor
    } = useContext(TruthOrLieContext);
    const userAnswer = answers[index]?.u;
    const frameContext = useContext(FrameContext);

    const {isResolve} = frameContext;

    const [active, setActive] = useState(userAnswer > -1 ? userAnswer : null);

    const clickHandler = answerIndex => {
        if (!isResolve) {
            setActive(answerIndex);
            dispatchAnswers({index: index, value: answerIndex});
        }
    };

    return (
        <Row style={{alignItems: "center"}}>
            {options.map((option, index) => {
                const isActive = active === index;
                const isTruth = option.label === "נכון";
                // const isLie = option.label === "לא נכון";
                const background = option.label === "נכון" ? colorTruth : colorLie;
                const color = option.label === "נכון" ? truthButtonTextColor : lieButtonTextColor;
                return (
                    <Col xs={6} style={{height: "100%", padding: `${gutters.md} ${gutters.md}`}} key={index}>
                        <OptionSquare onClick={() => clickHandler(index)}
                                      className={isActive ? "active" : ""}
                                      isActive={isActive}
                                      color={color}
                                      bgColor={bgColor}
                                      textColor={textColor}
                                      background={background}>
                            {option.icon && (isActive) ?
                                <Image src={option.icon}/> :
                                (isTruth ? (truthLabel || option.label) : (lieLabel || option.label))}
                        </OptionSquare>
                    </Col>
                )
            })}
        </Row>
    )
};

const TruthOrLieFinalScreen = () => {
    const {answers, adventureDataId, items, setActiveSentence, setShowIncorrectAnswers, previewMode, isLastFrame} = useContext(TruthOrLieContext);
    const correctAnswers = answers.filter(item => item.a === item.u);
    const successLength = correctAnswers.length;
    // const levelFinished = useContext(RiddleContext).updateGameLevel;
    const firstWrongIndex = answers.indexOf(answers.find(answer => answer.a !== answer.u));
    const {handleFrameFinish} = useContext(FrameContext);

    const allCorrect = successLength === items.length;

    useEffect(() => {
        if (allCorrect && !previewMode) handleFrameFinish();
    }, [allCorrect, handleFrameFinish, previewMode])
    //
    // useEffect(() => {
    //     if (allCorrect && !previewMode) {
    //         levelFinished();
    //     }
    // }, [allCorrect, levelFinished, previewMode]);

    return (
        <Element paddingBottom={"20px"}
                 style={{
                     backgroundColor: "rgba(255,255,255,.8)",
                     borderRadius: "20px",
                     textAlign: "center",
                     paddingTop: "20px"
                 }}>
            <Heading tag={"h2"} value={allCorrect ? "כל הכבוד" : `עניתם נכון על ${successLength} שאלות`}/>
            <Paragraph value={allCorrect ? "קראו את המשפט שנוצר בעזרת המקרא" : "התשובות הלא נכונות מסומנות באדום"}/>
            <TruthOrLieIndicators size={90} showAnswers={true}/>

            {allCorrect &&
            <div style={{fontSize: "15px", margin: "10px", fontWeight: "bold"}}>
                חזרו למסך הראשי, לחצו על החידה הבאה והזינו את הקוד שגיליתם.
            </div>
            }

            <Button block
                    style={{display: (!isLastFrame && allCorrect) ? "none" : "block", fontFamily: font_family.medium}}
                    fontWeight={"bold"}
                    onClick={() => {
                        setShowIncorrectAnswers(true);
                        setActiveSentence(firstWrongIndex || 0)
                    }}>
                <Link to={`/menu/${adventureDataId || ''}`} style={{color: "#fff"}}>המשך</Link>
                {allCorrect ?
                        <Link to={`/menu/${adventureDataId || ''}`}
                              style={{color: "#fff"}}>
                            המשך
                        </Link>
                    :
                    "נסו שוב"
                }
            </Button>
        </Element>
    )
};

export default ToolTruthOrLie;