import {DICT} from "../../../../languages/dictionary-names";
import {ar, en, he} from '../../../../languages/mock/'


export const dictionaryList = {en, he, ar};
//
// export const languageOptions = {
//     he: 'עברית',
//     en: 'English',
//     ar: "عربيه"
// };

const HOMEPAGE = "עמוד הבית";
const HEADER = "בר עליון";
const FOOTER = "בר תחתון";
const GENERAL = "כללי";
const USER_PAGE = "עמוד משתמש";
const BUSINESSES = "עסקים";
const RIDDLES_POOL = "מאגר חידות";
const GALLERY = "גלריה";
const ABOUT = "עלינו";
const CREATE_ADVENTURE = "יצירת הרפתקה";
const PAYMENT = "עמוד רכישה";

const HEADER_DICTIONARY = [
    DICT.REGISTER,
    DICT.LOGIN,
    DICT.LOGOUT,
    DICT.ADVENTURE_BLOG,
    DICT.GALLERY,
    DICT.TERMS,
    DICT.CONTACT_US
];
const FOOTER_DICTIONARY = [
    DICT.TALK_WITH_US,
    DICT.FAQ,
    DICT.WANT_EVENT_ADVENTURE,
    DICT.ALL_RIGHTS
];

const HOMEPAGE_DICTIONARY = [
    DICT.HOMEPAGE_TITLE,
    DICT.HOMEPAGE_DESCRIPTION_1,
    DICT.HOMEPAGE_DESCRIPTION_2,
    DICT.HOMEPAGE_DESCRIPTION_3,
    DICT.HOMEPAGE_DESCRIPTION_4,
    DICT.HOMEPAGE_INSTRUCTIONS_1,
    DICT.HOMEPAGE_INSTRUCTIONS_2,
    DICT.HOMEPAGE_INSTRUCTIONS_3,
    DICT.HOMEPAGE_ADVENTURES_TITLE,
    DICT.HOMEPAGE_ADVENTURES_DESCRIPTION,
];
const USER_PAGE_DICTIONARY = [
    DICT.USER_PAGE_TITLE,
    DICT.USER_PAGE_DESCRIPTION,
    DICT.ADVENTURE_TYPE,
];

const RIDDLES_POOL_DICTIONARY = [
    DICT.RIDDLE_STOCK_TITLE,
    DICT.RIDDLE_STOCK_DESCRIPTION,
    DICT.RIDDLE_STOCK_FULL,
    DICT.EXPERT,
    DICT.HARD,
    DICT.MEDIUM,
    DICT.EASY,
];
const GALLERY_DICTIONARY = [
    DICT.GALLERY_TITLE,
    DICT.GALLERY_MORE_IMAGES
];
const ABOUT_US_DICTIONARY = [
    DICT.ABOUT_US_TITLE,
    DICT.ABOUT_US_DESCRIPTION_1,
    DICT.ABOUT_US_DESCRIPTION_2,
    DICT.ADVENTURE_BOX,
    DICT.WOE_WE_ARE,
    DICT.CUSTOMERS_TITLE,
    DICT.CUSTOMERS_DESCRIPTION
];

const CREATE_ADVENTURE_DICTIONARY = [
    DICT.TASK_FINISHED,
    DICT.TASK_FULLY_COMPLETED,
    DICT.TASK_NOT_FULLY_COMPLETED,
    DICT.ADVENTURE_SAVED,
    DICT.QUESTION_NUMBER,
    DICT.INSERT_CORRECT_ANSWER,
    DICT.INSERT_INCORRECT_ANSWER,
    DICT.ADVENTURES_IN_PROGRESS,
    DICT.ADVENTURE_PROCESS_FINISH,
    DICT.ADVENTURE_DETAILS_WAITING_IN_EMAIL,
];

const BUSINESSES_DICTIONARY = [
    DICT.FOR_SPECIAL_BUSINESSES,
    DICT.ADVENTURES_FOR_BUSINESSES_TITLE,
    DICT.ADVENTURES_FOR_BUSINESSES_DESCRIPTION,
    DICT.BUSINESSES_DESCRIPTION_MAIN,
    DICT.BUSINESSES_DESCRIPTION_SUB,
    DICT.WHAT_CAN_WE_DO_TOGETHER,
    DICT.WHAT_CAN_WE_DO_TOGETHER_DESCRIPTION,
    DICT.FREE
];

const PAYMENT_DICTIONARY = [
    DICT.PAYMENT_MAIN_TITLE,
    DICT.PAYMENT_DELIVERY_MESSAGE,
    DICT.FIRST_BOX,
    DICT.OTHER_BOXES,
    DICT.FIRST_ADVENTURE,
    DICT.OTHER_ADVENTURES,
    DICT.INSERT_CUSTOM_PRICE,
    DICT.ADVENTURES_AMOUNT,
    DICT.HOW_TO_GET_ADVENTURE,
    DICT.SELF_COLLECTING,
    DICT.COMMENTS,
    DICT.DELIVERY,
    DICT.DELIVERY_ADDRESS,
    DICT.DELIVERY_MESSAGE,
    DICT.DELIVERY_WARNING,
    DICT.CUSTOMER_DETAILS,
    DICT.MAIL_TO_FOLLOW,
    DICT.MAIL_WARNING,
    DICT.COUPON_DISCOUNT,
    DICT.CONTACT_PHONE_NUMBER,
    DICT.CONTACT_MEMBER,
    DICT.CONTACT_WARNING,
    DICT.COUPON_CODE,
    DICT.COUPON_INSTRUCTION,
    DICT.COUPON_NOT_EXIST,
    DICT.I_CONFIRM_THE,
    DICT.GO_TO_PAYMENT,
    DICT.FINISH_ORDER,
    DICT.CITY,
    DICT.CITY_WARNING,
    DICT.STREET,
    DICT.TERMS_WARNING,
    DICT.STREET_WARNING,
    DICT.HOME_NUMBER,
    DICT.APARTMENT,
    DICT.POSTAL_CODE,
]

const GENERAL_DICTIONARY = [
    DICT.CREATE_ADVENTURE,
    DICT.SHEKEL,
    DICT.ADVENTURES,
    DICT.ADVENTURE,
    DICT.SEND,
    DICT.UPLOAD_IMAGE,
    DICT.UPLOAD_IMAGES,
    DICT.UPLOAD_VIDEO,
    DICT.MALE,
    DICT.FEMALE,
    DICT.MORE_DETAILS,
    DICT.NO_EMAIL_LOOK_AT_PROMOTIONS,
    DICT.HAVE_ANY_PROBLEM,
    DICT.SUBSCRIBE_DESCRIPTION,
    DICT.SUBSCRIBE_INPUT_PLACEHOLDER,
    DICT.DIGITAL,
    DICT.PERSONAL_BOXES,
    DICT.RIDDLES,
    DICT.ESCAPE_ROOMS,
    DICT.CLICK_HERE,
    DICT.UNIQUE_SOLUTIONS,
    DICT.CREATED_AT,
    DICT.PASSWORD,
    DICT.EDIT,
    DICT.PLAY,
    DICT.DELETE,
    DICT.NEXT,
    DICT.PREVIOUS,
    DICT.ADMIN,
]




export const DICTIONARY_CREATE = {
    [GENERAL]: GENERAL_DICTIONARY,
    [HEADER]: HEADER_DICTIONARY,
    [FOOTER]: FOOTER_DICTIONARY,
    [HOMEPAGE]: HOMEPAGE_DICTIONARY,
    [USER_PAGE]: USER_PAGE_DICTIONARY,
    [RIDDLES_POOL]: RIDDLES_POOL_DICTIONARY,
    [BUSINESSES]:BUSINESSES_DICTIONARY,
    [GALLERY]: GALLERY_DICTIONARY,
    [ABOUT]: ABOUT_US_DICTIONARY,
    [CREATE_ADVENTURE]: CREATE_ADVENTURE_DICTIONARY,
    [PAYMENT]: PAYMENT_DICTIONARY,

};

