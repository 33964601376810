import React, {useState} from 'react';
import {MdClose} from "react-icons/md";
import {Table, Td, Tr} from "../../../03_organisms/table";
import {FieldSelect, FieldText, FieldVideo, FilesUploader} from "../../../02_molecules";
import "./contact-list-create-style.scss";
import {ToggleIcon} from "../../../03_organisms";

export const ContactListCreateSection = (
    {
        name,
        phone_number,
        keyword,
        icon,
        video,
        sentence,
        sentencesOptions,
        placeholder,
        fixed,
        handleChange,
        removeSection,
        index
    }) => {
    const onChange = (name, value) => handleChange(name, value, index);
    const iconSrc = !!icon ? typeof icon === 'string' ? icon : icon[0] ? icon[0] : null : null;
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Tr className="contact-list-section">
            <Td style={{borderBottom: "1px solid #333"}}>
                <Table>
                    <tbody>
                    <Tr style={{verticalAlign: "center"}}>
                        <Td>{index + 1}</Td>
                        <Td style={{maxWidth: "130px"}}>
                            <FieldText placeholder={`שם המבר.ך`}
                                       value={name}
                                       index={index}
                                       controlStyle={{...controlStyle}}
                                       inputStyle={{...inputStyle, maxWidth: "200px"}}
                                       name={'name'}
                                       changeHandler={handleChange}/>
                        </Td>
                        <Td className={"d-flex align-items-center justify-content-end"}>
                            <ToggleIcon toggle={() => setIsOpen(!isOpen)} isOpen={isOpen} size={25}/>
                            <MdClose onClick={removeSection} className={"mr-2"} style={{cursor: "pointer"}}/>
                        </Td>
                    </Tr>
                    </tbody>
                </Table>

                <Table style={{display: isOpen ? "table" : "none"}}>
                    <tbody>
                    <Tr>
                        <Td>
                            <FilesUploader name={'icon'}
                                           label={"איקון"}
                                           previewStyle={iconStyle}
                                           index={index}
                                           triggerValue={'איקון'}
                                           hideTrigger
                                           multiple={false}
                                           previewWidth={80}
                                           previewHeight={80}
                                           triggerStyle={{width: "80px", height: "80px"}}
                                           labelStyle={{margin: 0}}
                                           setIsFileLoading={data => console.log('data', data)}
                                           changeHandler={handleChange}
                                           value={iconSrc && [iconSrc]}/>
                        </Td>
                    </Tr>
                    </tbody>
                </Table>
                <Table style={{display: isOpen ? "table" : "none"}}>
                    <tbody>
                    <Tr>
                        <Td style={{maxWidth: "120px"}}>
                            <FieldText placeholder={"מילת מפתח"}
                                       label={"מילת מפתח"}
                                       value={keyword}
                                       index={index}
                                       controlStyle={{...controlStyle}}
                                       inputStyle={{...inputStyle}}
                                       name={'keyword'}
                                       changeHandler={handleChange}/>
                        </Td>
                        <Td style={{maxWidth: "135px"}}>
                            <FieldText placeholder={`מספר טלפון`}
                                       label={`מספר טלפון`}
                                       value={phone_number}
                                       index={index}
                                       controlStyle={{...controlStyle, paddingRight: 0}}
                                       inputStyle={{...inputStyle}}
                                       name={'phone_number'}
                                       changeHandler={handleChange}/>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td colSpan={2}>
                            {sentencesOptions && sentencesOptions.length > 0 ?
                                <FieldSelect name={'sentence'}
                                             label={'משפט'}
                                             placeholder={`הכנס משפט`}
                                             options={sentencesOptions.map(option => {
                                                 return {label: option, value: option}
                                             })}
                                             value={{label: sentence, value: sentence}}
                                             controlStyle={{...controlStyle, paddingRight: 0}}
                                             changeHandler={(name, obj) => onChange(name, obj.value)}/>
                                :
                                <FieldText label={fixed ? "משפט קבוע (לא ניתן להתאמה אישית)" : "משפט"}
                                           placeholder={fixed ? "הכנס משפט קבוע (לא ניתן להתאמה אישית)" : "הכנס משפט"}
                                           controlStyle={{...controlStyle, paddingRight: 0}}
                                           value={sentence}
                                           index={index}
                                           inputStyle={inputStyle}
                                           name={'sentence'}
                                           changeHandler={handleChange}/>
                            }
                        </Td>
                    </Tr>
                    </tbody>
                </Table>
                <Table style={{display: isOpen ? "table" : "none"}}>
                    <tbody>
                    <Tr>
                        <Td>
                            <FieldVideo triggerValue={'העלו סרטון'}
                                        label={"סרטון וידאו"}
                                        controlStyle={{...controlStyle}}
                                        name={'video'}
                                        value={video}
                                        videoType={0}
                                        changeHandler={handleChange}
                                        index={index}/>
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>
                            <FilesUploader name={'placeholder'}
                                           label={'תמונת רקע'}
                                           triggerValue={'תמונת רקע'}
                                           multiple={false}
                                           previewWidth={'150px'}
                                           value={placeholder}
                                           changeHandler={handleChange}/>

                        </Td>
                    </Tr>
                    </tbody>
                </Table>
            </Td>
        </Tr>
    )
};

const iconStyle = {
    width: '100px',
    height: '100px',
    marginTop: 0,
    marginBottom: 0,
};
const controlStyle = {width: '100%', marginBottom: 0, padding: `0 10px`};
const inputStyle = {backgroundColor: '#fff'};