import React, {useContext} from "react";
import {Col, Container, Row} from "react-grid-system";
import {Element} from "react-stylesheet";
import {Heading} from "../../../01_atoms/text";
import {colors, font_family} from "../../../00_base/variables";
import {ReviewMocks, Subscribe} from "../../../03_organisms";
import PageCustomers from "../about/block-customers";
import {Card} from "../../../02_molecules";
import {LanguageContext, Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import {ThemeContext} from "../../../../providers/theme-provider";
import "./screen-businesses.scss";

const BUSINESS_IMAGE = "https://storage.googleapis.com/assets.youdoadventures.com/website/businesses_page.png";
const BOX_IMAGE = "https://storage.googleapis.com/assets.youdoadventures.com/website/Drawing_PersonalBox.png";

export const ScreenBusinesses = () => {
    const {rtl} = useContext(LanguageContext);
    const align = rtl ? "right" : "left";
    const {mode} = useContext(ThemeContext);
    const darkMode = mode === "dark";
    return (
        <div id="screen-businesses" className="businesses">
            <Element className='py-0 py-md-7' position='relative' zIndex={1}>
                <Container>
                    <Row className="mb-7">
                        <Col xs={12} sm={7}>
                            <Heading tag={'h1'}
                                     fontFammily={font_family.medium}
                                     className={`font-size-xxl mt-5 mt-md-0 mb-3 mb-md-0 text-${align}`}>
                                <Translation id={DICT.UNIQUE_SOLUTIONS}/>
                                <br/>
                                <Translation id={DICT.FOR_SPECIAL_BUSINESSES}/>
                            </Heading>
                            <p className={`font-size-md mt-5 mb-3 mb-md-0 color-black text-${align}`}>
                                <Translation id={DICT.BUSINESSES_DESCRIPTION_MAIN}/>
                            </p>
                        </Col>
                        <Col xs={12} sm={5}>
                            <img className="businesses-color-image" alt="businesses" src={BUSINESS_IMAGE}/>
                        </Col>
                        <Col xs={12} sm={9} className="businesses-description color-black">
                            <p><Translation id={DICT.BUSINESSES_DESCRIPTION_SUB}/></p>
                        </Col>
                    </Row>

                    <Card title={<Translation id={DICT.WHAT_CAN_WE_DO_TOGETHER}/>}
                          direction="row-reverse"
                          descriptionStyle={{color: colors[darkMode ? "white" : "black"]}}
                          description={<Translation id={DICT.WHAT_CAN_WE_DO_TOGETHER_DESCRIPTION}/>}
                          image={BOX_IMAGE}/>

                    <PageCustomers/>

                    <div className="py-5">
                        <ReviewMocks/>
                    </div>

                    <div className="d-none d-md-block py-7">
                        <Subscribe/>
                    </div>
                </Container>
            </Element>
        </div>
    )
};