import React from "react";
import "./moon.scss";

export const Moon = () => {
    return (
        <div className="moon">
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
        </div>
    )
};