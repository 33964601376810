import React, {useState} from 'react';
import {Button} from '../../../01_atoms';
import {CreateDefinerSection} from "./create-definer-section";
import {createNewId} from "../../../00_base/helpers/create-new-id";
import {Table, tableHeader, Tr} from "../../../03_organisms/table";
import "./create-tool-definer.scss";

const initialSection = {
    id: 0,
    title: '',
    description: '',
    image: null,
    color: null
};

const CreateToolDefiner = (
    {
        items = [],
        handleToolDataChange
    }) => {

    const [magdirItems, setMagdirItems] = useState(items);

    const addSection = () => {
        const newId = createNewId(magdirItems);
        const updated = magdirItems.concat({...initialSection, id: magdirItems.length > 0 ? newId : 0})
        setMagdirItems(updated)
        handleToolDataChange("items", updated);
    };

    const removeSection = (itemId) => {
        const updated = magdirItems.filter(item => item.id !== itemId);
        setMagdirItems(updated);
        handleToolDataChange("items", updated);
    };

    const handleChange = (name, value, index) => {
        magdirItems[index][name] = value;
        setMagdirItems(magdirItems);
        handleToolDataChange("items", magdirItems);
    };

    const renderSection = (item, index) => {
        const handleItemChange = (name, value) => handleChange(name, value, index);
        return <CreateDefinerSection {...item}
                                     index={index}
                                     handleChange={handleItemChange}
                                     removeSection={() => removeSection(item.id)}
                                     key={item.id}/>
    };
    const tableHeaderItems =  ["#", "טקסט", "תמונה", "צבע"];

    return (
        <div className={"create-tool magdir"}>
            <Table>
                <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                <tbody>{magdirItems.map(renderSection)}</tbody>
            </Table>

            <Button className={"tool-add-section"}
                    value={"הוסף פריט למגדיר"}
                    onClick={addSection}
                    outline/>
        </div>
    )
};

CreateToolDefiner.defaultProps = {

};

CreateToolDefiner.propTypes = {

};


export default CreateToolDefiner;