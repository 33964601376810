import React, {useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setAdventureRiddles} from "../../../../store/actions/riddle-actions";
import {AuthContext} from "../../../../providers/auth-provider";
import {LanguageContext} from "../../../../languages";
import {SingleRiddleGame} from "./single-riddle-game";
import {Button, LoaderPageContent} from "../../../01_atoms";
import {PageContentHeader} from "../../../03_organisms";
import Paragraph from "../../../01_atoms/text/a-paragraph";

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/riddles_thumbnail.png";

export const SingleRiddle = ({match, history}) => {
    const riddleId = match.params.id;
    const {isAdmin} = useContext(AuthContext);
    const {userLanguage} = useContext(LanguageContext);
    const [isActive, setIsActive] = useState(false);
    const [riddle, setRiddle] = useState(null);
    const [frames, setFrames] = useState(null);
    const [isLoading, setIsLoading] = useState(true);


    const dispatch = useDispatch();
    useEffect(() => {
        if (riddleId) {
            dispatch(setAdventureRiddles([riddleId], (riddles, frames) => {
                setRiddle(riddles[0]);
                setFrames(frames);
                setIsLoading(false);
            }));
        }
    }, [dispatch, riddleId]);


    if (isLoading) {
        return (
            <LoaderPageContent/>
        )
    }
    return (
        <div className={"container"}>

                <PageContentHeader title={riddle.title}
                                   description={<Paragraph className="color-black"
                                                           dangerouslySetInnerHTML={riddle.description}/>}
                                   image={THUMBNAIL} />

            {!isActive && <Button onClick={() => setIsActive(true)}
                                  className="px-5 mx-auto d-block"
                                  style={{fontWeight: "bold"}}
                                  value="אתגרו אותי!"/>}

            {isActive && <SingleRiddleGame riddle={riddle}
                                           frames={frames}/>}

            {isAdmin && <div className="text-center mt-5">
                <a href={`/${userLanguage}/admin/riddle/${riddleId}`}
                   target="_blank"
                   className="color-black">
                    CREATE
                </a>
            </div>}
        </div>
    )
};