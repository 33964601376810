import React, {useEffect, useState, useContext} from 'react';
import {Element, HBox} from 'react-stylesheet';
import {Col, Container} from "react-grid-system";
import {gutters} from '../../../00_base/variables';
import {BackgroundImage, Heading, Image, LoaderContent, LoaderInstagram, Paragraph, Span} from '../../../01_atoms';
import {dateFormat} from "../../../../helpers/dates/date-format";
import "./single-blog.scss";
import {useDispatch, useSelector} from "react-redux";
import {setSingleBlog} from "../../../../store/actions/blogs-actions";
import {LanguageContext} from "../../../../languages";
import {Breadcrumbs} from "../../../03_organisms/breadcrumbs/o-breadcrumbs";

const SingleBlog = (props) => {
    const id = props.id >= 0 ? props.id : props.match.params.id;
    const {userLanguage} = useContext(LanguageContext);
    const [isLoading, setIsLoading] = useState(true);
    const [blog, setBlog] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSingleBlog(id, response => {
            setBlog(response);
            setIsLoading(false);
        }));
    }, [dispatch]);


    // const printPdf = function (url) {
    //     let iframe = printItem;
    //     if (!this.printItem) {
    //         iframe = this.printItem = document.createElement('iframe');
    //         document.body.appendChild(iframe);
    //
    //         iframe.style.display = 'none';
    //         iframe.onload = function () {
    //             setTimeout(function () {
    //                 iframe.focus();
    //                 iframe.contentWindow.print();
    //             }, 1);
    //         };
    //     }
    //
    //     iframe.src = url;
    // }


    const {title, description, main_image, thumbnail_image, created_at} = blog;
    const image = main_image || thumbnail_image;
    if (isLoading) {
        return (
            <Container className={"pt-5"}>
                <LoaderContent/>
                <LoaderInstagram/>
            </Container>
        )
    }

    const breadcrumbs = [
        {title: "בלוג", href: `/${userLanguage}/blogs`},
        {title: blog.title},
    ];
    return (
        // <Page>
        <Element width={'100%'} className={"single-blog"}>
            <BackgroundImage url={image} height={'40vh'}/>
            <Container>
                <Breadcrumbs items={breadcrumbs}/>
                 <HBox className={'justify-content-center py-3 py-sm-5'}>
                    <Col sm={10} md={8}>
                        <Element marginBottom={gutters.md} marginTop={gutters.md}>
                            <Heading tag={'h1'}
                                     className={'scroll-element color-black font-size-xl text-right responsive-text'}>{title}</Heading>
                            {<Span color={'#A4465E'} isLoading={isLoading}
                                   fontWeight={'bold'}>{dateFormat(created_at)}</Span>}
                        </Element>
                        <Paragraph className={"color-black"} dangerouslySetInnerHTML={description}/>
                        {/*<Paragraph className={"color-black"}>{about}</Paragraph>*/}
                        {/*{print_file && print_file.file &&*/}
                        {/*<div className={'mt-5'}>*/}
                        {/*    <Heading tag={'h4'} onClick={this.printPdf}>{print_file.title}</Heading>*/}
                        {/*    <Image src={print_file.file} width={'100%'} maxWidth={'700px'}/>*/}
                        {/*</div>}*/}
                    </Col>
                </HBox>
            </Container>
        </Element>

        // </Page>
    )
}

export default SingleBlog;