import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import {FieldCheckbox, FieldColor, FieldText, FieldTextArea} from "../../../../../02_molecules";
import {font_size} from "../../../../../00_base/variables";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/all";

export const AdditionalDataTextBefore = (
    {handleToolDataChange, textBeforeSize = 18, textBeforeColor = "#000", textBefore}
) => {
    const [isActive, setIsActive] = useState(textBefore);
    const [isOpen, setIsOpen] = useState(textBefore);

    useEffect(() => setIsOpen(isActive), [isActive]);

    const toggle = (name, value) => {
        setIsActive(value);
        if (!value) {
            handleToolDataChange("textBefore", "");
        }
    };
    const IconDrop = isOpen ? MdArrowDropUp : MdArrowDropDown;
    const wrapperStyle = {borderBottom: `${isOpen ? 1 : 0}px dashed #000`, marginBottom: "10px"};

    return (
        <div style={wrapperStyle}>
            <div className={"d-flex justify-content-between align-items-center"}>
            <FieldCheckbox options={["טקסט מעל הכלי"]}
                           name="textActive"
                           controlStyle={{marginBottom: 0}}
                           value={isActive ? ["טקסט מעל הכלי"] : []}
                           changeHandler={toggle}/>
                {isActive && <IconDrop onClick={() => setIsOpen(!isOpen)} cursor={"pointer"} size={20}/>}
            </div>

            {isActive && isOpen &&
            <Row className={"pt-3 px-3 pb-0"}>
                <Col xs={5}>
                    <FieldText changeHandler={handleToolDataChange}
                               label="גודל טקסט"
                               name="textBeforeSize"
                               value={textBeforeSize}/>
                </Col>
                <Col>
                    <FieldColor changeHandler={handleToolDataChange}
                                name="textBeforeColor"
                                label="צבע טקסט"
                                labelStyle={{width: "100%", fontSize:font_size.xs, fontWeight:"normal"}}
                                controlStyle={{flexWrap: "wrap"}}
                                value={textBeforeColor}/>
                </Col>
                <Col xs={12}>
                    <FieldTextArea changeHandler={handleToolDataChange}
                                   wysiwyg={true}
                                   toolbarHidden={false}
                                   name="textBefore"
                                   rows={2}
                                   label="הכנס טקסט שיופיע מעל הכלי"
                                   value={textBefore}/>
                </Col>
            </Row>}
        </div>
    )
}