import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from "prop-types";
import "./google-maps.scss"

export const GoogleMaps = (
    {
        searchZoomLevel,
        startZoomLevel,
        isOnSatellite,
        defaultSearchString,
    }) => {
    const [urlSource, setUrlSource] = useState()

    const setUrlByParameters = useCallback( ({textSearch, currentIsOnSatellite = isOnSatellite, currentzoomLevel = searchZoomLevel}) => {
        setUrlSource(`https://maps.google.com/maps?${urlParseTextSearch(textSearch)}${urlParseIsSatellite(currentIsOnSatellite)}${urlParseZoomLevel(currentzoomLevel)}ie=UTF8&iwloc=&output=embed`)
    }, [isOnSatellite, searchZoomLevel]);


    useEffect(() => {
        setUrlByParameters({textSearch: defaultSearchString, currentIsOnSatellite:isOnSatellite, currentzoomLevel:startZoomLevel})
    }, [defaultSearchString, isOnSatellite, setUrlByParameters, startZoomLevel]);

    // const urlParseCoordinates = () => {
    //     let coordinates = "";
    //     if (latitude && longitude) {
    //         coordinates = `q=${latitude}%2C${longitude}&`
    //     }
    //     return coordinates;
    // }

    const urlParseTextSearch = (text) => {
        // let newText = _.replace(text, `/ /g`, "%");
        let newText = encodeURIComponent(text);
        return `q=${newText}&`
    }

    const urlParseIsSatellite = (currentIsOnSatellite) => {
        let onSatellite = "";
        if (currentIsOnSatellite) {
            onSatellite = `t=k&`;
        }
        return onSatellite
    }

    const urlParseZoomLevel = (currentzoomLevel) => {
        return `z=${currentzoomLevel}&`
    }

 

    return (
        <div className="google-maps-container">
            <input className={"google-maps-search"} onChange={e => setUrlByParameters({textSearch: e.target.value})}/>
            <iframe id="google-iframe"
                    width="100%"
                    height="100%"
                    src={urlSource}
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    title={"google-iframe"}
                    marginWidth="0"/>
        </div>
    )
};

GoogleMaps.defaultProps = {
    searchZoomLevel: 16,
    startZoomLevel: 6,
    isOnSatellite: true,
    defaultSearchString: "israel"
};

GoogleMaps.propTypes = {
    searchZoomLevel: PropTypes.number,
    startZoomLevel: PropTypes.number,
    isOnSatellite: PropTypes.bool,
    defaultSearchString : PropTypes.string,
}