import React from "react";
import {useSelector} from "react-redux";
import {selectAllCategories} from "../../../../../../store/selectors";
import {Image} from "../../../../../01_atoms";
import "./choose-tool.scss";

const THUMBNAIL = "https://storage.googleapis.com/assets.youdoadventures.com/website/default_thumbnail.jpg";

export const ChooseTool = ({onClick}) => {
    const allCategories = useSelector(selectAllCategories);
    const renderCategory = (category) => {
        return <div key={category.id}
                    style={{ cursor:"pointer"}}
                    className="choose-tool-item"
                    onClick={() => onClick(category)}>
            <Image src={category.icon || THUMBNAIL} width={50} height={50}/>
            <p className="fs-3 my-0 text-center">{category.title}</p>
        </div>
    };
    return (
        <div className="choose-tool d-flex flex-wrap">
            {allCategories.map(renderCategory)}
        </div>
    )
};