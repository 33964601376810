import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import CreateTool from '../../../creators/tools/create-tool';
import classNames from "classnames";
import {useDispatch} from "react-redux";
import {updateUserFrameData} from "../../../../../store/actions/frames-actions";
import {RiddleHeader} from "./riddle-header";
import "../adventure-create-riddle.scss";
import "./adventure-create-riddle.scss";
import {CreateAdventureContext} from "../screen-adventure-create";

export const RiddleContext = React.createContext({isRiddleValid: false});

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

export const AdventureCreateFrame = (
    {
        index,
        frame,
        isActive,
        setAdminFrameActive
    }) => {
    const sectionRef = useRef(null);
    const executeScroll = () => scrollToRef(sectionRef);

    const [isRiddleValid, setIsRiddleValid] = useState(frame.categorydata?.isValid >= 100);
    const [warningActive] = useState(false);
    const [flagActive, setFlagActive] = useState(false);
    const {setWarningsActive} = useContext(CreateAdventureContext);

    const hideFlag = useCallback(() => {
        if (flagActive) {
            setTimeout(() => setFlagActive(false), 3000);
        }
    }, [flagActive]);

    useEffect(hideFlag, [hideFlag]);

    useEffect(() => {
        if (warningActive) {
            setTimeout(() => setWarningsActive(false), 10000)
        }
    }, [setWarningsActive, warningActive]);

    useEffect(() => {
        if (isActive) {
            executeScroll()
        }
    }, [isActive])

    const dispatch = useDispatch();

    const {instructions, tips, categorydata} = frame;

    const handleToolDataChange = (isToolValid, name, value, index, optionIndex) => {
        setWarningsActive(false);

        const checkIfValid = (data) => {
            const validation = isToolValid(data)
            setIsRiddleValid(validation >= 100);
            return validation;
        };

        dispatch(updateUserFrameData(checkIfValid, frame.id, name, value, index, optionIndex));
    };

    const riddleClassNames = {active: isActive, valid: isRiddleValid, inValid: !isRiddleValid, warning: warningActive};

    const riddleProvider = {
        index,
        isRiddleValid: isRiddleValid,
        active: isActive,
        frame: frame,
        instructions: instructions,
        tips: tips,
        warningActive: warningActive,
        setWarningActive: setWarningsActive
    };

    return (
        <RiddleContext.Provider value={riddleProvider}>
            <div className="mb-3" ref={sectionRef}>
                <div className={classNames("adventure-riddle", riddleClassNames)}>

                    <RiddleHeader frame={frame}/>

                    <CreateTool id={frame.id}
                                riddleId={frame.id}
                                title={frame.title}
                                isActive={isActive}
                                checkIfValid={setIsRiddleValid}
                                categorydata={categorydata}
                                warningActive={warningActive}
                                setWarningActive={setWarningsActive}
                                setAdminFrameActive={setAdminFrameActive}
                                handleToolDataChange={handleToolDataChange}
                    />
                </div>

                <div className="d-flex justify-content-center">
                    <span className={classNames("adventure-riddle-rectangle", { valid: isRiddleValid})}/>
                </div>
            </div>

        </RiddleContext.Provider>
    )
};

