import React from 'react';
import {CssGeneral} from "../../css-general/css-general";
import "./button-create-style.scss";
import {StyledButton} from "../../css-general/css-styled";

export const ButtonCreate = ({
                                 title,
                                 changeHandler,
                                 defaultProps = {},
                                 className = "css-button-create",
                             }) => {

    const PreviewComponent = () => {
        return (
            <StyledButton {...defaultProps} className={className}>כפתור</StyledButton>
        )
    };

    return (
        <div className={"button-create"}>
            <CssGeneral title={title}
                        PreviewComponent={PreviewComponent}
                        changeHandler={changeHandler}
                        defaultProps={defaultProps}/>
        </div>
    )
};

