import React from "react";
import {Subscribe} from "../../../03_organisms/subscribe/subscribe";

export const FinalScreen = ({riddle}) => {
    return <div>
        <h2 className="my-0">כל הכבוד!</h2>
        <p className="font-size-lg" style={{lineHeight:"32px"}}>
            הצלחתם לפתור את {riddle.title}, אין ספק שאתם ניחנים<br/>
            בחוש יוצא דופן לפתרון בעיות ותעלומות. רוצים עוד חידות?<br/>
            הרשמו למטה לניוזלטר שלנו כדי לקבל חידות שבועיות.
        </p>
        <Subscribe/>
    </div>
};
