import React, {useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import classNames from "classnames";
import _ from "lodash"
import "./single-rotary-styles.scss";
import {MdArrowUpward} from "react-icons/all";

export const SingleRotary = (
    {
        degressAddOnClick,
        color,
        backgroundImage,
        onChange,
        backgroundColor,
        isResolved,
        initialRotateValue,
        answer,
        rotarySize,
        size,
        rotaryBorderWidth,
        rotaryBorderColor,
        rotaryBorderRadius,
        rotaryWidth,
        rotaryDotStyle,
        dotColor,
        showNumbers = true,
        numbersColor,
        numbersSize,
        numbersScale,
        hideZero,
        numbersInside,
        startLocation,
        rotaryColor,
        dotCustomImage
    }) => {
    const [rotateDegrees, setRotateDegrees] = useState(initialRotateValue ? 360 / initialRotateValue : 0);
    const removeFullRoundes = degrees => {
        let numToReturn = degrees;
        while (numToReturn >= 360) {
            numToReturn -= 360;
        }
        return numToReturn
    };

    const onRotaryClick = () => {

        if (!isResolved) {
            const newDegrees = _.toInteger(rotateDegrees) + _.toInteger(360 / degressAddOnClick);
            const roundDegrees = removeFullRoundes(newDegrees);
            const numberOfClicks = roundDegrees / (360 / degressAddOnClick);
            const isSingleCorrect = parseInt(answer) === numberOfClicks;
            setRotateDegrees(newDegrees);
            onChange(numberOfClicks);
            if (isSingleCorrect) {

            }
        }
    };

    const renderRotaryDot = () => {
        console.log("rotaryDotStyle", rotaryDotStyle);
        switch (rotaryDotStyle) {
            case "arrow":
                return (<RotaryArrow className={"rotary-arrow"}
                                     size={rotarySize}
                                     color={dotColor}>
                    <MdArrowUpward color={dotColor !== "transparent" ? dotColor : "#000"} size={rotarySize}/>
                </RotaryArrow>);
            case "outArrow":
                return (<RotaryArrow className={"rotary-arrow out"}
                                     size={rotarySize}
                                     color={dotColor}/>);
            case "line":
                return (<RotaryLine className={"rotary-line"}
                                    size={rotarySize}
                                    color={dotColor}/>);
            case "custom":
                return (
                    <RotaryCustom className={"rotary-line"} size={rotarySize}>
                        <img src={dotCustomImage} width="100%"/>
                    </RotaryCustom>
                );
            default:
                return (<RotaryDot className={"rotary-dot"}
                                   size={rotarySize}
                                   color={dotColor}/>)
        }
    };
    const renderNumbers = () => {
        const numbersCount = degressAddOnClick;

        return (
            <RotaryNumbers size={numbersScale} className={classNames("rotary-numbers", {inside: numbersInside})}>
                {_.times(numbersCount, (number) => {
                    const numberStyle = {
                        transform: `rotate(${(number / numbersCount) * 360 + (startLocation === "top" ? 0 : 180)}deg)`,
                    }
                    return <RotaryNumber size={numbersSize} color={numbersColor} className={"rotary-number"}
                                         style={numberStyle}>
                        {(number === 0 && hideZero) ? "" : number}
                    </RotaryNumber>
                })}
            </RotaryNumbers>
        )
    }

    const renderRotary = () => {
        return (
            <>
                <Rotary className={"rotary"}
                        color={color}
                        backgroundColor={backgroundColor}
                        startLocation={startLocation}
                        rotaryWidth={rotaryWidth}
                        rotateDegrees={rotateDegrees}>
                    {renderRotaryDot()}
                </Rotary>
                {showNumbers && renderNumbers()}
            </>
        )
    };

    return (
        <RotaryWrapper className={classNames("rotary-wrapper", {inside: numbersInside})}
                       color={rotaryColor}
                       size={size}
                       borderWidth={rotaryBorderWidth}
                       borderColor={rotaryBorderColor}
                       borderRadius={rotaryBorderRadius}
                       backgroundImage={backgroundImage}
                       onClick={onRotaryClick}>
            {renderRotary()}
        </RotaryWrapper>
    )
};

const RotaryWrapper = styled.div`
    &.rotary-wrapper {
        ${props => `
            background-color: ${props.color};
            height: ${props.size}px;
            width: ${props.size}px;
            min-height: ${props.size}px;
            min-width: ${props.size}px;
            border-style: solid;
            border-width: ${props.borderWidth}px;
            border-color: ${props.borderColor};
            border-radius: ${props.borderRadius}%;
            background-image: url(${props.backgroundImage});
            background-size: cover;
            background-position: center center;
        `}
    }
`;

const Rotary = styled.div`
&.rotary {
    ${props => `
        border-color: ${props.color};
        // ${props.rotaryWidth !== 0 && `box-shadow: 0 0 10px ${props.color}, inset 0 0 10px ${props.color};`}}
        background-color: ${props.backgroundColor};
        transform: rotate(${props.startLocation === "top" ? props.rotateDegrees : props.rotateDegrees + 180}deg);
        border-width: ${props.rotaryWidth}px;
    `}
    }
`;
const RotaryNumbers = styled.div`
    &.rotary-numbers {
        ${props => `
            width: ${props.size || 150}%;
            height: ${props.size || 150}%;
        `}
    }
`;
const RotaryNumber = styled.div`
&.rotary-number {
    ${props => `
        color: ${props.color || "#fff"};
        font-size: ${props.size}px;
    `}
    }
`;
const RotaryArrow = styled.div`
    &.rotary-number {
        ${props => `
            height: ${props.size}px;
            background-color: ${props.color};
            &:after {
                border-color: ${props.color};
              }
        `}
    }
`;
const RotaryDot = styled.div`
    ${props => `
        background-color: ${props.color || "#000"};
        top:  0;
        transform: translateY(-50%);
        height: ${props.size}px;
        width: ${props.size}px;
        right: calc(50% - calc(${props.size}px / 2));
        border-radius: 50%;
    `}
`;
const RotaryLine = styled.div`
    ${props => `
        background-color: ${props.color || "#000"};
        top:  ${props.size / 2}px;
        transform: translateY(-50%);
        height: ${props.size}px;
        width: ${props.size / 4}px;
        right: calc(50% - calc(${props.size / 3}px / 2));
    `}
`;
const RotaryCustom = styled.div`
    ${props => `
        top:  ${props.size / 2}px;
        transform: translateY(-50%);
        height: ${props.size}px;
        width: ${props.size / 4}px;
        right: calc(50% - calc(${props.size / 3}px / 2));
    `}
     img {
        width:100%;
     }
`;

SingleRotary.propTypes = {
    degressAddOnClick: PropTypes.number,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    onChange: PropTypes.func,
    size: PropTypes.number,
    rotarySize: PropTypes.number,
    borderWidth: PropTypes.number,
    borderColor: PropTypes.string,
    borderRadius: PropTypes.string,
    dotColor: PropTypes.string,
    rotaryWidth: PropTypes.number,
    rotaryDotStyle: PropTypes.string,
    rotaryColor: PropTypes.string,
}

export default SingleRotary;