import React, {useContext, useEffect, useState} from 'react';
import {RiddleContext} from "../../pages/adventure-create/adventure-riddle/adventure-create-frame";
import {AuthContext} from "../../../../providers/auth-provider";
import {HBox} from 'react-stylesheet';
import {gutters} from '../../../00_base/variables';
import {MdAddCircleOutline, MdEdit} from 'react-icons/md';
import Anchor from '../../../01_atoms/anchor';
import {getToolComponent} from "./get-tool-component";
import {getSingleFrame} from "../../../../store/actions/frames-actions";
import {RiddleInstructions} from "../../pages/adventure-create/adventure-riddle/riddle-instructions";
import "./create-tool.scss";

const CreateTool = props => {
    const {id, handleToolDataChange, setAdminFrameActive, templateId, riddleId} = props;
    const {instructions} = useContext(RiddleContext);
    const {isAdmin} = useContext(AuthContext);
    const [tool, setTool] = useState(null);
    const [toolData, setToolData] = useState({});

    useEffect(() => {
        if (!tool) {
            getSingleFrame(id).then(
                tool => {
                    const Tool = getToolComponent(tool.categories[0]);
                    if (Tool) {
                        setToolData(tool.categorydata || {})
                        setTool(() =>
                            <Tool handleToolDataChange={handleToolDataChange}
                                // checkIfValid={checkIfValid}
                                  riddleId={riddleId}
                                  invalidFields={props.invalidFields}/>
                        )
                    }
                }
            )
        }
    }, [tool, handleToolDataChange, id, props.invalidFields, riddleId]);

    useEffect(() => {
        const interval = setInterval(() => {
            getSingleFrame(id).then(
                tool => setToolData(tool.categorydata || {})
            )
        }, 5000);
        return () => clearInterval(interval);
    }, [id]);


    if (!tool) {
        return (
            <HBox
                justifyContent='center'
                width='100%'
                minHeight='400px'
                paddingTop={gutters.xxxl}>
                {/*<TailLoader/>*/}
            </HBox>
        )
    }
    return (
        <div className="create-tool">

            <RiddleInstructions {...instructions}/>

            <HBox>
                {!!templateId &&
                <Anchor href={`/template/${templateId}`} target={'_blank'}>
                    <MdEdit size={20}/>
                </Anchor>
                }
            </HBox>

            {!!tool && React.cloneElement(tool, {warningActive: props.warningActive, toolData})}

            {isAdmin && <MdAddCircleOutline size={20}
                                            onClick={setAdminFrameActive}
                                            style={openAdminScreenTrigger}/>}
        </div>
    )
}

const openAdminScreenTrigger = {
    position: "absolute",
    bottom: ".5rem",
    left: ".5rem",
    cursor: "pointer"
}

export default CreateTool;