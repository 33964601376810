import React from "react";
import {Modal} from "../../../03_organisms";
import {Image} from "../../../01_atoms";
import {tools} from "../tools";

export const ToolModal = ({nextFrameButton, toolId, toolData, modalIcon, modalIconSize = 200}) => {
    const ToolComponent = tools.find(tool => tool.id === toolId)?.component;

    return (
        <Modal triggerValue={<Image src={modalIcon}/>}
               triggerStyle={{backgroundColor:"transparent", width: `${modalIconSize}px`}}
               className={"tool-modal"}>
            {ToolComponent && <ToolComponent {...toolData}/>}
            {nextFrameButton}
        </Modal>
    )
}