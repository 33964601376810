import React, {useCallback, useContext, useEffect, useState} from 'react';
import {RiddleContext} from "../riddle/screen-riddle";
import {useDispatch, useSelector} from "react-redux";
import {tools} from "./tools";
import classNames from "classnames";
import ToolClues from './clues/tool-clues';
import {ToolTopBar} from "./tool-top-bar/tool-top-bar";
import {ScreenToolIcon} from "./screen-tool-icon";
import {ToolPassword} from "./password/tool-password";
import {Button, Image, Paragraph} from "../../01_atoms";
import * as journeyActions from "../../../store/actions/journey/journey-actions";
import "./screen-tool.scss";
import {StyledDiv} from "../../ui/css-general/css-styled";
import {ToolActionButtons} from "./action-buttons/tool-action-buttons";
import {ToolModal} from "./modals/tool-modals";
import {colors, font_size} from "../../00_base/variables";
import {FrameFinalData} from "../../03_organisms";

export const FrameContext = React.createContext({
    handleFrameFinish: null,
    handleFrameContinue: null,
    isFrameFinish: null
});


export const ScreenTool = (
    {
        toolId,
        toolData,
        handleFrameContinue,
        handleFramePreviewContinue,
        isLastFrame,
        riddles,
        riddleIndex,
        riddleData,
        previewMode,
        isFullScreen,
        previewFrameFinish,
        hideTopBar
    }) => {
    const riddleContext = useContext(RiddleContext);

    const [clueActive, setClueActive] = useState(false);
    const [actionsButtonsActive, setActionsButtonsActive] = useState(false);
    const [finalButtonChosen, setFinalButtonChosen] = useState(0);

    const journey = useSelector(state => state.journey);

    const gameData = useSelector(state => state.gameData);
    const {adventure = {}, adventureType = {}, adventureData = {}} = gameData || {};

    const {id, categorydata = {}, clues, thumbnail} = toolData;
    const {modals = []} = toolData;
    const frameData = categorydata || {};
    const {textBefore, textBeforeColor = colors.black, textBeforeSize = 18, textAfter, textAfterColor = colors.black, textAfterSize = 18, topBarTitle, topBarTextColor = "#000", topBarTitleSize = font_size.sm, topBarBgColor} = frameData;
    const {imageBefore, imageAfter, imageBeforeSize, imageAfterSize,} = frameData;
    const {frameAudio, audioPlayOn} = frameData;
    const {toolPassword, isPasswordModal, modalIcon, coderData, innerPassword, innerPasswordLabel, continueButtonValue = (adventure.language === "en" || adventureType?.id === 332) ? "continue" : "המשך"} = frameData;
    const {actionButtons, actionButtonBackgroundColor, actionButtonColor} = frameData;
    const {extraTool, screenBackgroundImage, screenBackgroundImageDesktop} = frameData;
    const {toolWrapperStyle = {}} = frameData;
    const isFrameFinish = journey && journey[`frame_${id}_finish`];

    const dispatch = useDispatch();

    const playFrameAudio = useCallback(() => {
        const audioPlayer = document.getElementById(`frame-audio-${id}`);
        if (audioPlayer) {
            document.getElementById(`frame-audio-${id}`).play()
        }
    }, [id]);

    const handleFrameFinish = useCallback(() => {
        if (frameAudio) {
            playFrameAudio();
        }
        if (handleFramePreviewContinue) {
            handleFramePreviewContinue()
        }
        dispatch(journeyActions.frameFinish(id));
    }, [dispatch, frameAudio, id, playFrameAudio]);

    useEffect(() => {
        if (finalButtonChosen) {
            handleFrameFinish();
        }
    }, [handleFrameFinish, finalButtonChosen]);

    useEffect(() => {
        if (frameAudio && audioPlayOn === "background") {
            playFrameAudio();
        }
    }, [frameAudio, audioPlayOn, id, playFrameAudio]);

    const closeClue = callback => {
        setClueActive(false);
        setTimeout(callback, 1000);
    };

    const nextRiddle = !previewMode && riddles[riddleIndex + 1];
    const goTo = (nextRiddle && toolData["after-riddle-target"] === "מעבר לחידה הבאה") ? `/riddle/${nextRiddle.id}` : `/menu/${adventureData?.id}`;

    const frameContext = {handleFrameFinish, handleFrameContinue, isFrameFinish, ...frameData};

    const TOOL_COMPONENT = tools.find(tool => tool.id === toolId)?.component;
    const EXTRA_TOOL_COMPONENT = tools.find(tool => tool.id === extraTool?.toolId)?.component;
    const NEXT_FRAME_BUTTON = (
        isFrameFinish && !isFullScreen &&
        <Button className="riddle-next-button"
                onClick={handleFrameContinue}
                value={continueButtonValue}/>
    );

    const hasExtraTool = extraTool && extraTool.toolData && EXTRA_TOOL_COMPONENT;

    const modalLocation = modals && modals[0]?.modalIconLocation;
    const modalLocationTop = !modalLocation || modalLocation === 1;
    const modalLocationBottom = modalLocation === 2;
    const modalLocationTopBar = modalLocation === 3;

    const toolStyle = {
        ...frameData,
        backgroundImage: screenBackgroundImage,
        backgroundImageDesktop: screenBackgroundImageDesktop,
    };
    const FRAMES_MODALS = modals?.length > 0 && modals.map(modal => <ToolModal {...modal}
                                                                               nextFrameButton={NEXT_FRAME_BUTTON}
                                                                               key={modal.id}/>);

    const TOP_BAR = !isFullScreen && !hideTopBar &&
        <ToolTopBar riddleData={riddleData}
                    tool={toolData}
                    topBarBgColor={topBarBgColor}
                    topBarTitle={topBarTitle}
                    topBarTextColor={topBarTextColor}
                    topBarTitleSize={topBarTitleSize}
                    frameModal={modalLocationTopBar && FRAMES_MODALS}
                    setClueActive={setClueActive}
                    hasClues={clues?.length > 0}
                    previewMode={previewMode}/>;

    const CLUES = !!clues && clueActive &&
        <ToolClues clueIndex={0}
                   riddleId={riddleData.id}
                   frameId={toolData.id}
                   cluesData={clues}
                   close={closeClue}
                   isActive={clueActive}/>;

    const FRAME_AUDIO = frameAudio &&
        <audio id={`frame-audio-${id}`}>
            <source src={frameAudio} type="audio/ogg"/>
            <source src={frameAudio} type="audio/mpeg"/>
        </audio>;

    const TEXT_BEFORE = textBefore &&
        <Paragraph dangerouslySetInnerHTML={textBefore.replace("{name}", adventure.adventure_name || "____")}
                   color={textBeforeColor}
                   fontSize={`${textBeforeSize}px`}
                   className="tool-text-before"/>;

    const TEXT_AFTER = textAfter &&
        <Paragraph dangerouslySetInnerHTML={textAfter.replace("{name}", adventure.adventure_name || "____")}
                   color={textAfterColor}
                   fontSize={`${textAfterSize}px`}
                   className="tool-text-after"/>

    const IMAGE_BEFORE = imageBefore && <Image src={imageBefore} width={imageBeforeSize || "100%"}/>;

    const IMAGE_AFTER = imageAfter && <Image src={imageAfter}
                                             width={imageAfterSize || "100%"}
                                             style={{marginTop: "30px"}}/>;


    const TOOL = <TOOL_COMPONENT {...frameData}
                                 toolData={toolData}
                                 handleFrameFinish={(!hasExtraTool && (!modals || modals.length === 0)) ? handleFrameFinish : () => {
                                     // console.log("FINISH WITH EXTRA FRAME")
                                 }}
                                 handleRiddleFinish={riddleContext.handleRiddleFinish}
                                 handleFrameContinue={handleFrameContinue}
                                 isLastFrame={isLastFrame}
                                 riddleId={riddleData?.id}
                                 nextFrameButton={isFrameFinish && !isFullScreen && !isLastFrame && NEXT_FRAME_BUTTON}
                                 showActionsButton={() => setActionsButtonsActive(true)}
                                 previewMode={previewMode}
                                 goTo={goTo}/>;

    const EXTRA_TOOL = hasExtraTool &&
        <EXTRA_TOOL_COMPONENT {...extraTool.toolData}
                              toolData={extraTool.toolData}
                              nextFrameButton={NEXT_FRAME_BUTTON}
                              handleFrameFinish={handleFrameFinish}
                              handleRiddleFinish={riddleContext.handleRiddleFinish}
                              handleFrameContinue={handleFrameContinue}/>;

    const ACTIONS_BUTTONS = actionButtons?.length > 0 && !isFrameFinish &&
        <ToolActionButtons actionButtons={actionButtons}
                           setActionsButtonsActive={setActionsButtonsActive}
                           isFrameFinish={isFrameFinish}
                           actionsButtonsActive={actionsButtonsActive}
                           setFinalButtonChosen={setFinalButtonChosen}
                           actionButtonBackgroundColor={actionButtonBackgroundColor}
                           actionButtonColor={actionButtonColor}
                           actionButtonSize={actionButtons}/>;

    const FRAME_PASSWORD = toolPassword &&
        <ToolPassword label={innerPasswordLabel}
                      isPasswordModal={isPasswordModal}
                      coderData={coderData}
                      modalIcon={modalIcon}
                      password={innerPassword}
                      successHandler={handleFrameFinish}/>;


    const SUCCESS_FRAME_DATA = (previewFrameFinish || isFrameFinish) && <FrameFinalData/>;

    const fullScreen = isFullScreen;

    const toolClassName = classNames(
        `screen-tool screen-tool-${toolId} frame-${id} adventure-${adventure.id} adventure-type-${adventureType.id}`, {
            fullScreen,
            [`tool-coder`]: toolId === 63
        }, {hasTextBefore: textBefore});

    const hasIcon = !!toolData.thumbnail;
    const toolWrapperClassName = classNames("tool-wrapper", {fullScreen, hasIcon});

    return (
        <FrameContext.Provider value={frameContext}>
            <StyledDiv id='screen-tool' className={toolClassName} {...toolStyle}>
                {TOP_BAR}
                {CLUES}
                {FRAME_AUDIO}
                {TOOL_COMPONENT &&
                <StyledDiv className={toolWrapperClassName} {...toolWrapperStyle} width={"100%"} height={"100%"}>
                    <div>
                        {!isFullScreen &&
                        <>
                            {toolData.thumbnail && <ScreenToolIcon icon={thumbnail}/>}
                            {modalLocationTop && FRAMES_MODALS}
                            {TEXT_BEFORE}
                            {IMAGE_BEFORE}
                        </>
                        }

                        {TOOL}

                        {!isFullScreen &&
                        <>
                            {TEXT_AFTER}
                            {IMAGE_AFTER}
                            {EXTRA_TOOL}
                            {modalLocationBottom && FRAMES_MODALS}
                            {ACTIONS_BUTTONS}
                            {FRAME_PASSWORD}
                            {SUCCESS_FRAME_DATA}
                            {NEXT_FRAME_BUTTON}
                        </>
                        }
                    </div>
                </StyledDiv>
                }
            </StyledDiv>
        </FrameContext.Provider>
    )
};
