import React, {useContext} from "react";
import styled from "styled-components";
import classNames from "classnames";
import {TriviaContext} from "./tool-trivia";
import {MdClose, MdDone} from "react-icons/md";

export const TriviaIndicators = ({items, bgColor, textColor, onClick, showFeedback}) => {
    const {userAnswers, activeSection, finalScreenActive} = useContext(TriviaContext);
    return (
        <div className="trivia-indicators">
            {items.map((item, index) => {
                const active = index === activeSection;
                const {icon} = item;
                const {u, a} = userAnswers[index] || {};
                const isCorrect = u === a;
                const className = classNames("trivia-indicator", {active, correct: isCorrect && finalScreenActive});
                const answerValue = u >= 0 ? u + 1 : "";
                const showIcon = (showFeedback && isCorrect);
                const Icon = isCorrect ? MdDone : MdClose;
                const borderColor = (!finalScreenActive || isCorrect) ? "#fff" : "red";
                return (
                    <IndicatorStyle className={className}
                                    onClick={() => onClick(index)}
                                    active={active}
                                    backgroundImage={answerValue && icon}
                                    background={item.bgColor || item.color}
                                    color={item.textColor}
                                    borderColor={borderColor}
                                    key={index}>
                        {!icon && (
                            borderColor === "red" ?
                                <Icon color="#fff"/> :
                                (showIcon && !finalScreenActive) ?
                                    <Icon color="#fff"/> :
                                    answerValue
                        )}
                    </IndicatorStyle>
                )
            })}
        </div>
    )
};

const IndicatorStyle = styled.div`
    &.trivia-indicator {
        background-image: ${props => `url(${props.backgroundImage || ""})`};
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: ${props => props.background};
        border-color: ${props => props.borderColor};
        color:${props => props.color};
    }    
`;
