import React from "react";
import {Container} from "react-grid-system";
import {MdEdit, MdMic, MdPauseCircleOutline, MdPersonOutline, MdPhone} from 'react-icons/md';
import {GoPlus, GoSync} from 'react-icons/go';
import "./contact-list-phone-call.scss";
import styled from "styled-components";


const ContactListPhoneCall = (
    {phone_number, video = "", name, closePhoneCall, placeholder}
) => {
    const isVideo = video.includes('mp4' || "MP4") || video.includes("mov" || 'MOV') || video.includes('webm');
    const isAudio = video.includes('mp3' || "MP3") || video.includes('ogg' || "OGG") || video.includes('mpeg');
    const isImage = video.includes('jpg') || video.includes('png') || video.includes('jpeg') || video.includes('gif');

    return (
        <PhoneCall className="phone-call" backgroundImage={placeholder}>
            <Container fluid className="phone-call-container" style={{padding: 0}}>
                <div className="phone-call-panel">
                    {!isVideo && !isImage &&
                    <div className="phone-call-header">
                        <div className="phone-call-top-icon">
                            {/* <img src={MdPersonOutline}
                                     width={'100px'}/> */}
                            <MdPersonOutline size={50} color={"black"}/>
                        </div>

                        <div className="phone-call-main-details">
                            <h2 className="phone-call-name">{name}</h2>
                            <h3 className="phone-call-number">{phone_number}</h3>
                        </div>
                    </div>
                    }

                    <div className="phone-call-body">
                        {isImage &&
                        <div className="phone-call-imageType">
                            <img width={'100%'} alt="" src={video}/>
                        </div>
                        }
                        {isVideo &&
                        <div className={"phone-call-videoType"}>
                            <video width="100%" id={'audio'} controls autoPlay={true}>
                                <source src={video} type="video/mp4"/>
                                <source src={video} type={"video/avi"} />
                                <source src={video} type={"video/webm"} />
                                <source src={video} type={"video/ogg"} />
                                <source src={video} type={"video/wmv"} />
                            </video>
                        </div>
                        }
                        {isAudio &&
                        <div className={"phone-call-audioType"}>
                            <audio src={video}
                                   id='audio'
                                   autoPlay={true}
                                   className="phone-call-audio"/>
                            {!isImage && <PhoneIcons/>}
                        </div>
                        }


                        <div className="phone-call-close" style={{
                            position: isVideo || isImage ? 'absolute' : 'static',
                        }}>
                            <button onClick={closePhoneCall}>
                                <MdPhone size={27} color={"#fff"}/>
                            </button>

                        </div>
                    </div>
                </div>
            </Container>
        </PhoneCall>
    )
};

class PhoneIcons extends React.Component {
    render() {
        return (
            <div className="phone-call-icons">
                <div className="phone-call-icon" style={phoneIcon}>
                    <MdMic color={"white"} size={35}/>
                </div>
                <div className="phone-call-icon disable">
                    <MdPauseCircleOutline color={"white"} size={35}/>
                </div>
                <div className="phone-call-icon disable">
                    <MdEdit/>
                </div>
                <div className="phone-call-icon">
                    <button>
                        <MdPersonOutline color={"white"} size={35}/>
                    </button>
                </div>
                <div className="phone-call-icon disable">
                    <GoPlus color={"white"} size={35}/>
                </div>
                <div className="phone-call-icon">
                    <button>
                        <GoSync color="white" size={35}/>
                    </button>
                </div>
            </div>
        )
    }
}


const PhoneCall = styled.div`
    ${props => `
        background-image: ${props.backgroundImage};
    `}
`;
const phoneIcon = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33.33%',
    padding: "15px",
    color: "white",
    fontSize: '3rem'
};

export default ContactListPhoneCall;
