import React from 'react';
import PropTypes from "prop-types";
import {Col, Row} from "react-grid-system";
import {Button, Heading, Image, Paragraph} from "../../01_atoms";
import Anchor from "../../01_atoms/anchor/anchor";
import "./card.scss";

const COOL_BOX = "https://storage.googleapis.com/assets.youdoadventures.com/website/cool_box.svg";

const Card = (
    {
        title,
        description,
        dangerouslyDescription,
        descriptionStyle,
        image,
        cta,
        ctaType,
        direction,
        titleStyle,
        ctaUrl,
        ctaAction,
        ctaStyle,
        colSizes
    }) => {
    const TITLE = (
        <Heading tag="h3"
                 className="card-title"
                 style={titleStyle}>
            {title}
        </Heading>
    );

    const DESCRIPTION = (
        dangerouslyDescription ?
            <Paragraph className="card-description"
                       dangerouslySetInnerHTML={dangerouslyDescription}
                       style={descriptionStyle}/>
            :
            <Paragraph className="card-description"
                       value={description}
                       style={descriptionStyle}/>
    );

    const IMAGE = (
        image && <div className="card-image">
            <Image src={image}/>
        </div>
    );

    const CTA = cta && (
        ctaType === "link" ?
            <Anchor href={ctaUrl} style={ctaStyle}>{cta}</Anchor> :
            <Button onClick={ctaAction}>{cta}</Button>
    );

    return (
        <Row className={`card d-flex flex-wrap flex-${direction}`}>
            <Col md={colSizes[0]}>
                {TITLE}
                {DESCRIPTION}
                {CTA}
            </Col>
            <Col md={colSizes[1] || colSizes[0]}>
                {IMAGE}
            </Col>
        </Row>
    )
};


Card.defaultProps = {
    title: "כותרת",
    description: "תיאור",
    descriptionStyle: {},
    image: COOL_BOX,
    imageWidth: "100px",
    imageHeight: "100%",
    ctaType: "link",
    ctaUrl: `javascript:void(0)`,
    ctaStyle: {},
    direction: "row",
    titleStyle: {},
    colSizes: [9, 3]
};

Card.propTypes = {
    image: PropTypes.string,
    imageWidth: PropTypes.string,
    imageHeight: PropTypes.string,
    ctaType: PropTypes.oneOf(["link", "button"]),
    cta: PropTypes.string,
    direction: PropTypes.oneOf(["row", "row-reverse"]),
    titleStyle: PropTypes.object,
    ctaUrl: PropTypes.string,
    ctaAction: PropTypes.func,
    ctaStyle: PropTypes.object,
    colSizes: PropTypes.array
};

export default Card;
