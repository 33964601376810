import React, {useState} from "react";
import PropTypes from 'prop-types';
import styled from 'styled-components'
import "./peek-lock-styles.scss";

export const ToolPeekLock = (
    {
        moveSpeed,
        lockSize,
        lockedImage,
        backgroundColor = "#000"
    }) => {
    const [currentMouseX, setCurrentMouseX] = useState(0);
    const [currentMouseY, setCurrentMouseY] = useState(0);

    const [dragStartMouseX, setDragStartMouseX] = useState(0);
    const [dragStartMouseY, setDragStartMouseY] = useState(0);

    const [draggedX, setDraggedX] = useState(0);
    const [draggedY, setDraggedY] = useState(0);

    const [isDragging, setIsDragging] = useState(false);

    const [firstDraggedX, setFirstDraggedX] = useState(0)
    const [firstDraggedY, setFirstDraggedY] = useState(0)

    const getDiastanceBetweenTwoNumbers = (num1, num2) => {
        if (num1 >= num2) {
            return (num1 - num2);
        }
        return (num2 - num1);
    }
    const handleMouseMove = ({clientX, clientY}) => {
        setCurrentMouseX(clientX);
        setCurrentMouseY(clientY);

        if (currentMouseX === 0 && currentMouseY === 0) {
            setDragStartMouseX(clientX)
            setDragStartMouseY(clientY)
        }


        if (isDragging) {
            let newX = firstDraggedX + (currentMouseX - dragStartMouseX)
            let newY = firstDraggedY + (currentMouseY - dragStartMouseY)

            const lockImageWidth = document.getElementById("peek-lock-image").clientWidth;
            const lockImageHeight = document.getElementById("peek-lock-image").clientHeight;

            if (newX < lockImageWidth / 2 && newX > lockImageWidth / -2 && getDiastanceBetweenTwoNumbers(newX, draggedX) > 5) {
                setDraggedX(newX)
            }
            if (newY < lockImageHeight / 2 && newY > lockImageHeight / -2 && getDiastanceBetweenTwoNumbers(newY, draggedY) > 5) {
                setDraggedY(newY)
            }
        }
    }

    const onMouseDown = () => {
        setIsDragging(true)
        setFirstDraggedX(draggedX)
        setFirstDraggedY(draggedY)

        setDragStartMouseX(currentMouseX)
        setDragStartMouseY(currentMouseY)
    }

    const onMouseUp = () => {
        setIsDragging(false);
    }

    return (
        <div className={"peek-lock-container"}
             onMouseLeave={onMouseUp}
             onTouchMove={(e) => {
                 handleMouseMove(e.touches[0])
             }}
             onMouseMoveCapture={handleMouseMove}>

            <LockImage className={"lock-image"}
                       id={"peek-lock-image"}
                       lockSize={lockSize}
                       onMouseDown={onMouseDown}
                       onMouseUp={onMouseUp}
                       onTouchStart={onMouseDown}
                       onTouchEnd={onMouseUp}


            />

            <InsideImage className={"inside-image"}
                         src={lockedImage}
                         moveSpeed={moveSpeed}
                         x={draggedX}
                         y={draggedY}/>
        </div>
    )
}

const LockImage = styled.div`
    ${({lockSize}) => `
       transform: scale(${lockSize});
    `} 
`;

const InsideImage = styled.img`
    ${({x, y, moveSpeed}) => `
       transform: translate(${x}px, ${y}px);
       transition-duration: ${moveSpeed}s;
    `} 
`;

ToolPeekLock.defaultProps = {
    lockSize: 1.5,
    moveSpeed: 0.1,
    lockedImage: "https://thumbs.dreamstime.com/b/stupid-portrait-british-breed-cat-white-color-blue-eyes-stare-camera-opened-mouth-isolated-black-background-119331293.jpg",
};

ToolPeekLock.propTypes = {
    borderWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lockSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    moveSpeed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    borderColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lockedImage: PropTypes.string,
};