import React, {useEffect, useState} from "react";
import classNames from "classnames";
import styled from "styled-components";
import {Image} from "../../01_atoms";
import "./tool-choose-item.scss";

export const ToolChooseItem = (
    {
        items = [],
        items_view = "blocks",
        handleFrameFinish,
        choseItem,
        itemStyle = {}
    }) => {
    const [activeItem, setActiveItem] = useState(-1);

    useEffect(() => {
        if (!choseItem && activeItem >= 0) {
            handleFrameFinish()
        } else {
            if (choseItem === activeItem) {
                handleFrameFinish()
            }
        }
    }, [activeItem, choseItem, handleFrameFinish]);

    const renderItem = (item, index) => {
        return (
            <ChooseItemSection {...itemStyle}
                               backgroundColor={item.bgColor || itemStyle.backgroundColor}
                               color={item.textColor}
                               className={classNames("tool-choose-item-section", {active: activeItem === index})}
                               onClick={() => setActiveItem(index)} key={index}>
                {item.image &&
                <div className="tool-choose-item-image">
                    <Image src={item.image} width={60} height={60}/>
                </div>}
                <div className="tool-choose-item-text">
                    <p>{item.text}</p>
                </div>
            </ChooseItemSection>
        )
    }
    return (
        <div className={`tool-choose-item ${items_view}`}>
            {items.map(renderItem)}
        </div>
    )
};

const ChooseItemSection = styled.div`
    .tool-choose-item-text {
        ${props => {
    return {
        ...props,
        width: props.width + "px",
        height: props.height + "px",
        fontSize: props.fontSize + "px",
        borderRadius: props.borderRadius + "px"
    }
}
}`