import React from "react";
import {FieldText, FieldVideo, FilesUploader} from "../../../../02_molecules";
import {AdvancedList} from "./advanced-list";
import "./gallery-advanced-data.scss";
import FieldTextArea from "../../../../02_molecules/form-fields/textarea/m-field-textarea";

export const CreateGalleryAdvancedData = ({handleChange, advancedData, advancedDataType}) => {
    const changeHandler = (name, value) => {
        handleChange('advancedData', {[name]: value});
    };
    const FieldByType = () => {
        switch (advancedDataType) {
            case "text":
                return <FieldText type="text"
                                  name="text"
                                  placeholder="יופיע בלחיצה על התמונה"
                                  value={advancedData.text}
                                  inputStyle={{maxWidth: "100%"}}
                                  controlStyle={{marginBottom: 0}}
                                  dir={"vertical"}
                                  changeHandler={changeHandler}/>;
            case "textarea":
                return <FieldTextArea name={"textarea"}
                                      value={advancedData.textarea}
                                      wysiwyg={true}
                                      toolbarHidden={false}
                                      inputStyle={{maxWidth: "100%"}}
                                      controlStyle={{marginBottom: 0}}
                                      changeHandler={changeHandler}/>;
            case "list":
                return <AdvancedList name="list"
                                    list={advancedData.list}
                                    changeHandler={changeHandler}/>;
            case "image":
                return <FilesUploader name="image"
                                      value={advancedData.image}
                                      multiple={false}
                                      previewWidth={55}
                                      previewHeight={55}
                                      changeHandler={changeHandler}/>;
            case "video":
                return <FieldVideo name="video"
                                   value={advancedData.video}
                                   triggerValue="העלה סרטון"
                                   controlStyle={{marginTop: "10px"}}
                                   changeHandler={changeHandler}/>;
            default:
                return <div/>
        }
    };

    return (
        <div className="create-gallery-advanced-data">
            <FieldByType/>
        </div>
    )
};