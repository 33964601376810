import React from 'react';
import classNames from "classnames";
import {MdPlayArrow} from "react-icons/all";
import {StyledDiv} from "../../ui/css-general/css-styled";
import './slider.scss';
import {Image} from "../../01_atoms";

export const SliderView = (
    {
        className,
        height,
        indicators = [],
        goToIndex,
        indicatorsLocation,
        items,
        activeItem,
        nextItem,
        prevItem,
        noArrows,
        arrowColor = "#000",
        arrowStyle,
        speed,
        indicatorStyle,
        debug
    }
) => {
    const sliderClassName = classNames("slider", {className: className, 'slider-debug': debug});
    const wrapperClassName = classNames('slider_wrapper', {"indicators-up": indicatorsLocation === "up"});
    return (
        <div className={sliderClassName}
             style={{maxWidth: "100%", height: height}}>
            <div className={wrapperClassName}>

                {/* {!noArrows && items.length > 1 && <div className={'slider_arrow slider_arrow-left'} onClick={prev}>
                    <Circle size={circleSize} background={circlesBackground ? circlesBackground : '#333333'}>
                        <MdChevronLeft size={`${circleArrowSize}px`} color={circlesColor ? circlesColor : '#ffffff'}/>
                    </Circle>
                </div>} */}

                <div className={'slider_content'}>
                    {!noArrows &&
                    <StyledDiv className='slider_indicators-prev'
                               style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                               onClick={() => goToIndex(nextItem)}
                               {...arrowStyle}>
                        <MdPlayArrow color={arrowColor}/>
                    </StyledDiv>
                    }
                    <div style={{padding:"0 10px"}}>
                    {items.map((item, i) => {
                        const itemClass =
                            activeItem === i ? ' slider_item-active' :
                                prevItem === i ? ' slider_item-prev' :
                                    nextItem === i && ' slider_item-next';

                        return (
                            <div key={i} className={`slider_item${itemClass ? itemClass : ''}`}
                                 style={{transitionDuration: `${speed}s`}}>
                                {item}
                            </div>
                        )
                    })}
                    </div>
                    {!noArrows &&
                    <StyledDiv className='slider_indicators-next d-flex justify-content-center align-items-center'
                               style={{display: "flex", justifyContent: "center", alignItems: "center"}}
                               onClick={() => goToIndex(prevItem)}
                               {...arrowStyle}>
                        <MdPlayArrow color={arrowColor}/>
                    </StyledDiv>
                    }
                </div>

                <div className={'slider_indicators'}>

                    <div className="slider_indicators-container">
                        {items.length > 1 && items.map((item, i) => {
                            const value = indicators[i] ? <Image src={indicators[i]}/> : <span>{i + 1}</span>;
                            return (
                                <StyledDiv key={i}
                                           {...indicatorStyle}
                                           style={{transitionDuration: `.${speed}s`}}
                                           className={classNames("slider_indicators_item", {active: activeItem === i})}
                                           onClick={() => goToIndex(i)}>{value}</StyledDiv>
                            )
                        })}
                    </div>

                </div>

                {/* {!noArrows && items.length > 1 && <div className={'slider_arrow slider_arrow-right'} onClick={next}>
                    <Circle size={circleSize} background={circlesBackground ? circlesBackground : '#333333'}>
                        <MdChevronRight size={circleArrowSize} color={circlesColor ? circlesColor : '#ffffff'}/>
                    </Circle>
                </div>} */}

            </div>
        </div>
    )
};