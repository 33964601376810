import React from 'react';
import {Element} from 'react-stylesheet';
import {Container} from 'react-grid-system';
import {LoaderPageContent, Heading} from "../../../01_atoms";
import Page from "../../../04_layout/app-page/l-page";
import "./screen-terms.scss";

class ScreenTerms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            pageData: {
                title: {rendered: ''},
                content: {rendered: ''}
            }
        };

    }

    componentDidMount() {
        fetch('https://admin.youdoadventures.com/wp-json/wp/v2/pages/497', {
            method: 'GET',
        }).then(
            response => response.json()
        ).then(
            success =>
                this.setState({
                    pageData: success,
                    isLoading: false
                })
        ).catch(
            error => console.log(error)
        );
    }

    render() {
        const {pageData, isLoading} = this.state;

        return (
            <Page>
                {isLoading ?
                    <Element width="100%" padding={"50px 0"}>
                        <Heading tag={'h1'}
                                 isLoading={this.props.isLoading}
                                 value={pageData.title.rendered}/>
                        <LoaderPageContent />
                    </Element>
                    :
                    <Container>
                        <Element padding={`8rem 0`}>
                            <Heading tag={'h1'}
                                     isLoading={this.props.isLoading}
                                     value={pageData.title.rendered}/>
                        </Element>
                        <p dangerouslySetInnerHTML={{__html:pageData.content.rendered}}
                                   className="color-black"/>
                    </Container>
                }
            </Page>
        )
    }
}

export default ScreenTerms;