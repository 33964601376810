import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector,} from 'react-redux';
import {
    Table,
    TableContainer,
    tableHeader,
    TableSearchBar,
    TableTitle,
    TableWrapper,
    Tr
} from "../../../03_organisms/table";
// import {CategoryItem} from "./category-item";
import SVG_API from "../../../../assets/images/svg-icons/svg-api.svg";
import SVG_EDIT from "../../../../assets/images/svg-icons/svg-edit.svg";
import {selectShowInStoreRiddles} from "../../../../store/selectors";
import {AuthContext} from "../../../../providers/auth-provider";
import {createRiddle, setMainRiddles, setShowInStoreRiddles} from "../../../../store/actions/riddle-actions";
import {LanguageContext} from "../../../../languages";
import {RiddleItem} from "./riddle-item";
import {NavigateItems} from "../../../03_organisms/navigation-groups/navigate-items";
import ScreenAdventure from "../../../website/pages/adventure/screen-adventure";
// import {CategoryModal} from "./category-modal";
// import "./categories-gallery.scss";

const adventureTypeId = 345;

export const ScreenRiddlesGallery = ({history}) => {
    const {userLanguage} = useContext(LanguageContext);
    const allRiddles = useSelector(selectShowInStoreRiddles);
    const [innerMenuActive, setInnerMenuActive] = useState(null);
    const [itemsLength, setItemsLength] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setShowInStoreRiddles(0));
    }, [dispatch]);

    const createNewRiddle = () => {
        dispatch(createRiddle(
            {title:'חידה חדשה', show_in_store: true},
            "",
            (riddleData) => history.push(`/${userLanguage}/admin/riddle/${riddleData.id}`))
        );
    };

    const open_api = id => window.open(`https://api.youdoadventures.com/riddles/${id}`, '_blank');
    const open_single_riddle = id => history.push(`/${userLanguage}/admin/riddle/${id}`);

    const innerMenuItems = [
        {Icon: () => <img src={SVG_EDIT} alt="remove"/>, label: "עריכה", onClick: open_single_riddle},
        {Icon: () => <img src={SVG_API} alt="api"/>, label: "API", onClick: open_api},
    ];

    const tableHeaderItems = [
        "",
        "כותרת",
        "איקון",
        "פעיל",
        "יוצר",
        "תאריך יצירה"
    ];

    const tableRow = (item, index) => {
        return <RiddleItem item={item}
                             key={item.id}
                             index={index}
                             setInnerMenuActive={() => setInnerMenuActive(innerMenuActive === index ? null : index)}
                             closeInnerMenu={() => setInnerMenuActive(null)}
                             innerMenuItems={innerMenuItems}
                             menuActive={innerMenuActive === index}/>
    };

    return (
        <TableWrapper>
            <TableTitle>
                <h1>חידות</h1>
            </TableTitle>
            <TableContainer fluid>
                <TableSearchBar
                    addLabel={"+ צור חידה"}
                    handleAddClick={createNewRiddle}/>

                <Table cellSpacing="0" cellPadding="0">
                    <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                    <tbody>{allRiddles.map(tableRow)}</tbody>
                </Table>

                {itemsLength > 15 && <NavigateItems length={itemsLength}
                                                    currentPageIndex={pageIndex}
                                                    handleChange={(index) => setPageIndex(index)}/>}
            </TableContainer>
        </TableWrapper>
    )
};