import React, {useContext} from "react";
import {TriviaContext} from "./tool-trivia";
import styled from "styled-components";
import {MdNavigateBefore, MdNavigateNext} from "react-icons/all";

export const TriviaButtons = (
    {
        setActiveSection,
        nextButtonBackground,
        nextButtonColor,
        prevButtonBackground,
        prevButtonColor,
        showContinueButton
    }) => {
    const {activeSection, allCorrect, finalScreenActive} = useContext(TriviaContext);

    const goBack = () => setActiveSection(finalScreenActive ? 0 : activeSection - 1);
    const goNext = () => setActiveSection(activeSection + 1);

    return (
        <div className="trivia-buttons">
            {activeSection > 0 &&
            <div className="trivia-button prev">
                <Button onClick={goBack}
                        background={prevButtonBackground}
                        color={prevButtonColor}>
                    <MdNavigateNext color="#fff" size={25}/>
                </Button>
            </div>
            }
            {!(finalScreenActive && !allCorrect) && showContinueButton &&
            <div className="trivia-button next">
                <Button onClick={goNext}
                        background={nextButtonBackground}
                        color={nextButtonColor}>
                    <MdNavigateBefore color="#fff" size={25}/>
                </Button>
            </div>
            }
        </div>
    )
}

const Button = styled.button`
    ${props => `
        background-color: ${props.background};
        color: ${props.color};
    `}
`;