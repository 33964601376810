import React, {useState} from "react";
import {MdAccessAlarm, MdAccountBalance, MdFormatAlignJustify, MdMusicNote, MdPlayCircleOutline} from "react-icons/all";

const ICONS = [MdMusicNote, MdAccessAlarm, MdFormatAlignJustify, MdAccountBalance]

export const AudioListBottom = ({item}) => {
    const [activeTab, setActiveTab] = useState(3);
    return (
        <div className="audio-list-bottom">
            <div className="audio-list-player">
                <h2 className={!item ? "hidden" : ""}>{item?.title || "title"}</h2>
                <h5 className={!item ? "hidden" : ""}>{item?.name || "artist"}</h5>
                <div className="audio-list-play">
                    <MdPlayCircleOutline size={55} color={item ? "#ed1c24" : "#575654"}/>
                </div>
            </div>
            <div className="audio-list-tabs">
                {ICONS.map((Icon, index) => {
                    return (
                        <div className="audio-list-tabs-icon" onClick={() => setActiveTab(index)} key={index}>
                            <Icon size={40}
                                  color={activeTab === index ? "#ed1c24" : "#575654"}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}