class Riddle {
    constructor(
        id,
        title,
        riddle_name,
        subtitle,
        description,
        description_short,
        templatedata,
        thumbnail,
        videourl,
        user_email,
        is_published,
        keywords,
        accessories,
        payment_type,
        print_files,
        riddlestemplates,
        tags,
        created_at,
        instructions,
        is_main,
        show_in_store,
        editable
    ) {
        this.id = id;
        this.title = title;
        this.riddle_name = riddle_name;
        this.subtitle = subtitle;
        this.description = description;
        this.description_short = description_short;
        this.templatedata = templatedata;
        this.thumbnail = thumbnail;
        this.videourl = videourl;
        this.user_email = user_email;
        this.is_published = is_published;
        this.keywords = keywords;
        this.accessories = accessories;
        this.payment_type = payment_type;
        this.print_files = print_files;
        this.riddlestemplates = riddlestemplates;
        this.tags = tags;
        this.instructions = instructions;
        this.is_main = is_main;
        this.show_in_store = show_in_store;
        this.created_at = created_at;
        this.editable = editable;
    }
};

export default Riddle;

