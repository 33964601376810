import React, {useEffect, useState} from 'react';
import {FieldCheckbox, FieldSelect, FilesUploader} from "../../../02_molecules";
import {Table} from "../../../03_organisms/table";
import {AudioListCreateSection} from "./audio-list-create-section";

const initialSection = {
    id: 0,
    name: '',
    phone_number: '',
    sentence: '',
    sentencesOptions: [],
    keyword: '',
    icon: null,
    audio: '',
    video: '',
    color: null
};

export const CreateToolAudioList = (
    {
        items = [],
        handleToolDataChange,
        templatedata,
        list_view = "blocks",

        audio_list_background_image,
        iTunes
    }) => {
    const [audioItems, setAudioItems] = useState(items);
    const [listView, setListView] = useState(list_view);

    // const fixeOldVersion = () => {
    //     const confirm = window.confirm("Are you sure?");
    //     if (confirm) {
    //         if (templatedata?.items) {
    //             handleToolDataChange("items", templatedata.items);
    //         }
    //     }
    // };

    useEffect(() => {
        handleToolDataChange("list_view", listView);
    }, [handleToolDataChange, listView]);

    const listViewOption = (option) => {
        return {id: option, value: option, label: option}
    };

    const addSection = () => {
        const newId = Math.max(...audioItems.map(item => item.id)) + 1;
        const updated = audioItems.concat({...initialSection, id: audioItems.length > 0 ? newId : 0});
        setAudioItems(audioItems.concat({...initialSection, id: audioItems.length > 0 ? newId : 0}))
        handleToolDataChange("items", updated)
    };

    const removeSection = (itemId) => {
        const updated = audioItems.filter(item => item.id !== itemId);
        setAudioItems(updated);
        handleToolDataChange("items", updated)
    };

    const handleChange = (name, value, index) => {
        audioItems[index][name] = value;
        setAudioItems(audioItems);
        handleToolDataChange("items", audioItems)
    };

    const renderSection = (item, index) => {
        const handleItemChange = (name, value) => handleChange(name, value, index);

        return <AudioListCreateSection {...item}
                                       index={index}
                                       handleChange={handleItemChange}
                                       removeSection={() => removeSection(item.id)}
                                       key={item.id}/>
    };

    return (
        <div className={"create-tool"}>
            {/*<button onClick={fixeOldVersion} style={{marginRight: "auto"}}>FIX OLD VERSION BUG</button>*/}
            <Table>
                <tbody>{audioItems.map(renderSection)}</tbody>
            </Table>

            <button className={"tool-add-section"} onClick={addSection}>
                הוסף אודיו
            </button>

            <div>
                <FieldSelect options={["blocks", "grid", "gallery"].map(listViewOption)}
                             name="list_view"
                             label="אופן תצוגת הרישמה"
                             value={listViewOption(listView)}
                             changeHandler={(name, item) => setListView(item.value)}/>

                <FieldCheckbox options={["iTunes"]}
                               name={"iTunes"}
                               changeHandler={handleToolDataChange}
                               value={iTunes ? ["iTunes"] : []}/>

                <FilesUploader multiple={false}
                               label={"תמונת רקע לרשימה"}
                               triggerValue={"תמונת רקע לרשימה"}
                               name={'audio_list_background_image'}
                               changeHandler={handleToolDataChange}
                               value={audio_list_background_image}/>
            </div>
        </div>
    )
};

CreateToolAudioList.defaultProps = {};

CreateToolAudioList.propTypes = {};


