import React, {useContext, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Element, HBox} from 'react-stylesheet';
import PuzzleBoard from './puzzle-board';
import cover_1 from './frames/1.jpg';
import cover_2 from './frames/2.jpg';
import cover_3 from './frames/3.jpg';
import cover_4 from './frames/4.jpg';
import cover_5 from './frames/5.jpg';
import cover_6 from './frames/6.jpg';
import cover_7 from './frames/7.jpg';
import cover_8 from './frames/8.jpg';
import cover_9 from './frames/9.jpg';
import cover_10 from './frames/10.jpg';
import easy from '../../../assets/images/easy.png';
import medium from '../../../assets/images/medium.png';
import hard from '../../../assets/images/hard.png';
import {MdPlayCircleOutline, MdSettingsBackupRestore} from 'react-icons/md';

import Heading from "../../01_atoms/text/a-heading";
import {Wrapper} from "./tool-puzzle-style";
import {font_size} from "../../00_base/variables";
import {ScreenToolIcon} from "../../05_screens/tool/screen-tool-icon";
import {FrameContext} from "../../05_screens/tool/screen-tool";
import "./tool-puzzle-style.scss";

const ToolPuzzle = (
    {
        puzzle_image,
        level,
        coverUrl,
        start_title,
        title_size,
        success_title,
        screenBackground,
        borderColor,
        borderWidth,
        textColor = "#fff",
        thumbnail
    }) => {
    const frameContext = useContext(FrameContext);

    const isResolve = frameContext.isFrameFinish;
    const handleFrameFinish = frameContext.handleFrameFinish;

    const [status, setStatus] = useState(isResolve ? "finish" : 'play');//visitor ? 'visitor' : !!level ? !!coverUrl ? 'ready' : 'chooseCover' : null);
    const [choosenLevel, setChoosenLevel] = useState(level);
    const [choosenCover, setChoosenCover] = useState(coverUrl);
    const [score, setScore] = useState(0);
    const showNumbers = true;
    const gameData = useSelector(state => state.gameData);

    const {adventureType = {}, adventure = {}} = gameData || {};
    const isEmployee = adventureType?.id === 324 || adventureType?.id === 330 || adventureType?.id === 332;
    const isEnglish = adventureType?.id === 332;

    useEffect(() => {
        if (status === "finish") {
            handleFrameFinish()
        }
    }, [handleFrameFinish, status])

    const ActionsBtns = ({btns}) => {
        return <HBox justifyContent={'center'} padding={'20px 0'}>
            {btns.map(btn => {
                    const Icon = btn.icon;
                    return (
                        <Element key={btn.id}
                                 onClick={btn.action}
                                 background="transparent"
                                 margin="0 10px">
                            <Icon size={30} color={textColor}/>
                        </Element>
                    )
                }
            )}
        </HBox>
    };


    const startGame = () => {
        setStatus('play');
        setChoosenLevel(choosenLevel)
    };

    const chooseLevelButtons = [
        {label: 'Hard', color: '#f1162b', icon: hard, level: 3},
        {label: 'Medium', color: 'pink', icon: medium, level: 2},
        {label: 'Easy', color: 'lightblue', icon: easy, level: 1}
    ];
    const covers = [
        {id: 1, url: cover_1},
        {id: 2, url: cover_2},
        {id: 3, url: cover_3},
        {id: 4, url: cover_4},
        {id: 5, url: cover_5},
        {id: 6, url: cover_6},
        {id: 7, url: cover_7},
        {id: 8, url: cover_8},
        {id: 9, url: cover_9},
        {id: 10, url: cover_10},
    ];
    const actionsBtns = [
        {id: 1, label: 'restart', icon: MdSettingsBackupRestore, action: () => setStatus("play")},
        // {id: 2, label: 'share', icon: MdShare, action: () => console.log('share')},
    ];
    const noStatus = (
        <div>
            <h2 style={{textAlign: 'center', fontWeight: 'bold', margin: '10px 0 20px'}}>Choose game level</h2>
            <div style={{position: 'relative'}}>

                <div style={{width: '100%', padding: '15px', backgroundImage: `url(${choosenCover})`}}>
                    <img alt="" src={puzzle_image} width='100%'/>
                </div>

                <div className={"tool-puzzle-level-buttons"}>
                    {chooseLevelButtons.map((button, index) => {
                        return (
                            <div className={"tool-puzzle-level-button"} key={index}>
                                <button key={button.level}
                                        style={{backgroundColor: button.color}}
                                        onClick={() => {
                                            setChoosenLevel(button.level);
                                            setStatus(coverUrl ? 'play' : 'chooseCover');
                                        }}>
                                    <img alt="" src={button.icon} width={'100%'}/>
                                </button>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );

    const statusCover = (
        <>
            <img alt="" src={puzzle_image} width='100%'/>
            <h4>Choose Board</h4>
            <div style={styles.coversWrapper}>
                {covers.map((cover, index) => {
                    return <div style={styles.coverWrapper}
                                key={index}
                                onClick={() => {
                                    setChoosenCover(cover.url);
                                    setStatus('ready');
                                }}>
                        <img alt="" src={cover.url} style={styles.cover}/>
                    </div>
                })}
            </div>
        </>
    );

    const statusReady = (
        <div style={{padding: '15px', backgroundImage: `url(${choosenCover})`, backgroundSize: 'cover'}}>
            <img alt="" src={puzzle_image} width='100%'/>
            <div style={styles.startButtonWrapper}>
                <button style={styles.startButton} onClick={startGame}>
                    <MdPlayCircleOutline size={110}/>
                </button>
            </div>
        </div>
    );

    const statusPlay = (
        <>
            {/*<div className="tool-puzzle-score">{score}</div>*/}
            <PuzzleBoard choosenCover={choosenCover}
                         level={choosenLevel}
                         textColor={textColor}
                         imageUrl={puzzle_image}
                         restart={() => setStatus(null)}
                         finishGame={() => setStatus('finish')}
                         updateScore={() => setScore(score + 1)}
                         showNumbers={showNumbers}/>
        </>
    );
    const statusFinish = (
        <div style={{position: "relative"}}>
            <h2 style={{
                direction: 'ltr',
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '10px 0',
                fontSize: font_size.lg,
                color: textColor
            }}>
                {success_title?.replace("{name}", adventure.adventure_name)}
            </h2>
            {/*<Fireworks/>*/}
            <div style={{position: 'relative', padding: '15px', backgroundImage: `url(${choosenCover})`}}>
                <img alt="" src={puzzle_image} width='100%' style={{opacity: '.9'}}/>
            </div>

            {isEmployee &&
            <>
                {adventure?.json_data?.employee_company_name && adventureType?.id !== 332 &&
                <p style={{fontSize: "20px", color: "#000"}}>באהבה, {adventure?.json_data?.employee_company_name}</p>
                }
                {adventure?.json_data?.employee_company_logo &&
                <div style={{textAlign: "center"}}>
                    <img src={adventure?.json_data?.employee_company_logo}
                         alt={adventure?.json_data?.employee_company_name || "company logo"} width={80}/>
                </div>
                }

                <div style={{marginTop: "10px", textAlign: "center"}}>
                    <a href={`https://youdoadventures.com/${isEnglish ? "en" : "he"}/adventure/${isEnglish ? 332 : 324}`}
                       style={{fontSize: "20px", textAlign: "center", color: "#000", textDecoration: "underline"}}>
                        {isEnglish ? "Send an adventurous birthday blessing" : "שלחו ברכת יום הולדת הרפתקנית"}
                    </a>
                </div>
            </>
            }
            <ActionsBtns btns={actionsBtns}/>

        </div>
    );

    const statusVisitor = (
        <>
            <h2 style={{direction: 'ltr', fontWeight: 'bold', textAlign: 'center', margin: '20px 0'}}>
                I solved my greeting card!
            </h2>
            <div style={{padding: '15px', backgroundImage: `url(${choosenCover})`}}>
                <img src={puzzle_image} alt="" width='100%'/>
            </div>
            <div style={{...styles.startButtonWrapper}}>
                <button style={{background: 'pink', border: 0, fontWeight: 'bold', padding: '5px 10px'}}>
                    <a href={'https://youdoadventures.com/valentine'}>
                        Create your greeting card
                    </a>
                </button>

            </div>
        </>
    );

    return (
        <Wrapper className={"tool-puzzle"}
                 screenBackground={screenBackground}
                 borderColor={borderColor}
                 borderWidth={borderWidth}>
            <div className={"tool-puzzle-container"} style={styles.wrapper}>
                {thumbnail && <ScreenToolIcon icon={thumbnail}/>}
                <Heading tag={'h4'}
                         fontSize={`${title_size}px`}
                         color={textColor}
                         value={start_title?.replace("{}")}/>
                {!status && noStatus}
                {status === 'chooseCover' && statusCover}
                {status === 'ready' && statusReady}
                {status === 'play' && statusPlay}
                {status === 'finish' && statusFinish}
                {status === 'visitor' && statusVisitor}
            </div>
        </Wrapper>
    );

};

const styles = {
    coversWrapper: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    coverWrapper: {
        width: '20%'
    },
    cover: {
        width: '100%'
    },
    startButtonWrapper: {
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1,
        top: 0,
        left: 0
    },
    startButton: {
        fontSize: '30px',
        fontWeight: 'bold',
        border: 0,
        padding: '0',
        textTransform: 'uppercase',
        borderRadius: '50%',
        backgroundColor: 'red'
    },
    showNumbersTrigger: {
        marginTop: '10px',
        opacity: 0,
        display: 'none'
    },
    fireworksWrapper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50% -50%)'
    }
}

export default ToolPuzzle; 