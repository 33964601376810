import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getRiddle, setAdventureRiddles, updateRiddle} from "../../../store/actions/riddle-actions";
import {LoaderPageContent} from "../../01_atoms/loaders";
import CreateRiddle from "../riddle/create-riddle";
// import {createTemplate} from "../../../store/actions/templates-actions";
import {RiddleTemplates} from "../riddle/template/riddle-templates";
import Button from "../../01_atoms/button/a-button";
import {selectActiveFrame, selectAdventure, selectAllTags} from "../../../store/selectors";
import {SingleFrame} from "../riddle/template/single-frame";
import Modal from "../../03_organisms/modal/modal";
import {createFrame, setActiveFrame} from "../../../store/actions/frames-actions";
import {FieldText, FieldTextArea, FilesUploader} from "../../02_molecules";
import {gutters} from "../../00_base/variables";
import {Tabs} from "../../03_organisms";
import arrayMove from "array-move";
import {RiddleMainContent} from "./riddle-main-content";

export const RiddleMain = ({match}) => {
    const {riddleId} = match.params;
    const singleAdventure = useSelector(selectAdventure);
    const allTags = useSelector(selectAllTags);
    const {riddles, frames, activeFrame} = singleAdventure;

    const [isLoading, setIsLoading] = useState(true);

    const riddle = riddles.find(item => item.id === parseInt(riddleId)) || {};
    const {id, title, description, description_short, templatedata, thumbnail} = riddle;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setAdventureRiddles(
            [riddleId],
            (riddles, frames) => {
                // setFrames(frames);
                // setRiddle(riddles[0]);
                setIsLoading(false);
            }));
    }, []);


    const handleRiddleChange = (name, value) => {
        dispatch(updateRiddle(
            {...riddle, [name]: value},
            updatedRiddle => {
                // setRiddle(updatedRiddle);
            }));
    };

    const addNewFrame = () => {
        dispatch(createFrame(
            {title: "פריים חדש"},
            parseInt(riddleId),
            newFrame => {
                // handleRiddleChange("riddlestemplates", riddle.riddlestemplates.concat(newFrame.id))
            }))
    };

    const removeRiddleFrame = (frameId) => {
        const updatedFrames = riddle.riddlestemplates.filter(id => id !== frameId);
        // setFrames(updatedFrames);
        handleRiddleChange("riddlestemplates", updatedFrames);
    };

    const closeFrame = () => dispatch(setActiveFrame(null));

    const reorderFrames = ({oldIndex, newIndex}) => {
        const updatedFrames = arrayMove(frames, oldIndex, newIndex);
        // serRenderFrames(updatedFrames);
        handleRiddleChange("riddlestemplates", updatedFrames.map(frame => frame.id));
    };
    if (isLoading) {
        return (
            <LoaderPageContent/>
        )
    }

    const RiddleContent = () => {
        return <RiddleMainContent handleRiddleChange={handleRiddleChange}
                                  riddle={riddle}/>
    };

    const RiddleFrames = () => {
        return <>
            <RiddleTemplates frames={frames}
                             indexStart={0}
                             templatedata={templatedata}
                             removeRiddleFrame={removeRiddleFrame}
                             riddle_id={id}
                // handleChange={handleChange}
                             reorderFrames={reorderFrames}/>
            <Button className="d-block mx-auto"
                    onClick={addNewFrame}
                    value="הוסף פריים"/>
        </>
    };

    const tabsItems = [
        {label: "תוכן", content: <RiddleContent/>},
        {label: "רצף החידה", content: <RiddleFrames/>}
    ];

    return (
        <div className="container">
            <h1 className={"text-center font-size-xl"}>{title || ""}</h1>

            {activeFrame &&
            <Modal isActive="active"
                   hideTrigger={true}
                   closePreActions={closeFrame}
                   contentStyle={{width: "100%", maxHeight: "100%"}}>
                <SingleFrame frame={frames.find(frame => frame.id === activeFrame)}
                             handleCloseFrame={closeFrame}/>
            </Modal>
            }

            <Tabs items={tabsItems}
                  contentStyle={{overflow: "visible"}}/>

        </div>
    )
};