import {createContext, useContext} from "react";

import {ar, en, he} from './mock/';

export const dictionaryList = {he, en, ar};


export const languageOptions = {
    he: 'עברית',
    en: 'English',
    ar: "عربي"
};

export const LanguageContext = createContext({
    userLanguage: 'he',
    languages: null,
    createLanguage: null,
    dictionary: dictionaryList.he,
    filterByLanguage:null,
    getLanguageTranslations: null
});

export const Translation = ({id}) => {
    const languageContext = useContext(LanguageContext);
    return languageContext.dictionary[id] || id;
};