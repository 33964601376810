import React, {useState} from "react";
import {CssCreateSizes} from "./css-create-sections/css-create-sizes";
import {CssCreateMargin} from "./css-create-sections/css-create-margin";
import {CssCreateTypo} from "./css-create-sections/css-create-typo";
import {CssCreateColors} from "./css-create-sections/css-create-colors";
import {CssCreateBorder} from "./css-create-sections/css-create-border";
import {CssCreatePadding} from "./css-create-sections/css-create-padding";
import {CssCreateDisplay} from "./css-create-sections/css-create-display";
import {font_size} from "../../../00_base/variables";
import "./css-general.scss";

export const CssGeneral = ({id = "", title, defaultProps = {}, changeHandler, PreviewComponent, className = ""}) => {
    const [isOpen, setIsOpen] = useState(!title);

    const fieldCommonProps = {id, labelStyle, inputStyle, changeHandler};
    const sections = [CssCreateDisplay, CssCreateSizes, CssCreatePadding, CssCreateMargin, CssCreateTypo, CssCreateColors, CssCreateBorder]
    const Title = (
        title && <div className="general-css-form-header" onClick={() => setIsOpen(!isOpen)}>
            <h3 className="general-css-form-title">{title}</h3>
        </div>
    );

    const Body = (
        <div className="general-css-form-body">
            {PreviewComponent &&         <div className="general-css-form-preview"> <PreviewComponent/></div>}
            <div className="container general-css-form-section button-create-section">
                {sections.map((CreateStyle, index) => {
                    return <CreateStyle key={index}
                                        {...defaultProps}
                                        {...fieldCommonProps}/>
                })}
            </div>
        </div>
    );

    return (
        <div className={`general-css-form ${className}`}>
            {Title}
            {isOpen && Body}
        </div>
    )
};

const labelStyle = {marginBottom: 0, paddingLeft: "5px", fontSize: font_size.sm};
const inputStyle = {marginRight: 0, maxWidth: "100px"};