import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {Element, HBox} from 'react-stylesheet';
import {Col, Container, Row} from 'react-grid-system';
import {MdClose, MdDelete, MdPlayArrow, MdStop} from 'react-icons/md';
import {CreateAdventureContext} from "../../../pages/adventure-create/screen-adventure-create";
import {Translation} from "../../../../../languages";
import {DICT} from "../../../../../languages/dictionary-names";
import {colors, gutters, zIndex} from '../../../../00_base/variables';
import {Button, Circle, Heading, MobileOnly, Video} from '../../../../01_atoms';
import {FieldSelect, FieldText, FieldVideo, ToolTip} from '../../../../02_molecules';
import {IconAngleLeft, IconAngleRight} from '../../../../02_molecules/icons';
import "./create-contact-list.css";
import {ThemeContext} from "../../../../../providers/theme-provider";

const ContactListItem = (
    {
        name,
        sentence,
        sentencesOptions,
        keyword,
        video,
        handleToolDataChange,
        dropdownOpen,
        setDropdownOpen,
        setActiveSection,
        activeSection,
        toolData,
        adventureType,
        warningActive,
        index
    }) => {
    const {adventure_name, warningsActive} = useContext(CreateAdventureContext);
    const {mode} = useContext(ThemeContext);
    const darkMode = mode === "dark";
    const [videoUrl, setVideoUrl] = useState(video);

    const [previewVideoActive, setPreviewVideoActive] = useState(false);

    const handleChange = (name, value) => {
        handleToolDataChange(name, value, index);
    };
    const onVideoChange = (name, value) => {
        if (value || value === "") {
            setVideoUrl(value.value || value);
            handleChange("video", value);
        }
    };
    const toggleVideo = () => setPreviewVideoActive(!previewVideoActive);
    const removeVideo = () => {
        setVideoUrl('');
        setPreviewVideoActive(false);
    };
    const defaultTextValue = `שם המברך.ת`;

    const PreviewComponent = ({handleFileChange}) => {
        const buttonStyle = {display: "flex", alignItems: "center", margin: `0 ${gutters.xxs}`};
        const Icon = previewVideoActive ? MdStop : MdPlayArrow;
        return <div className={"d-flex align-items-center"}>
            <Button background={colors.action}
                    backgroundColorHover={colors.action_hover}
                    pointer
                    style={buttonStyle}
                    value={<Icon color={colors.white}/>}
                    onClick={toggleVideo}/>
            <Button pointer
                    style={buttonStyle}
                    background={colors.error}
                    backgroundColorHover={colors.error_hover}
                    value={<MdDelete color={colors.white}/>}
                    onClick={removeVideo}/>
        </div>
    };

    const isNameFieldOk = name => {
        return !(!!name && !!(name.trim()) && name !== defaultTextValue);
    };
    const dropdown = dropdownOpen === index;

    const DropdownElement = (item, i) => {
        return (
            <div key={i}
                 onClick={(e) => {
                     e.stopPropagation();

                     // setCurrentSentence(item);
                     setDropdownOpen(-1)
                 }}
                 className={'contact-list-dropdown-item'}>
                <p key={i}>{item.replace("{name]", adventure_name)}</p>
            </div>
        )
    };

    return (
        <Wrapper padding={`${gutters.sm} 0`}
                 className={activeSection === index ? "active" : ""}>
            <div>
                <MobileOnly>
                    <HBox width="100%" justifyContent="center">
                        <Button
                            style={{opacity: activeSection > 0 ? 1 : .3}}
                            onClick={() => activeSection > 0 && setActiveSection(activeSection - 1)}
                            background="transparent"
                            backgroundColorHover="transparent">
                            <Circle size={25} background={darkMode ? colors.white : colors.gray_6}>
                                <IconAngleRight width="13px" height="16px"
                                                color={darkMode ? colors.black : colors.white}/>
                            </Circle>
                        </Button>
                        <Button
                            style={{opacity: activeSection < toolData.items.length - 1 ? 1 : .3}}
                            onClick={() => activeSection < toolData.items.length - 1 && setActiveSection(activeSection + 1)}
                            background={'transparent'}
                            backgroundColorHover={'transparent'}>
                            <Circle size={25} background={darkMode ? colors.white : colors.gray_6}>
                                <IconAngleLeft width="13px" height="16px"
                                               color={darkMode ? colors.black : colors.white}/>
                            </Circle>
                        </Button>
                    </HBox>
                </MobileOnly>

                <Container style={{...formStyle, paddingLeft: 0, paddingRight: 0}}>
                    <Row style={{width: '100%', alignItems: 'center', margin: "10px 0"}}>
                        {keyword && <Col md={2} xl={1}>
                            <Heading tag={'h5'} className={'m-0 color-black'}>{keyword}</Heading>
                        </Col>}
                        <Col xs={12} md={2} style={{marginBottom: "10px"}}>
                            <FieldText placeholder={defaultTextValue}
                                       invalid={(warningsActive) && isNameFieldOk(name)}
                                       value={(name !== defaultTextValue) ? name : ''}
                                       initialValue={(name !== defaultTextValue) ? name : ''}
                                       index={index}
                                       controlStyle={{marginBottom: 0}}
                                       name={'name'}
                                       changeHandler={handleChange}/>
                        </Col>
                        <Col style={{marginBottom: "10px"}}>
                            {index === 5 && (adventureType === 30 || adventureType === 40) ?
                                <Heading tag={"h4"}
                                         value={'"' + sentence + '"'}/>
                                :
                                <HBox alignItems={'center'} overflow={'visible'}>
                                    <Element width={"100%"} marginLeft={gutters.sm} position={'relative'}
                                             overflow={'visible'}>
                                        {sentencesOptions && sentencesOptions[0] ?
                                            <FieldSelect controlStyle={{marginBottom: 0, padding: '0'}}
                                                         index={index}
                                                         name={'sentence'}
                                                         paddingLeft={0}
                                                         inputHeight={'5rem'}
                                                         inputRadius={'1rem'}
                                                         options={sentencesOptions.map(item => {
                                                             return {label: item, value: item, id: item}
                                                         })}
                                                         value={{
                                                             label: sentence.replace("{name}", adventure_name),
                                                             value: sentence,
                                                             id: sentence
                                                         }}
                                                         createOptionHandler={(value) => {
                                                             handleChange('sentencesOptions', sentencesOptions.concat(value));
                                                             handleChange('sentence', {value: value});
                                                         }}
                                                         changeHandler={handleChange}/>

                                            :
                                            <FieldText controlStyle={{marginBottom: 0}}
                                                       placeholder={`הכנס משפט`}
                                                       value={sentence.replace("{name}", adventure_name)}
                                                       initialValue={sentence.replace("{name}", adventure_name)}
                                                       index={index}
                                                       name={'sentence'}
                                                       changeHandler={handleChange}/>
                                        }
                                        {dropdown && sentencesOptions &&
                                        <Element position="absolute"
                                                 width='100%'
                                                 style={{
                                                     borderBottomLeftRadius: '.8rem',
                                                     borderBottomRightRadius: '.8rem'
                                                 }}
                                                 background='#fff'
                                                 top='50px'
                                                 padding={`${gutters.sm} 0`}
                                                 border='.1rem solid #d1d0d2'
                                                 zIndex={1}>
                                            {sentencesOptions.map(DropdownElement)}
                                        </Element>
                                        }
                                    </Element>
                                    {/*{sentencesOptions && sentencesOptions.length > 0 &&*/}
                                    {/*<MdExpandMore onClick={(e) => {*/}
                                    {/*e.stopPropagation();*/}
                                    {/*setDropdownOpen(dropdown ? -1 : index)*/}
                                    {/*}}*/}
                                    {/*size={32}*/}
                                    {/*style={{cursor: "pointer"}}/>}*/}
                                </HBox>
                            }
                        </Col>
                        <Col md={2} style={{marginBottom: "10px", cursor: 'pointer', padding: 0}}>
                            {videoUrl ?
                                <PreviewComponent handleFileChange={handleToolDataChange}/>
                                :
                                <FieldVideo previewHeight={'auto'}
                                            triggerValue={<Translation id={DICT.UPLOAD_VIDEO}/>}
                                            hideTextField={true}
                                            alertButton={warningActive}
                                            name={'video'}
                                            value={videoUrl}
                                            controlStyle={{border: 0}}
                                            buttonWidth="6rem"
                                            changeHandler={onVideoChange}
                                            index={index}
                                            triggerTip={<Translation id={DICT.UPLOAD_VIDEO}/>}/>
                            }
                        </Col>
                    </Row>
                    {previewVideoActive &&
                    <PreviewVideo className={'preview-video'}>
                        <button onClick={() => setPreviewVideoActive(false)} className={'close-btn'}>
                            <MdClose size={20}/>
                        </button>
                        <Video source={videoUrl}
                               autoPlay={true}
                               controls={true}
                               videoStyle={{position: 'absolute', transform: "none", top: 0, left: 0}}/>
                    </PreviewVideo>
                    }
                </Container>
            </div>
        </Wrapper>
    )
}

const formStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
};

const Wrapper = styled.div`
        order: ${props => props.order};
        @media (max-width: 768px) {
            display: none;
            
        }
        &.active {
            display: block
        }
    }
`;


const PreviewVideo = styled.div`
    // position: absolute;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0,0,0,0.9);
    z-index: ${zIndex.medium};
    overflow: hidden;
    width: 100%;
    height: 100%;
    height: 600px;
    top: 0;
    left: 0;
`;

export default ContactListItem;