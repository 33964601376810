import React, {useEffect} from "react";
import MetaTags from 'react-meta-tags';

export const Screen = (
    props
) => {
    const {title = "", description = "", image, path, component, match} = props;

    useEffect(() => {
        document.title = title; 
    }, [title]);

    const url = `https://youdoadventures.com${path.replace(":id", match.params.id)}`;

    const Page = component;
    return <div className="screen" style={{width: "100%"}}>
        <MetaTags>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta property="og:title" content={title}/>
            <meta property="og:image" content={image}/>
            <meta property="og:url" content={url}/>
        </MetaTags>
        <Page {...props}/>
    </div>
}