import React, {useState, useContext} from "react";
import {useHistory} from "react-router-dom";
import {Td, TdContent, Tr} from "../../../../03_organisms/table";
import {FieldText} from "../../../../02_molecules";
import "./blog-item.scss";
import {deleteBlog as deleteBlogAction, updateBlog} from "../../../../../store/actions/blogs-actions";
import {useDispatch, useSelector} from "react-redux";
import Swal from "sweetalert2"
import {VBox} from "react-stylesheet/lib";
import SimpleLoader from "../../../../01_atoms/loaders/simple-loader";
import svgClose from "../../../../../assets/images/svg-icons/svg-close.svg";
import svgEye from "../../../../../assets/images/svg-icons/svg-eye.svg";
import svgEdit from "../../../../../assets/images/svg-icons/svg-edit.svg";
import {Toast} from "../../../../../store/actions/fetch-data";
import {dateFormat} from "../../../../../helpers/dates/date-format";
import {Switcher} from "../../../../01_atoms/switch/a-switch";
import {LanguageContext} from "../../../../../languages";
import {ChooseItemLanguage} from "../../../../03_organisms/choose-item-language/choose-item-language";

export const BlogItem = (
    {
        item,
        index,
        blogProperties,
        toggleMarkItem,
        changeHandler,
        setActiveModalId
    }) => {
    const {userLanguage} = useContext(LanguageContext);
    const [loadingId, setLoadingId] = useState(false)
    const dispatch = useDispatch();
    const history = useHistory();

    const defaultProps = (style) => ({
        inputStyleWithValue: {backgroundColor: "transparent"},
        controlStyle: {margin: 0, height: "100%", ...style},
        inputStyle: {border: 0, height: "100%", padding: 0}
    });

    const deleteBlog = () => {
        Swal.fire({
            title: "האם אתה בטוח שברצונך למחוק את הבלוג?",
            text: "לא יהיה אפשר לבטל את הפעולה!",
            type: "warning",
            showCancelButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "מחק",
            cancelButtonText: "ביטול",
            closeOnConfirm: true,
            closeOnCancel: true,
        })
            .then((result) => {
                if (result.value) {
                    setLoadingId(item.id)
                    dispatch(deleteBlogAction(item.id, onSuccess, onError))
                }
            })
    };

    const handleChange = (name, value) => {
        dispatch(updateBlog({...item, [name]: value}))
    };

    const onSuccess = (res) => {
        console.log("Success - ", res);
        setLoadingId(false);
    };

    const onError = (err) => {
        console.log("Error - ", err);
        setLoadingId(false);
        alert("שגיאה");
    };

    if (loadingId === item.id) {
        return (
            <VBox alignItems={"center"} justifyContent={'center'} height={"45px"} width={"50px"}>
                <SimpleLoader size={25}/>
            </VBox>
        )
    }


    const goToSingle = () => {
        history.push(`/${userLanguage}/admin/blog/${item.id}`);
        // window.location.href = window.location.href.substring(0, window.location.href.length - 1) + "/" + item.id;
    };

    const sideOptions = (
        <div className={"single-blog-side-options"}>
            <div className={"px-3"}>
                <img src={svgEye}
                     alt="Preview"
                     className={"single-blog-side-option preview"}
                     onClick={() => setActiveModalId(item.id)}/>
            </div>
            <div className={"px-3"}>
                <img src={svgEdit} alt="Edit"
                     className={"single-blog-side-option edit"}
                     onClick={goToSingle}/>
            </div>
            <div className={"px-3"}>
                <img src={svgClose}
                     alt="Delete"
                     className={"single-blog-side-option delete"}
                     onClick={deleteBlog}/>
            </div>
        </div>
    );

    return (
        <Tr className={"admin-blog-item"}>
            {blogProperties.map(({title, language_id, key, style, editable}) => {
                    const isDate = key === "created_at";
                    const value = isDate ? dateFormat(item[key]) : item[key];
                    return <Td key={key}>
                        <TdContent>
                            {key === "language_id" ?
                                <ChooseItemLanguage value={item.language_id}
                                                    changeHandler={handleChange}
                                                    flagsSize={20}/>
                                :

                                key === "is_active" ?
                                    <Switcher name={key}
                                              changeHandler={handleChange}
                                              checked={value}/>
                                    :
                                    <FieldText name={key}
                                               value={value}
                                               mode={!editable && "read"}
                                               changeHandler={handleChange}
                                               {...defaultProps(style)}/>
                            }
                        </TdContent>
                    </Td>
                }
            )}
            {sideOptions}
        </Tr>
    )
}