const slide_top = `
    .slide-top-enter {
        transform: translateY(-100%);
    } 
    .slide-top-exit.slide-top-exit-active {
        transform: translateY(100%);
        transition: transform 300ms ease-in;
    }
`;
const slide_bottom = `
    .slide-bottom-enter {
        transform: translateY(100%);
    }
    .slide-bottom-exit.slide-bottom-exit-active {
        transform: translateY(-100%);
        transition: transform 300ms ease-in;
    }
`;
const slide_right = `
    .slide-right-enter {
        transform: translateX(-100%); 
    }  
    .slide-right-exit.slide-right-exit-active {
        transform: translateX(100%);
        transition: transform 300ms ease-in;
    }
`;
const slide_left = `
    .slide-left-enter {
        transform: translateX(100%);
    } 
    .slide-left-exit.slide-left-exit-active {
        transform: translateX(-100%);
        transition: transform 300ms ease-in;
    }
`;
const slideScreen = `
    .slide-top-enter.slide-top-enter-active, 
    .slide-bottom-enter.slide-bottom-enter-active,
    .slide-right-enter.slide-right-enter-active,
    .slide-left-enter.slide-left-enter-active,
    .slide-enter.slide-enter-active {
        transform: translate(0);
        transition: transform 300ms ease-in;
    }
    .slide-exit, 
    .slide-top-exit, 
    .slide-bottom-exit, 
    .slide-left-exit, 
    .slide-right-exit {
        transform: translateX(0); 
    }
    .slide-enter {
        transform: translateX(-100%);
    }
    .slide-exit.slide-exit-active {
        transform: translateX(100%);
        transition: transform 300ms ease-in;
    }
    ${slide_top}
    ${slide_bottom}
    ${slide_right}
    ${slide_left}
`;

export {slideScreen};