import React, {useContext, useEffect, useState} from "react";
import {SWITCHES_USER_STATUS} from "./switches-local-storage-consts"
import SingleSwitch from "./single-switch/single-switch";
import PropTypes from 'prop-types';
import _ from 'lodash';
import "./switches-styles.scss";
import {FrameContext} from "../../05_screens/tool/screen-tool";

export const ToolSwitches = (
    {
        items,
        switches_on,
        switches_off,
        onClick,
        switchHeight,
        textColor,
        fontSize,
        switchWidth,
        buttonSize,
    }) => {
    const {handleFrameFinish, isFrameFinish} = useContext(FrameContext);

    const [userStatus, setUserStatus] = useState(JSON.parse(localStorage.getItem(SWITCHES_USER_STATUS)) ||
        items.map(({status}) => status));

    // useEffect(() => {
    //     setUserStatus(items.map(({status}) => status))
    // }, [items]);

    useEffect(() => {
        localStorage.setItem(SWITCHES_USER_STATUS, JSON.stringify(userStatus));
    }, [userStatus]);

    const handleSwitchChange = (index, status) => {
        if (onClick) {
            onClick();
        }
        let userStatusCopy = JSON.parse(JSON.stringify(userStatus));
        userStatusCopy[index] = status;
        setUserStatus(userStatusCopy);

        if (_.isEqual(userStatusCopy.map(item => !!item), items.map(item => !!item.answer))) {
            setTimeout(handleFrameFinish, 100);
        }
    };

    const renderSwitches = () => {
        return items.map((singleSwitch, index) =>
            <SingleSwitch {...singleSwitch}
                          fullOnImage={singleSwitch.fullOnImage || switches_on}
                          fullOffImage={singleSwitch.fullOffImage || switches_off}
                          fontSize={fontSize}
                          textColor={textColor}
                          switchHeight={switchHeight}
                          switchWidth={switchWidth}
                          buttonSize={buttonSize}
                          status={userStatus[index]}
                          onClick={status => !isFrameFinish &&  handleSwitchChange(index, status)}
                          key={singleSwitch.id}/>
        )
    };

    return (
        <div className={"switches-container"}>
            {renderSwitches()}
        </div>
    )
};

ToolSwitches.defaultProps = {
    items: [],
};

ToolSwitches.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            bottomTitle: PropTypes.string,
            topTitle: PropTypes.string,
            answer: PropTypes.bool.isRequired,
            status: PropTypes.bool,
            fullOnImage: PropTypes.string,
            fullOffImage: PropTypes.string,
            offWrapperImage: PropTypes.string,
            onWrapperImage: PropTypes.string,
            onButtonImage: PropTypes.string,
            offButtonImage: PropTypes.string,
        })
    ).isRequired,
    handleFinish: PropTypes.func,
    onClick: PropTypes.func,
    switchHeight: PropTypes.string,
    switchWidth: PropTypes.string,
    buttonSize: PropTypes.string,
    switches_on: PropTypes.string,
    switches_off: PropTypes.string,
};