import React, {useState} from "react";
import {useContext} from "react";
import {LanguageContext} from "../../../languages";
import {FieldOptions} from "../../02_molecules";
import {Image} from "../../01_atoms";
import {MdLanguage} from "react-icons/all";

export const ChooseItemLanguage = ({name = "language_id", label, value = 0, changeHandler, flagsSize = 30, className}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {
        languages = []
    } = useContext(LanguageContext);
    const languagesOptions = languages?.map(({id, title, name, flag}) => {
        return {id: id, label: <img src={flag} width={flagsSize}/>, value: name}
    }) || [];
    const defaultLanguage = languagesOptions.find(lang => lang.id === value) || {
        id: 0,
        title: "עברית",
        name: "he",
        translations: {},
        flag: ""
    };
    const [activeValue, setActiveValue] = useState(defaultLanguage);

    const handleChange = (key, value) => {
        const langId = languages.find(lang => lang.name === value).id;
        changeHandler(name, langId);
        setActiveValue(languagesOptions.find(lang => lang.id === langId));
        setIsOpen(false)
    };

    return (
        <div className={className}>
            {isOpen ?
                <FieldOptions label={label}
                              name={name}
                              controlStyle={{display: "flex", marginBottom: 0}}
                              changeHandler={handleChange}
                              value={activeValue}
                              options={languagesOptions}/>
                :
                <div onClick={() => setIsOpen(true)} style={{cursor: "pointer"}}>
                    {activeValue.label || <MdLanguage size={20}/>}
                </div>
            }
        </div>
    )
};
