import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import {MdClose} from "react-icons/md";
import {FieldCheckbox, FieldText} from "../../../../../02_molecules";
import {ButtonCreate} from "../../../../ui/button/create/button-create";
import {MdArrowDropDown, MdArrowDropUp} from "react-icons/all";

export const AdditionalDataActionButtons = (
    {handleToolDataChange, actionButtons = [], actionButtonStyle = {}, actionButtonSize, actionButtonBackgroundColor, actionButtonColor, actionButtonCorrect}
) => {
    const [isActive, setIsActive] = useState(actionButtons?.length > 0);
    const [isOpen, setIsOpen] = useState(actionButtons?.length > 0);

    const [newButton, setNewButton] = useState("");
    const [buttons, setButtons] = useState(actionButtons);

    useEffect(() => setIsOpen(isActive), [isActive]);

    const toggle = (name, value) => {
        setIsActive(value);
        if (!value) {
            setButtons([]);
            handleToolDataChange("actionButtons", []);
        }
    };

    const addButton = (name, value) => {
        const updated = buttons.concat(value);
        setNewButton("");
        setButtons(updated);
        handleToolDataChange(name, updated);
    };

    const removeButton = (index) => {
        const updated = buttons.filter((item, i) => index !== i);
        setButtons(updated);
        handleToolDataChange("actionButtons", updated);
    };

    const handleButtonStyleChange = (name, value) => {
        handleToolDataChange("actionButtonStyle", {
            ...actionButtonStyle,
            [name]: value
        });
    }
    const IconDrop = isOpen ? MdArrowDropUp : MdArrowDropDown;
    const wrapperStyle = {borderBottom: `${isOpen ? 1 : 0}px dashed #000`, marginBottom: "10px"};

    return (
        <div style={wrapperStyle}>
            <div className={"d-flex justify-content-between align-items-center"}>
                <FieldCheckbox options={["כפתורי פעולה מתחת הכלי"]}
                               name="textActive"
                               controlStyle={{marginBottom: 0}}
                               value={isActive ? ["כפתורי פעולה מתחת הכלי"] : []}
                               changeHandler={toggle}/>
                {isActive && <IconDrop onClick={() => setIsOpen(!isOpen)} cursor={"pointer"} size={20}/>}
            </div>

            {isActive && isOpen &&
            <div className={"p-3"}>
                <Row>
                    {buttons.map((button, index) => {
                        const changeHandler = (name, value) => {
                            buttons[index] = value;
                            setButtons(buttons);
                            handleToolDataChange(name, buttons);
                        };
                        const sideIcon = <MdClose onClick={() => removeButton(index)}/>
                        return (
                            <Col xs={12} key={`${button}-${index}`}>
                                <FieldText changeHandler={changeHandler}
                                           value={buttons[index]}
                                           sideIcon={sideIcon}
                                           name={"actionButtons"}/>

                            </Col>
                        )
                    })}
                    <Col xs={12}>
                        <FieldText changeHandler={addButton}
                                   keydownHandler={e => e.key === "Enter" && addButton("actionButtons", e.target.value)}
                                   value={newButton}
                                   inputStyle={{border: 0, borderBottom: "2px solid #000"}}
                                   placeholder={"הוסף כפתור"}
                                   name={"actionButtons"}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FieldText type="number"
                                   changeHandler={handleToolDataChange}
                                   label="כפתור נכון לבחירה"
                                   name="actionButtonCorrect"
                                   value={actionButtonCorrect}/>
                    </Col>

                    <Col xs={12}>
                        <ButtonCreate defaultProps={actionButtonStyle}
                                      changeHandler={handleButtonStyleChange}/>
                    </Col>
                    {/*<Col xs={12}>*/}
                    {/*    <FieldText changeHandler={handleToolDataChange}*/}
                    {/*               label="גודל טקסט"*/}
                    {/*               name="actionButtonSize"*/}
                    {/*               value={actionButtonSize}/>*/}
                    {/*</Col>*/}
                    {/*<Col xs={12}>*/}
                    {/*    <FieldColor changeHandler={handleToolDataChange}*/}
                    {/*                name="actionButtonBackgroundColor"*/}
                    {/*                label="צבע רקע"*/}
                    {/*                value={actionButtonBackgroundColor || "#fff"}/>*/}
                    {/*</Col>*/}
                    {/*<Col xs={12}>*/}
                    {/*    <FieldColor changeHandler={handleToolDataChange}*/}
                    {/*                name="actionButtonColor"*/}
                    {/*                label="צבע טקסט"*/}
                    {/*                value={actionButtonColor || "#fff"}/>*/}
                    {/*</Col>*/}

                </Row>
            </div>}
        </div>
    )
}