import React from "react";
import "./table-inner-menu.scss";
import {Panel} from "../../panel/panel";

export const TableInnerMenu = ({items, itemId}) => {
    const renderItem = (item, index) => {
        const {Icon, label, onClick} = item;
        return (
            <div className="table-inner-menu-item" onClick={(e) => {
                e.stopPropagation();
                onClick(itemId)
            }} key={index}>
                {Icon && <div className="table-inner-menu-icon"><Icon/></div>}
                <div className="table-inner-menu-name">{label}</div>
            </div>
        )
    }

    return (
        <Panel className="table-inner-menu">
            {items.map(renderItem)}
        </Panel>
    )
}