import React, {useState} from "react";
import arrayMove from "array-move";
import {createNewId} from "../trivia/create-tool-trivia";
import {FilesUploader} from "../../../02_molecules/";
import {SortableList} from "../../../03_organisms";
import {Table, tableHeader, Tr} from "../../../03_organisms/table";
import {CreateToolGalleryImagesItem} from "./create-tool-gallery-images-item";
import {GallerySectionHeader} from "./create-tool-gallery-images-header";

const initialItem = {
    id: 0,
    image: "",
    frame: "",
    icon:"",
    editable: false,
    advancedData: {},
    advancedDataType: null
};
export const CreateToolGalleryImagesSection = ({id, index, galleryView, reorderGalleries, icon, items = [], remove, handleSectionChange}) => {
    const [tableItems, setTableItems] = useState(items);
    const [isOpen, setIsOpen] = useState(true);

    const onChange = (name, value) => handleSectionChange(name, value, id);

    const handleItemChange = (name, value, itemId) => {
        const itemToUpdate = tableItems.find(item => item.id === itemId);
        itemToUpdate[name] = value;
        handleSectionChange("items", tableItems, id);
        setTableItems(tableItems);
    };

    const addItem = () => {
        const newId = createNewId(tableItems);
        const updatedItems = tableItems.concat({...initialItem, id: tableItems.length > 0 ? newId : 0});
        handleSectionChange("items", updatedItems, id);
        setTableItems(updatedItems);
    };

    const removeItem = (itemId) => {
        const updatedItems = tableItems.filter(item => item.id !== itemId);
        handleSectionChange("items", updatedItems, id);
        setTableItems(updatedItems);
    };

    const reorderItems = ({oldIndex, newIndex}) => {
        const updatedItems = arrayMove(tableItems, oldIndex, newIndex);
        handleSectionChange("items", updatedItems, id);
        setTableItems(updatedItems);
    };

    const handleInitialImagesChange = (name, value) => {
        const updatedItems = value.map((image, index) => {
            return {...initialItem, image: image, id: index}
        });
        handleSectionChange("items", updatedItems, id);
        setTableItems(updatedItems);
    };

    const isMatching = galleryView === "matching";

    const toggle = () => setIsOpen(!isOpen);

    const tableHeaderItems = ["#", "תמונה", "מסגרת",  "ניתן לעריכה"].concat(isMatching ? "איקון" : "");

    const renderItem = (item, index) => {
        return <CreateToolGalleryImagesItem {...item}
                                            galleryView={galleryView}
                                            index={index}
                                            handleChange={handleItemChange}
                                            removeItem={() => removeItem(item.id)}
                                            key={item.id}/>
    };

    return (
        <div className="create-tool-gallery-images-section" key={id}>
            <GallerySectionHeader id={id}
                                  index={index}
                                  icon={icon}
                                  isOpen={isOpen}
                                  remove={remove}
                                  changeHandler={onChange}
                                  reorderGalleries={reorderGalleries}
                                  addItem={addItem}
                                  toggle={toggle}/>

            {isOpen &&
            <>
                {tableItems.length === 0 ?
                    <div className="create-tool-gallery-section-body">
                        <FilesUploader name="new-images"
                                       triggerValue={"העלו תמונות"}
                                       changeHandler={handleInitialImagesChange}/>
                    </div>
                    :
                    <div className="create-tool-gallery-section-body">
                        <Table>
                            <thead><Tr>{tableHeaderItems.map(tableHeader)}</Tr></thead>
                            {/*<tbody>*/}
                            {/*<SortableList items={tableItems.map(renderItem)}*/}
                                          {/*ItemTag="tr"*/}
                                          {/*ListTag="tbody"*/}
                                          {/*useDragHandle={true}*/}
                                          {/*onSortEnd={reorderItems}/>*/}
                            {/*</tbody>*/}
                            <tbody>{tableItems.map(renderItem)}</tbody>
                        </Table>
                    </div>
                }
            </>
            }
        </div>
    )
};