import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {LanguageContext, Translation} from "../../../languages";
import {DICT} from "../../../languages/dictionary-names";
import {fetchData} from "../../../store/actions/fetch-data";
import {AuthContext} from "../../../providers/auth-provider";
import {colors, gutters} from "../../00_base/variables";
import {Button, SimpleLoader} from "../../01_atoms";
import {FieldText} from "../../02_molecules";
import "./subscribe.scss";

const ENVELOPES = "https://storage.googleapis.com/assets.youdoadventures.com/website/subscribe.png";
const BACKGROUND = "https://storage.googleapis.com/assets.youdoadventures.com/website/subscribe.svg";

export const Subscribe = ({description, inputPlaceholder}) => {
    const [userEmail, setUserEmail] = useState("");
    const {userData} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [sendSuccess, setSendSuccess] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const {dictionary, rtl} = useContext(LanguageContext);
    const inputStyle = {
        borderRadius: "1.2rem",
        border: `3px solid ${colors.black}`,
        backgroundColor: colors.white,
        height: "6.2rem",
        padding: `0 ${gutters.sm}`
    };
    const controlStyle = {
        marginBottom: 0
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setShowValidation(false);
        const emailRegex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailRegex.test(userEmail.toLowerCase())) {
            const data = {
                name: userData.name,
                email: userEmail,
                subject: "בקשה להרשמה לבלוג דרך האתר",
                message: "בקשה להרשמה לבלוג דרך האתר"
            }
            await fetchData(
                'post',
                'contact',
                data,
                success => {
                    setIsLoading(false)
                    setSendSuccess(true);
                }
            );
        } else {
            setShowValidation(true);
            setIsLoading(false);
        }

    };

    return (
        <div className="subscribe" style={{backgroundImage: `url(${BACKGROUND})`}}>

            <div className="d-flex align-items-center">
                <img src={ENVELOPES} alt="envelope" className={"subscribe-envelopes"}/>
                <p className="subscribe-description">
                    <Translation id={DICT.SUBSCRIBE_DESCRIPTION}/>
                </p>
            </div>

            <div className="d-flex flex-wrap flex-sm-nowrap mt-3">
                {sendSuccess ?
                    <span className="d-block text-center" style={{width: "100%"}}>בקשתך התקבלה</span>
                    :
                    <>
                        <FieldText name="email"
                                   keydownHandler={e => e.key === "Enter" && handleSubmit(e)}
                                   controlStyle={controlStyle}
                                   inputStyle={inputStyle}
                                   placeholder={dictionary[DICT.SUBSCRIBE_INPUT_PLACEHOLDER]}
                                   errorMessageActive={showValidation}
                                   errorMessage="יש להזין כתובת מייל תקינה"
                                   changeHandler={(name, value) => setUserEmail(value)}/>
                        <Button onClick={handleSubmit}
                                style={{height:"6.2rem"}}
                                className={`py-1 px-5 mx-auto ${rtl ? "mr-sm-3 mt-sm-0" : "ml-sm-3 ml-sm-0"}`}>
                            {isLoading ? <SimpleLoader/> : <Translation id={DICT.REGISTER}/>}
                        </Button>
                    </>
                }
            </div>
        </div>
    )
};

Subscribe.defaultProps = {
    description: "הצטרפו לקהילה שלנו כדי לקבל חידות, אתגרים וטיפים לשדרוג ההרפתקאות שלכם",
    inputPlaceholder: "הזינו את כתובת המייל שלכם :)"
};
Subscribe.propTypes = {
    description: PropTypes.string,
    inputPlaceholder: PropTypes.string
};