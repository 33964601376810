import React from 'react';
import PropTypes from "prop-types";
import {Container, Row} from 'react-grid-system';
import styled from 'styled-components';
import {colors, gutters, zIndex} from '../../00_base/variables';

const AppHeader = props => {
    const { leftComponent, rightComponent, centerComponent } = props;
    const {justifyContent, alignItems} = props;

    return (
        <Header {...props} style={props.style}
            className={`app-header ${props.classsName}`}>
            <Container fluid={props.fluid}>
                <Row style={{justifyContent, alignItems}}>
                    <HeaderItem style={{...props.itemWrapperStyle, ...props.leftWrapperStyle}}>
                        {leftComponent && leftComponent}
                    </HeaderItem>
                    <HeaderItem style={{...props.itemWrapperStyle, ...props.centerWrapperStyle}}>
                        {centerComponent && centerComponent}
                    </HeaderItem>
                    <HeaderItem style={{...props.itemWrapperStyle, ...props.rightWrapperStyle}}>
                        {rightComponent && rightComponent}
                    </HeaderItem>
                </Row>
            </Container>
        </Header>
    )
}

const Header = styled.header`
    z-index: ${zIndex.header};
    left 0;
    right 0;
    ${props => `
        position: ${props.fixed ? 'fixed' : 'relative'};
        background-color: ${props.backgroundColor};
        padding: ${props.paddingVertical} ${props.paddingHorizontal};
        border-bottom: ${props.borderBottom}; 
    `}
`; 

const HeaderItem = styled.div`

`;

AppHeader.defaultProps = {
    className: '',
    backgroundColor: colors.gray_2,
    paddingVertical: gutters.sm,
    paddingHorizontal: gutters.sm,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${colors.black}`,
    fluid: false,
    fixed: false
};
AppHeader.propTypes = {
    fixed: PropTypes.bool,
    fluid: PropTypes.bool,
    leftComponent: PropTypes.element,
    rightComponent: PropTypes.element,
    centerComponent: PropTypes.element,
    itemWrapperStyle: PropTypes.object,
    leftWrapperStyle: PropTypes.object,
    centerWrapperStyle: PropTypes.object,
    rightWrapperStyle: PropTypes.object,
    backgroundColor: PropTypes.string,
    paddingVertical: PropTypes.string,
    paddingHorizontal: PropTypes.string,
    justifyContent: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around']),
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center']),
    borderBottom: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    
};

export default AppHeader;