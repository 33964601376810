import React from "react";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styled from 'styled-components'
import "./single-switch-styles.scss";

export const SingleSwitch = (
    {
        topTitle,
        bottomTitle,
        wrapperColor,
        status,
        buttonColor,
        fontSize,
        textColor,
        trueLocation,
        onClick,
        switchHeight,
        switchWidth,
        buttonSize,
        fullOnImage,
        fullOffImage,
        offWrapperImage,
        onWrapperImage,
        onButtonImage,
        offButtonImage,
        switches_on,
        switches_off
    }) => {

    const getRotation = (trueLocation) => {
        switch (trueLocation) {
            case 'Right':
                return 90
            case 'Bottom':
                return 180
            case 'Left':
                return 270
            case 'Top':
                return 0
            default:
                return 0
        }
    };

    const renderSwitch = () => {
        if (fullOnImage && fullOffImage) {
            const imageSource = status ? fullOnImage : fullOffImage
            return (<FullImageSwitch className={"switch-single-image"}
                                     src={imageSource}
                                     onClick={() => onClick(!status)}
                                     height={`${switchHeight}px`}
                                     width={`${switchWidth}px`}/>)
        }
        if (offWrapperImage && onWrapperImage && onButtonImage && offButtonImage) {
            const wrapperImageSource = status ? onWrapperImage : offWrapperImage;
            const buttonImageSource = status ? onButtonImage : offButtonImage;
            return (
                <ImageSwitchWrapper className={classNames("switch-single-image-wrapper", {checked: status})}
                                    height={`${switchHeight}px`}
                                    width={`${switchWidth}px`}
                                    backgroundImage={wrapperImageSource}>
                    <ImageSwitchButton className={"switch-single-image-button"}
                                       src={buttonImageSource}
                                       size={`${buttonSize}px`}
                                       onClick={() => onClick(!status)}/>
                </ImageSwitchWrapper>
            )
        }
        return (
            <SwitchWrapper className={classNames("switch-single-wrapper", {checked: status})}
                           backgroundColor={wrapperColor}
                           borderColor={wrapperColor}
                           rotation={getRotation(trueLocation)}
                           height={`${switchHeight}px`}
                           width={`${switchWidth}px`}>
                <SwitchButton className={"switch-single-button"}
                              onClick={() => onClick(!status)}
                              backgroundColor={buttonColor}
                              buttonSize={`${buttonSize}px`}
                              size={`${buttonSize}px`}/>
            </SwitchWrapper>
        )
    };

    const titleProps = {className:"switch-single-title", color:textColor, fontSize:fontSize};
    return (
        <SwitchContainer className={"switch-single-container"}
                         height={`${switchHeight}px`}
                         buttonSize={`${buttonSize}px`}
                         width={`${switchWidth}px`}>
            <Title {...titleProps}>{topTitle}</Title>
            {renderSwitch()}
            <Title {...titleProps}>{bottomTitle}</Title>
        </SwitchContainer>
    )
};
const titleHeight = 30;

const Title = styled.div`
    height: ${titleHeight}px;
    font-size:${props => props.fontSize || 20}px;
    color: ${props => props.color};
`;
const SwitchContainer = styled.div`
    ${props => `
        height: calc(${props.height} + ${2 * titleHeight}px + 30px);
        width: calc(${props.buttonSize} + 10px);
    `}
`;
const SwitchButton = styled.div`
    ${props => `
        background-color: ${props.backgroundColor};
        height: ${props.size};
        width: ${props.size};
        margin: calc(${props.buttonSize} / -3.5);
    `}
`;
const SwitchWrapper = styled.div`
    ${props => `
        border: 5px solid ${props.borderColor};
        background-color: ${props.backgroundColor};
        transform:rotate(${props.rotation}deg);
        height: ${props.height};
        width: ${props.width};
    `}
`;
const FullImageSwitch = styled.img`
    ${props => `
        height:${props.height};
        width:${props.width};
    `}
`;
const ImageSwitchWrapper = styled.div`
    ${props => `
        background-image:url(${props.backgroundImage});
        height: ${props.height};
        width: ${props.width};
    `}
`;
const ImageSwitchButton = styled.img`
    ${props => `
        height: ${props.size};
        width: ${props.size};
    `}
`;

SingleSwitch.defaultProps = {
    status: "false",
    wrapperColor: "grey",
    buttonColor: "white",
    trueLocation: "Top",
    switchHeight: 100,
    switchWidth: 40,
    buttonSize: 55,
};

SingleSwitch.propTypes = {
    onClick: PropTypes.func.isRequired,
    topTitle: PropTypes.string,
    bottomTitle: PropTypes.string,
    status: PropTypes.bool,
    wrapperColor: PropTypes.string,
    buttonColor: PropTypes.string,
    switchHeight: PropTypes.number,
    switchWidth: PropTypes.number,
    buttonSize: PropTypes.number,
    trueLocation: PropTypes.oneOf(['Top', 'Right', 'Bottom', 'Left']),
    fullOnImage: PropTypes.string,
    fullOffImage: PropTypes.string,
    OffWrapperImage: PropTypes.string,
    OnWrapperImage: PropTypes.string,
    OnButtonImage: PropTypes.string,
    OffButtonImage: PropTypes.string,
};

export default SingleSwitch