import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import * as adventureActions from "../../../store/actions/adventure-actions";
import {
    selectCurrentAdventure,
    selectCurrentAdventureData,
    selectCurrentAdventureType,
    selectCurrentRiddles
} from "../../../store/selectors";
import {FieldCheckbox, FieldText} from "../../02_molecules";
import {GameLobbyUi, Modal} from "../../03_organisms";
import {colors} from "../../00_base/variables";
import {MdRemoveRedEye} from "react-icons/md";
import ScreenMenu from "../../youdo-tools/src/components/05_screens/menu/screen-menu";

export const AdventureLobby = () => {
    const adventure = useSelector(selectCurrentAdventure);
    const adventureType = useSelector(selectCurrentAdventureType);
    const adventureData = useSelector(selectCurrentAdventureData);
    const riddles = useSelector(selectCurrentRiddles);

    const dispatch = useDispatch();
    const {lobby_title, lobby_subtitle, lobby_image_desktop, lobby_image_mobile, show_fake_icons, lobby_fake_icons = []} = adventure.json_data || {};
    const [fakeIcons, setFakeIcons] = useState(lobby_fake_icons);
    const [previewActive, setPreviewActive] = useState(false);
    const handleChange = useCallback((name, value) => {
        const updatedAdventure = {
            ...adventure,
            adventureType: adventure.adventuretype,
            json_data: {...adventure.json_data, [name]: value}
        };
        if (!_.isEqual(adventure.json_data, updatedAdventure.json_data)) {
            dispatch(adventureActions.updateAdventure(updatedAdventure))
        }
    }, [dispatch, adventure]);

    useEffect(() => {
        handleChange("lobby_fake_icons", fakeIcons);
    }, [fakeIcons, handleChange]);

    useEffect(() => {
        if (show_fake_icons) {
            setFakeIcons(icons);
        }
    }, [show_fake_icons])


    const toggleIconHandler = (name, value) => {
        let updatedIcons;
        if (value) {
            updatedIcons = fakeIcons.concat(name);
        } else {
            updatedIcons = fakeIcons.filter(icon => icon !== name);
        }
        setFakeIcons(updatedIcons);
    };

    return (
        <div className="adventure-lobby">
            {previewActive &&
            <Modal isActive="active"
                   hideTrigger={true}
                   zIndex={999999}
                   padding={"10px"}
                   closePreActions={() => setPreviewActive(false)}
                   contentStyle={{width: "100%", height: "100%", maxHeight: "100%"}}
                   childrenWrapperStyle={{height: "100%"}}>
                <ScreenMenu adventure={adventure}
                            isPreview={true}
                            adventureType={adventureType}
                            adventureData={adventureData}
                            riddles={riddles}/>
            </Modal>}
            <div className={"d-flex justify-content-between align-items-center"}>
                <h4>לובי</h4>
                <MdRemoveRedEye size={20}
                                color={colors.blue}
                                onClick={() => setPreviewActive(true)}
                                cursor={"pointer"}/>
            </div>
            <FieldText name="lobby_title"
                       value={lobby_title}
                       changeHandler={handleChange}
                       inputStyleTitle={true}
                       inputStyle={{...inputNoBorders, width: "57rem", fontSize: "20px"}}
                       inputStyleWithValue={{backgroundColor: "transparent"}}
                       placeholder="כותרת לובי"/>

            <FieldText name="lobby_subtitle"
                       value={lobby_subtitle}
                       changeHandler={handleChange}
                       inputStyleTitle={true}
                       inputStyle={{...inputNoBorders, fontSize: "16px"}}
                       inputStyleWithValue={{backgroundColor: "transparent"}}
                       placeholder="כותרת משנה לובי"/>

            <FieldCheckbox options={["הצג איקונים מסיחים"]}
                           name="show_fake_icons"
                           value={show_fake_icons ? ["הצג איקונים מסיחים"] : []}
                           changeHandler={handleChange}/>

            {show_fake_icons &&
            <div>
                <h4>בחר איקונים שיופיעו בלובי</h4>
                <div style={iconsWrapper}>
                    {icons.map((icon, index) => {

                        return (
                            <div key={index}>
                                <FieldCheckbox options={[icon]}
                                               name={icon}
                                               value={fakeIcons.some(fake => fake === icon) ? [icon] : []}
                                               changeHandler={toggleIconHandler}
                                               controlStyle={{width: "auto", marginLeft: "25px"}}/>
                            </div>
                        )
                    })}
                </div>
            </div>
            }

            <GameLobbyUi lobby_image_desktop={lobby_image_desktop}
                         lobby_image_mobile={lobby_image_mobile}
                         handleChange={handleChange}/>
        </div>
    )
}

const inputNoBorders = {fontSize: "20px", borderTop: 0, borderLeft: 0, borderRight: 0, borderRadius: 0, padding: 0};

export const icons = [
    'instagram',
    'whatsapp',
    'shazam',
    'settings',
    'google',
    'calculator',
    'waze',
    'asana',
    'messenger'
];
const iconsWrapper = {
    display: "flex",
    flexWrap: "wrap"
}