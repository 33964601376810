import React from "react";
import {Col, Row} from "react-grid-system";
import {font_size} from "../../00_base/variables";
import {FieldOptions, FieldText, FieldVideo, FilesUploader} from "../../02_molecules";
import {ColorPickerBgText} from "../../03_organisms";
import "./game-entrance-ui.scss";

export const GameEntranceUi = (
    {
        entrance_image_desktop,
        entrance_image_mobile,
        logo_url,
        opening_video_url,
        welcome_title_size,
        bg_color,
        text_color,
        continue_bg_color,
        continue_text_color,
        logo_type,
        welcome_pre_title,
        entrance_cta,
        handleChange
    }
) => {
    const Trigger = ({title, subtitle}) => {
        return (
            <div>
                <p>{title}</p>
                <p>{subtitle}</p>
            </div>
        )
    };

    const triggerStyle = {
        backgroundColor: "#fff",
        border: "2px dashed #bbbbbb",
    };
    const logoTypes = [
        {id: 0, label: "הסתר לוגו", value: "hidden"},
        {id: 1, label: "לבן", value: "white"},
        {id: 2, label: "שחור", value: "black"},
    ];

    return (
        <>
            {/*<h4>מסך ראשי</h4>*/}
            <Row className="pb-5 mb-5">
                <Col>
                    <FilesUploader name={'entrance_image_desktop'}
                                   value={entrance_image_desktop}
                                   label={"תמונת רקע דסקטופ"}
                                   previewWidth={'33rem'}
                                   previewHeight={'14rem'}
                                   multiple={false}
                                   shrinkImage={false}
                                   triggerValue={<Trigger title={"תמונת רקע דסקטופ"} subtitle={"1920*400"}/>}
                                   triggerStyle={{...triggerStyle, width: "33rem", height: "14rem"}}
                                   changeHandler={handleChange}
                                   controlStyle={{paddingRight: 0, width: "33rem", height: "14rem"}}/>
                </Col>
                <Col>
                    <FilesUploader name={'entrance_image_mobile'}
                                   value={entrance_image_mobile}
                                   label={"תמונת רקע מובייל"}
                                   multiple={false}
                                   shrinkImage={false}
                                   previewWidth={'15rem'}
                                   previewHeight={'17rem'}
                                   triggerValue={<Trigger title={"תמונת רקע מובייל"} subtitle={"360*480"}/>}
                                   triggerStyle={{...triggerStyle, width: "15rem", height: "17rem"}}
                                   changeHandler={handleChange}
                                   controlStyle={{width: "15rem", height: "17rem"}}/>
                </Col>
                <Col>
                    <FilesUploader name={'logo_url'}
                                   value={logo_url}
                                   label={"לוגו"}
                                   multiple={false}
                                   shrinkImage={false}
                                   previewWidth={'14rem'}
                                   previewHeight={'14rem'}
                                   triggerValue={<Trigger title={" לוגו"} subtitle={"200*200"}/>}
                                   triggerStyle={{...triggerStyle, width: "14rem", height: "14rem"}}
                                   changeHandler={handleChange}
                                   controlStyle={{width: "14rem", height: "14rem"}}/>
                </Col>
                <Col xs={12} className="adventure-type-game-video mt-5">
                    <h6 className="mb-2 mt-0">סרטון פתיחה</h6>
                    <FieldVideo name="opening_video_url"
                                triggerValue={"העלה קובץ"}
                                value={opening_video_url}
                                changeHandler={handleChange}/>
                </Col>
            </Row>

            <Row className={"adventure-type-game-style"}>
                <Col xs={12} md={6} lg={4}>
                    <ColorPickerBgText label={"צבעים (רקע וטקסט)"}
                                       bgColor={bg_color}
                                       textColor={text_color}
                                       bgName={"bg_color"}
                                       textName={"text_color"}
                                       handleChange={handleChange}/>
                </Col>
                <Col xs={12} lg={5}>
                    <FieldOptions label={"צבע לוגו"}
                                  options={logoTypes}
                                  name={"logo_type"}
                                  controlStyle={{display: "flex", alignItems: "center"}}
                                  labelStyle={{marginBottom: 0}}
                                  dir={"horizontal"}
                                  inputStyle={{fontSize: font_size.sm}}
                                  value={logoTypes.find(item => item.value === logo_type)}
                                  changeHandler={handleChange}/>
                </Col>
            </Row>

            <Row>
                <Col sm={8}>
                    <FieldText changeHandler={handleChange}
                               name={"welcome_pre_title"}
                               label={"טקסט מקדים לכותרת"}
                               value={welcome_pre_title}/>
                </Col>
                <Col sm={4}>
                    <FieldText changeHandler={handleChange}
                               label={"גודל כותרת"}
                               type={"number"}
                               name={"welcome_title_size"}
                               value={welcome_title_size}/>
                </Col>
            </Row>
            <h6 className={"mt-3 mb-1"}>כפתור המשך</h6>
            <Row className={"mb-3"}>
                <Col className={"d-flex flex-wrap"}>
                    <FieldText name="entrance_cta"
                               value={entrance_cta}
                               changeHandler={handleChange}
                               dir="horizontal"
                               controlStyle={{marginLeft: "30px", width: "auto"}}
                               placeholder="בואו נתחיל"/>
                    <ColorPickerBgText bgColor={continue_bg_color}
                                       textColor={continue_text_color}
                                       bgName={"continue_bg_color"}
                                       textName={"continue_text_color"}
                                       handleChange={handleChange}/>
                </Col>
            </Row>
        </>
    )
};