import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BackgroundImage from '../background-image/a-background-image';
import SimpleLoader from "../loaders/simple-loader";

const Video = (
    {
        source,
        placeholder,
        autoPlay,
        controls,
        muted,
        loop,
        videoStyle,
        type,
        isLoading,
        afterColor
    }) => {
    return (
        <VideoWrapper className={'video-wrapper'} afterColor={afterColor} style={{ ...videoDefaultStyle, ...videoStyle }}>
            {isLoading && <SimpleLoader/>}
            {placeholder &&
                <BackgroundImage url={placeholder}
                    style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />}

            <video className={'video'}
                autoPlay={autoPlay}
                muted={muted}
                loop={loop}
                controls={controls}
                style={{ height: '100%', width:"100%", position:"relative", zIndex:1 }}>
                <source src={source} type={"video/mp4"} />
                <source src={source} type={"video/avi"} />
                <source src={source} type={"video/webm"} />
                <source src={source} type={"video/ogg"} />
                <source src={source} type={"video/wmv"} />
            </video>
        </VideoWrapper>
    )
};

const VideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    &:after {
        content: '';
        background-color: ${props => props.afterColor};
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
`;
const videoDefaultStyle = {
    position: 'absolute',
    left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign:"center"
}

Video.defaultProps = {
    type: 'video/*',
    autoPlay: false,
    muted: false,
    loop: false,
    controls: true,
    afterColor: 'rgba(0,0,0,0.5)'
};

Video.propTypes = {
    source: PropTypes.string,
    placeholder: PropTypes.string,
    controls: PropTypes.bool,
    autoPlay: PropTypes.bool,
    muted: PropTypes.bool,
    loop: PropTypes.bool,
    afterColor: PropTypes.string,
    videoStyle: PropTypes.object,
    type: PropTypes.string
};

export default Video;