import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {Span} from '../../../components/01_atoms';
import "./breadcrumbs.scss";

export const Breadcrumbs = ({items = []}) => {
    const Breadcrumb = ({title, href, isLast}) => {
        const Div = ({children}) => <div>{children}</div>;
        const Tag = href ? Link : Div;
        return (
            <>
                <Tag to={href} style={{cursor: "pointer"}}>
                    {title}
                </Tag>
                {!isLast && <span className={"px-3"}>/</span>}
            </>
        )
    };

    return (
        <div className={"d-flex align-items-center py-3 breadcrumbs"}>
            {items.map((item, index) => <Breadcrumb {...item}
                                                    isLast={index === items.length - 1}
                                                    key={index}/>)}
        </div>
    )
};