import React, {createContext, useState} from "react";

export const RiddlesStoreContext = createContext({
    levelFilter: [],
    handleLevelFilterChange: null
});

export const RiddlesStoreProvider = ({children}) => {
    const [levelFilter, setLevelFilter] = useState([]);

    const handleLevelFilterChange = (name,value) => {
        debugger
    }
    const provider = {
        levelFilter,
        handleLevelFilterChange: () => setLevelFilter()
    };
    console.log(levelFilter)
    return (
        <RiddlesStoreContext.Provider value={provider }>
            {children}
        </RiddlesStoreContext.Provider>
    )
};