import React, {useCallback, useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {MdMoreVert} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import * as adventureTemplateActions from "../../../../store/actions/adventure-template-actions";
import {selectAllAdventureTemplates} from "../../../../store/selectors";
import {AuthContext} from "../../../../providers/auth-provider";
import {API_URL, fetchData} from "../../../../store/actions/fetch-data";
import {font_size} from "../../../00_base/variables";
import {Circle, Switcher} from "../../../01_atoms";
import {FieldCheckbox, FieldSelect, FieldText} from "../../../02_molecules";
import {TableInnerMenu, Td, TdContent, Tr} from "../../../03_organisms/table";
import "./adventureType-items.scss";
import {ChooseItemLanguage} from "../../../03_organisms/choose-item-language/choose-item-language";

export const AdventureTypeItem = (
    {
        item,
        index,
        innerMenuItems,
        setInnerMenuActive,
        menuActive,
        toggleMarkItem,
        changeHandler,
    }) => {
    const adventureTemplates = useSelector(selectAllAdventureTemplates);

    const [currentAdventureTemplate, setCurrentAdventureTemplate] = useState(adventureTemplates.find(template => template.id === item.adventuretemplate_id));
    const [adventureData, setAdventureData] = useState(null);
    const [userDetails, setUserDetails] = useState({});
    const [framesLength, setFramesLength] = useState(null);
    const [adventuresLength, setAdventuresLength] = useState("");

    const dispatch = useDispatch();

    const {isTom} = useContext(AuthContext);

    const getFramesLength = useCallback(() => {
        const getSceneFramesLength = (riddleId) => new Promise(resolve => {
            fetchData(
                "get",
                `riddles/${riddleId}`,
                {},
                riddle => resolve(riddle.riddlestemplates?.length || 0)
            )
        });
        return Promise.all(adventureData?.riddles.map(getSceneFramesLength)).then(
            response => setFramesLength(response.reduce((a, b) => a + b, 0))
        )
    }, [adventureData]);

    useEffect(() => {
        if (adventureData) {
            getFramesLength();
        }
    }, [adventureData, getFramesLength]);

    useEffect(() => {
        fetchData(
            "get",
            `counteAdventuresByAdventureType/${item.id}`,
            {},
            response => setAdventuresLength(response[0].count)
        );
    }, [item.id]);

    useEffect(() => {
        if (item.adventuredata_id) {
            fetch(`${API_URL}/adventureData/${item.adventuredata_id}`).then(
                response => response.json()
            ).then(
                response => setAdventureData(response)
            )
        }

    }, [item.adventuredata_id]);

    useEffect(() => {
        if (item.user_id) {
            fetchData(
                "get",
                `users/${item.user_id}`,
                {},
                response => {
                    setUserDetails(response)
                }
            )
        }

    }, [item.user_id]);

    const createNewTemplate = (name) => dispatch(adventureTemplateActions.createAdventureTemplate({name}));

    const handleChange = (name, value) => changeHandler({...item, [name]: value});

    const dropdownStyle = {
        customStyles: {dropdownIndicator: {display: "none"}, singleValue: {border: 0}},
        controlStyle: {width: "100%", marginBottom: 0},
        customControlStyle: {width: "100%", height: "50px", cursor: "pointer"},
        customSingleValueStyle: {fontSize: font_size.xs, paddingRight: 0},
        customInputStyle: {fontSize: font_size.xs},
        inputStyle: {height: "100%", border: "0"}
    };
     return (
        <Tr key={index} className={"adventure-type-item"}>
            {isTom &&
            <>
                <Td>
                    <TdContent>
                        <FieldCheckbox options={['סמן']}
                                       inputBorder={"2px solid #5388ff"}
                                       inputBackground={"#fff"}
                                       controlStyle={{marginBottom: 0}}
                                       changeHandler={(name, isChecked) => toggleMarkItem(item.id, isChecked)}
                                       name={`mark`}
                                       hideOptionLabel={true}/>
                    </TdContent>
                </Td>
                <Td>
                    <TdContent style={{fontWeight: "bold"}}>{item.id || "-"}</TdContent>
                </Td>
            </>
            }
            <Td>
                <TdContent>
                    <FieldText name={"name"}
                               changeHandler={handleChange}
                               inputStyleWithValue={{backgroundColor:"transparent"}}
                               value={item.name}
                               controlStyle={controlStyle}
                               inputStyle={inputStyle}/>
                </TdContent>
            </Td>

            <Td>
                <TdContent style={{paddingTop: 0, paddingBottom: 0}}>
                    <FieldSelect name={"adventuretemplate_id"}
                                 options={adventureTemplates.map(item => {
                                     return {label: item.name, value: item.id, id: item.id}
                                 })}
                                 hideArrow={true}
                                 className={"choose-adventure-template"}
                                 value={currentAdventureTemplate && {
                                     value: currentAdventureTemplate.id,
                                     label: currentAdventureTemplate.name
                                 }}
                                 changeHandler={(name, value) => {
                                     setCurrentAdventureTemplate(adventureTemplates.find(template => template.id === value.id))
                                     handleChange(name, value.id)
                                 }}
                                 placeholder={"-"}
                                 {...dropdownStyle}
                                 createOptionHandler={(value, handleChange) => {
                                     handleChange(value);
                                     createNewTemplate(value);
                                 }}
                    />
                </TdContent>
            </Td>

            <Td>
                <TdContent>
                    <ChooseItemLanguage value={item.language_id}
                                        changeHandler={handleChange}
                                        flagsSize={20}/>
                </TdContent>
            </Td>

            <Td>
                <TdContent>
                    {userDetails.name}
                </TdContent>
            </Td>

            <Td>
                <TdContent>
                    <Switcher checked={!!item.is_active}
                              name={"is_active"}
                              changeHandler={(name, checked) => {
                                  if (item.is_active !== checked) {
                                      handleChange(name, checked)
                                  }
                              }}/>
                </TdContent>
            </Td>

            <Td>
                <TdContent>
                    <Switcher checked={!!item.show_in_site}
                              name={"show_in_site"}
                              changeHandler={(name, checked) => {
                                  if (item.show_in_site !== checked) {
                                      handleChange(name, checked)
                                  }
                              }}/>
                </TdContent>
            </Td>

            <Td>
                <TdContent>
                    <FieldText name={"base_price"}
                               type={"number"}
                               changeHandler={handleChange}
                               value={item.base_price ? item.base_price.replace(".00", "") : ""}
                               controlStyle={{...controlStyle, maxWidth: "80px"}}
                               inputStyle={inputStyle}/>
                </TdContent>
            </Td>

            <Td>
                <TdContent>{adventuresLength}</TdContent>
            </Td>

            <Td>
                <TdContent>
                    {!!framesLength ?
                        <Link style={{color: "#000"}} to={`/adventure-type/${item.id}/sequence`}>{framesLength}</Link>
                        :
                        "-"
                    }
                </TdContent>
            </Td>

            <Td>
                <TdContent>
                    <Circle background={menuActive ? "#f1f1f1" : "transparent"}
                            onClick={setInnerMenuActive}
                            className="inner-menu-trigger"
                            cursor={"pointer"} size={25}>
                        <MdMoreVert size={20} color={"#d2d2d2"}/>
                    </Circle>
                    {menuActive && <TableInnerMenu items={innerMenuItems} itemId={item.id}/>}
                </TdContent>
            </Td>
        </Tr>
    )
}

const controlStyle = {margin: 0, height: "100%"};
const inputStyle = {border: 0, height: "100%", padding: 0};