import React from 'react';
import {Page} from "../../../04_layout";
import Login from "./login";

const ScreenLogin = (props) => {
    const title = props && props.location && props.location.title;
    const goAfterLogin = props && props.location && props.location.goAfterLogin;
    const language = props.match.params.lang || "he";
    return (
        <Page style={{justifyContent: 'center'}} className={`registration language-${language}`}>
            <Login title={title} goAfterLogin={goAfterLogin} language={language}/>
        </Page>
    )
};

export default ScreenLogin;