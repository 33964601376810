import React from "react";
import {Heading, MobileHidden} from "../../../01_atoms";
import {Element, HBox} from "react-stylesheet";
import {Translation} from "../../../../languages";
import {DICT} from "../../../../languages/dictionary-names";
import classnames from "classnames";
import {colors, gutters} from "../../../00_base/variables";
import {MdClose, MdGrade} from "react-icons/md";

const starColor = colors.yellow;

export const buildStars = (length) => {
    const stars = [];
    for (let i = 0; i < length; i++) {
        stars.push(MdGrade)
    }
    return stars;
};

export const StarsDictionary = (
    {
        isLoading,
        difficultyFilterLevel,
        setDifficultyFilterLevel,
        getFilteredRiddlesStoreData
    }) => {
    const DifficultyDictionary = () => {
        const items = [
            {index: 1, label: <Translation id={DICT.EASY}/>},
            {index: 2, label: <Translation id={DICT.MEDIUM}/>},
            {index: 3, label: <Translation id={DICT.HARD}/>},
            {index: 4, label: <Translation id={DICT.EXPERT}/>},
        ];
        return (
            <HBox justifyContent={'center'} marginTop={'30px'}>
                {items.map((item, index) => {
                        const isCurrentDifficultyFilter = item.index === difficultyFilterLevel;
                        return (
                            <Element key={index}
                                     className={classnames("singleDifficultyLevelOption", {current: isCurrentDifficultyFilter})}
                                     textAlign={'center'}
                                     padding={`0 ${gutters.md}`}
                                     onClick={() => setDifficultyFilterLevel(isCurrentDifficultyFilter ? null : item.index)}>
                                <Heading tag={'h4'} className={"color-black"}>{item.label}</Heading>
                                {buildStars(item.index).map((Star, index) => <Star key={index} size={30}
                                                                                   color={starColor}/>)}
                            </Element>
                        )
                    }
                )}
            </HBox>
        )
    };
    return (
        <>
            {!isLoading && <MobileHidden><DifficultyDictionary/></MobileHidden>}
            <MobileHidden>
                {
                    (difficultyFilterLevel !== null) &&
                    <div className={"d-flex justify-content-center align-items-center my-3"}>
                        {buildStars(difficultyFilterLevel).map((Star, index) => <Star key={index} size={30}
                                                                                      color={starColor}/>)}
                        <h4 className={"mr-4"}>({getFilteredRiddlesStoreData().length} חידות) </h4>
                        <h6 className={"mr-4"} style={{cursor: "pointer", textDecoration: "underline"}}
                            onClick={() => setDifficultyFilterLevel(null)}><MdClose/></h6>
                    </div>
                }
            </MobileHidden>
        </>
    )
};