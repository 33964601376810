import React, {useContext, useState} from 'react';
import {MdAddCircleOutline} from "react-icons/md";
import {colors} from "../../../00_base/variables";
import {Button} from '../../../01_atoms';
import {FrameContext} from "../../riddle/template/single-frame";
import CreateClue from './create-clue';
import "./create-clues.scss";

const initialClue = {title: '', subtitle: '', description: '', image: ''};

const CreateToolClues = (
    {
        toolClues = [],
        closeClues,
    }) => {
    const {handleFrameChange} = useContext(FrameContext);
    const [activeClue, setActiveClue] = useState(null);

    const handleChange = (clues) => handleFrameChange("clues", clues);

    const changeClue = (name, value, index) => {
        toolClues[index][name] = value;

        handleChange(toolClues)
    };

    const addClue = () => {
        handleChange(toolClues.concat(initialClue))
    };

    const deleteClue = index => {
        handleChange(toolClues.filter((clue, i) => index !== i));
    };

    return (
        <div className="clues">
            <div className="clues-header">
                <Button className="clues-add-clue" onClick={addClue}>
                    <MdAddCircleOutline color={colors.black}/>
                    <span>הוסף רמז</span>
                </Button>
            </div>

            {toolClues && toolClues.map((clue, index) => {
                return (
                    <CreateClue clueData={clue}
                                key={index}
                                index={index}
                                deleteClueHandler={deleteClue}
                                changeHandler={changeClue}
                                toggle={(index) => setActiveClue(index)}
                                isActive={activeClue === index}
                                closeClues={closeClues}/>
                )
            })}
        </div>
    )
}

export default CreateToolClues; 