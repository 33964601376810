import React, {createContext, useContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import classNames from "classnames";
import {Route, Switch, useHistory, withRouter} from "react-router-dom";
import * as templatesActions from "../../../store/actions/templates-actions";
import * as categoriesActions from "../../../store/actions/categories-actions";
import ScreenHomepage from "../../website/pages/homepage";
import AppSidebar from "../app-sidebar/l-app-sidebar";
import {setAllAdventureTypes, setAllShowInSiteAdventureTypes} from "../../../store/actions/adventure-type-actions";
import {BurgerMenu} from "../../03_organisms";
import {adminRoutes, authenticationRoutes, websiteRoutes} from "../../../app-routes";
import ScreenNotFound from "../../05_screens/not-found/screen-not-found";
import {Screen} from "../../05_screens/screen";
import {AppHeader} from "../index";
import AppFooter from "../app-footer";
import {AuthContext, AuthProvider} from "../../../providers/auth-provider";
import {setLoginUserAdventures} from "../../../store/actions/adventure-actions";
import {setAllBlogs} from "../../../store/actions/blogs-actions";

import styled from "styled-components";
import {ThemeProvider} from "../../../providers/theme-provider";
import {setAllTags} from "../../../store/actions/tags-actions";

export const RouteContext = createContext({
    pathname: "",
    admin: false,
    adminInsideWebsite: false,
    setAdminInsideWebsite: null
});

const AppContent = ({location}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const pathname = history.location.pathname;

    const {isLogin, isAdmin, isCreator, isTranslator, userData} = useContext(AuthContext);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [adminInsideWebsite, setAdminInsideWebsite] = useState(false);

    const isRegister = location && location.pathname === "/register";
    const isRouteAdmin = pathname.includes("/admin/");

    const routeProvider = {
        pathname,
        admin: isRouteAdmin,
        adminInsideWebsite: adminInsideWebsite > 0,
        setAdminInsideWebsite
    };
    const sidebar = (mobileMenuActive || (isLogin && !isRegister)) && <AppSidebar/>;
    const isWebsite = !isRouteAdmin;

    if (window.location.href.includes("create.youdoadventures.com")) {
        window.location.href = "https://youdoadventures.com/he/admin/dashboard"
    }

    useEffect(() => {
        dispatch(setAllAdventureTypes());
        dispatch(setAllBlogs());
        dispatch(setAllShowInSiteAdventureTypes());
        dispatch(setAllTags());
        dispatch(templatesActions.setAllTemplates());
        dispatch(categoriesActions.setAllCategories());
        if (isLogin && userData?.email) {
            dispatch(setLoginUserAdventures(userData.email))
        }
    }, [dispatch, isLogin, userData.email]);

    const routes = authenticationRoutes.concat(websiteRoutes).concat(isAdmin ? adminRoutes.slice(0, isCreator ? isTranslator ? 9 : 8 : 100) : []);

    const appRoutesClassNames = classNames(
        "app-routes ", {
            "route-website": isWebsite,
            "route-admin": !isWebsite && !adminInsideWebsite
        });

    return (
        <RouteContext.Provider value={routeProvider}>
            <ThemeProvider>
                {(isWebsite || adminInsideWebsite) && <AppHeader/>}
                {isRouteAdmin && <BurgerMenu isActive={mobileMenuActive} toggle={setMobileMenuActive}/>}
                <div id='app-routes' className={appRoutesClassNames}>
                    {isRouteAdmin && sidebar}
                    <ScreenWrapper className="route-section">
                        <Switch location={location}>
                            <Route exact path={'/:lang?'} component={ScreenHomepage}/>
                            {routes.map((route, index) => {
                                return <Route key={index}
                                              path={route.path}
                                              render={props => <Screen {...props} {...route}/>}/>
                            })}
                            <Route render={() => <Route path="*" component={ScreenNotFound}/>}/>
                        </Switch>
                    </ScreenWrapper>
                </div>

                {isWebsite && <AppFooter/>}
            </ThemeProvider>
        </RouteContext.Provider>
    );
};

const ScreenWrapper = styled.div`
    min-height: calc(100vh - 46rem);
    .route-admin & {
        background-image: none;
    }
`;

export default withRouter(AppContent); 