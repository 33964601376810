import React from "react";
import {FieldTextArea} from "../../../../02_molecules";
import "./create-tool-content.scss";

export const CreateToolContent = (
    {toolData, handleToolDataChange}
) => {

    const checkIsValid = (toolData) => {
        return toolData.items.filter(item => !item.value).length === 0 ? 100 : 0
    };
    const changeHandler = (name, value) => {

        handleToolDataChange(checkIsValid, "items", toolData.items.map((item, index) => {
                return {...item, value: index === 0 ? value : item.value}
            }
        ))
    };

    return (
        <div className={"create-tol-content-text"}>
            <FieldTextArea name={"text"}
                           wysiwyg={true}
                           inputStyle={{fontSize: "20px"}}
                           rows={7}
                           value={toolData.items[0]?.value}
                           initialValue={toolData.items[0]?.value}
                           changeHandler={changeHandler}/>
        </div>
    )
};