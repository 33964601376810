import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from "../../../01_atoms";
import {Table, tableHeader} from "../../../03_organisms/table/";
import {CreateToolRotariesItem} from "./create-tool-rotaries-item";
import {CreateToolRotariesCommon} from "./create-tool-rotaries-common";
import "./create-tool-rotaries.scss";

const initialItem = {
    id: 0,
    initialRotateValue: '',
    answer: ''
};

const CreateToolRotaries = (props) => {
    const {items = [],
        handleToolDataChange,
         } = props;
    const [commonFieldsActive, setCommonFieldsActive] = useState(true);
    const [rotariesItems, setRotariesItems] = useState(items);

    // const totalEditOptions = [
    //     // {key:"direction",title: "כיוון", type:"Dropdown", options:['row','column']},
    //     {key: "degressAddOnClick", title: "מעלות בלחיצה", type: "Number"},
    //
    //     // {parent:"עיצוב בורר", key:"borderWidth",title: "עובי מסגרת", type:"Number"},
    //     // {parent:"עיצוב בורר", key:"borderColor",title: "צבע מסגרת", type:"Color"},
    //     // {parent:"עיצוב בורר", key:"borderRadius", title:"עיגוליות", type:"String"},
    //     // {parent:"עיצוב בורר", key:"size",title: "גודל", type:"Number"},
    //
    //     // {parent:"חוגה", key:"rotaryWidth",title: "עובי חוגה", type:"Number"},
    //     // {parent:"חוגה", key:"rotarySize",title: "גודל חוגה", type:"Number"},
    //     // {parent:"חוגה", key:"rotaryDotStyle", title:"סגנון נקודה", type:"Dropdown", options:["dot", "arrow", "outArrow", "line"]},
    //     {parent: "חוגה", key: "startLocation", title: "מיקום התחלה", type: "Dropdown", options: ["top", "bottom"]},
    //
    //     {parent: "מספרים", key: "showNumbers", title: "הראה מספרים", type: "Boolean"},
    //     {parent: "מספרים", key: "hideZero", title: "הסתר 0", type: "Boolean"},
    //     {parent: "מספרים", key: "numbersInside", title: "מספרים בפנים", type: "Boolean"},
    // ]
    //
    // const singleItemsEditOptions = [
    //     // {key:"color",title: "צבע חוגה", type:"Color"},
    //     // {key:"dotColor", title:"צבע נקודה", type:"Color"},
    //     // {key:"backgroundColor",title: "צבע רקע", type:"Color"},
    //     {key: "initialRotateValue", title: "מצב התחלתי", type: "Number"},
    //     {key: "answer", title: "תשובה", type: "Number"},
    // ];


    const addItem = () => {
        const newId = Math.max(...rotariesItems.map(item => item.id)) + 1;
        const updated = rotariesItems.concat({...initialItem, id: rotariesItems.length > 0 ? newId : 0});
        setRotariesItems(updated);
        handleToolDataChange("items", updated)
    };

    const removeItem = (itemId) => {
        const updated = rotariesItems.filter(item => item.id !== itemId);
        setRotariesItems(updated);
        handleToolDataChange("items", updated)
    };

    const handleChange = (name, value, index) => {
        rotariesItems[index][name] = value;
        setRotariesItems(rotariesItems);
        handleToolDataChange("items", rotariesItems);
    };

    const renderItem = (item, index) => {
        return <CreateToolRotariesItem {...item}
                                       isCustomDotStyle={props.rotaryDotStyle === "custom"}
                                       handleChange={handleChange}
                                       index={index}
                                       removeItem={removeItem}/>
    };
    const tableHeaderItems = ["#", "תשובה", "עיגול חיצוני", "מסגרת", "עיגול פנימי", "נקודה"];


    const commonFields = <CreateToolRotariesCommon handleToolDataChange={handleToolDataChange}
                                                   {...props}/>
    return (
        <div className={"create-tool create-tool-rotaries"}>
            {items?.length > 0 &&
            <div className="create-tool-table-wrapper">
                <Table>
                    <thead>{tableHeaderItems.map(tableHeader)}</thead>
                    <tbody>{items.map(renderItem)}</tbody>
                </Table>
            </div>
            }
            <Button className={"tool-add-section mb-3"} onClick={addItem}>הוסף בורר</Button>
            <div onClick={() => setCommonFieldsActive(!commonFieldsActive)} className={"create-tool-rotaries-ui"}>
                עיצוב בורר
            </div>
            {commonFieldsActive && commonFields}

        </div>
    )

    // return (
    //     <CreateCommon className={"rotaries-create"}
    //                   items={items}
    //                   newItemBody={{}}
    //                   defaultProps={categorydata}
    //                   handleToolDataChange={handleToolDataChange}
    //                   singleItemsEditOptions={singleItemsEditOptions}
    //                   totalEditOptions={totalEditOptions}/>
    // )
};


CreateToolRotaries.defaultProps = {
    submitHandler: (toolData) => console.log('toolData', toolData),
    toolData: {items: []}
};

CreateToolRotaries.propTypes = {
    toolData: PropTypes.object,
    submitHandler: PropTypes.func,
    switchHeight: PropTypes.string,
    switchWidth: PropTypes.string,
    buttonSize: PropTypes.string,
    defaultProps: PropTypes.object
};


export default CreateToolRotaries;