import styled from 'styled-components';
import {viewport} from '../../00_base/variables';


export const MobileHidden = styled.div`
    display: none;
    @media screen and (min-width: ${viewport.sm}) {
        display: ${props => props.display ? props.display : 'block'};
    }
`;

export const MobileLgHidden = styled.div`
    display: none;
    @media screen and (min-width: ${viewport.md}) {
        display: ${props => props.display ? props.display : 'block'};
    }
`;
export const TabletHidden = styled.div`
    display: none;
    @media screen and (min-width: ${viewport.lg}) {
        display: ${props => props.display ? props.display : 'block'};
    }
`;
export const DesktopHidden = styled.div`
    display: none;
    @media screen and (min-width: ${viewport.xl}) {
        display: ${props => props.display ? props.display : 'block'};
    }
`;


export const MobileOnly = styled.div`
    @media screen and (min-width: ${props => props.viewport ? props.viewport : viewport.sm}) {
        display: none !important;
    }
`;

export const MobileLgOnly = styled.div`
    @media screen and (min-width: ${viewport.md}) {
        display: none !important;
    }
`;

export const TabletOnly = styled.div`
    @media screen and (min-width: ${viewport.lg}) { 
        display: none !important;
    }
`;

