import React, {useContext, useEffect, useState} from "react";
import _ from "lodash";
import {FrameModal} from "./frame-modal";
import classNames from "classnames";
import {FrameContext} from "../../single-frame";
import "./frame-modals.scss";

const modalInitialData = {
    id: 0,
    toolId: "",
    toolData: {},
    modalIcon: "https://images.youdoadventures.com/1611076610858-kodan_bg_01_png.png"
};
export const createNewId = (arr) => Math.max(...arr.map(item => item.id)) + 1;

export const SingleFrameModals = ({modals}) => {
    const {handleFrameChange} = useContext(FrameContext);

    const [modalsList, setModalsList] = useState(modals || []);
    const [isOpen, setIsOpen] = useState(modals?.length > 0);

    useEffect(() => {
        if (!_.isEqual(modals, modalsList)) {
            handleFrameChange("modals", modalsList);
        }
    }, [handleFrameChange, modals, modalsList]);

    const addModal = () => setModalsList(modalsList.concat([{...modalInitialData, id: createNewId(modalsList)}]));
    const removeModal = (id) => setModalsList(modalsList.filter(modal => modal.id !== id));

    const handleModalChange = (name, value, id) => {
        const updatedModals = modalsList.map(modal => modal.id === id ? {...modal, [name]: value} : modal);
        setModalsList(updatedModals);
    };

    const renderModalForm = modal => <FrameModal {...modal}
                                             handleModalChange={(name, value) => handleModalChange(name, value, modal.id)}
                                             removeModal={() => removeModal(modal.id)}
                                             key={modal.id}/>;

    return (
        <div className={"single-frame-modals single-frame-box"}>
            <h4 className="single-frame-box-title" onClick={() => setIsOpen(!isOpen)}>
                {`פופאפים ${modalsList?.length > 0 ? `(${modalsList.length})` : ""}`}
            </h4>
            <div className={classNames("additional-data-body single-frame-box-content", {active: isOpen})}>
                {modalsList.map(renderModalForm)}
                <button onClick={addModal}
                className="single-frame-add-modal">
                    הוסף פופאפ
                </button>
            </div>
        </div>
    )
};