import React, {useEffect, useState} from "react";
import {Col, Row} from "react-grid-system";
import classNames from "classnames";
import {ScreenTool} from "../../../../youdo-tools/src/components/05_screens/tool/screen-tool";
import {SingleFrameDesign} from "./frame-design/single-frame-design";
import {SingleFrameTool} from "./single-frame-tool";
import {SingleFrameEditable} from "./single-frame-editable";
import {SingleFrameClues} from "./single-frame-clues";
import {SingleFrameAdditionalData} from "./frame-additional-data/single-frame-additional-data";
import {SingleFrameModals} from "./frame-modals/single-frame-modals";
import {MdFullscreen, MdFullscreenExit} from "react-icons/md";
import {LoaderContent, LoaderFacebook, LoaderInstagram} from "../../../../01_atoms";
import "./single-frame-body.scss";
import {SingleFrameTopBar} from "./frame-top-bar/single-frame-top-bar";

export const SingleFrameBody = (
    {id, riddle, frame, categories, modals, categorydata, clues, instructions, tips, editable}
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [mainFullScreen, setMainFullScreen] = useState(false);
    const [toolFullScreen, setToolFullScreen] = useState(false);

    // useEffect(() => {
    //     setTimeout(() => {
    //         setIsLoading(false)
    //     }, 200)
    // }, []);

    const ButtonFullSize = ({onClick, isFullScreen}) => {
        const Icon = isFullScreen ? MdFullscreenExit : MdFullscreen;
        return (
            <button className="preview-tool-fullscreen" onClick={onClick}>
                <Icon size={20}/>
            </button>
        )
    };

    const classNameRightCol = classNames("single-frame-col", {fullScreen: mainFullScreen});
    const classNameCenterCol = classNames("single-frame-col", {fullScreen: toolFullScreen});
    const classNameLeftCol = classNames("single-frame-col");

    return (
        <div className="single-frame-body route-admin">

            <div className="single-frame-container">
                <Row gutterWidth={10} style={{alignItems: "stretch"}}>
                    <Col xl={mainFullScreen ? 12 : toolFullScreen ? 6 : 5} className={classNameRightCol}>
                        <aside className={"single-frame-main"}>
                            <div className="single-frame-box">
                                {isLoading ?
                                    <LoaderFacebook/> :
                                    <>
                                        <SingleFrameTool categorydata={categorydata}
                                                         categories={categories}
                                                         id={id}
                                                         riddle={riddle}
                                                         frame={frame}/>
                                        <ButtonFullSize onClick={() => setMainFullScreen(!mainFullScreen)}
                                                        isFullScreen={mainFullScreen}/>
                                    </>
                                }
                            </div>

                        </aside>
                    </Col>

                    <Col lg={toolFullScreen ? 12 : mainFullScreen ? 6 : 8}
                         xl={toolFullScreen ? 12 : mainFullScreen ? 6 : 4}
                         style={{order: toolFullScreen ? -1 : "initial", transition: "all .3s"}}
                         className={classNameCenterCol}>
                        <main>

                            <div className={"single-frame-box"}>
                                {isLoading ?
                                    <LoaderInstagram/> :
                                    <>
                                        {frame?.categories[0] &&
                                        <>
                                            <ScreenTool toolId={frame.categories[0]}
                                                        toolData={{categorydata: categorydata}}
                                                        previewMode={true}/>

                                            <ButtonFullSize onClick={() => setToolFullScreen(!toolFullScreen)}
                                                            isFullScreen={toolFullScreen}/>
                                        </>
                                        }
                                    </>}
                            </div>
                        </main>
                    </Col>

                    <Col lg={(toolFullScreen && mainFullScreen) ? 12 : (toolFullScreen || mainFullScreen) ? 6 : 4}
                         xl={(toolFullScreen && mainFullScreen) ? 12 : (toolFullScreen || mainFullScreen) ? 6 : 3}
                         className={classNameLeftCol}>
                        <aside className="single-frame-sidebar">
                            {isLoading ?
                                <>
                                    <div className={"single-frame-box"}><LoaderContent/></div>
                                    <div className={"single-frame-box"}><LoaderContent/></div>
                                    <div className={"single-frame-box"}><LoaderContent/></div>
                                    <div className={"single-frame-box"}><LoaderContent/></div>
                                    <div className={"single-frame-box"}><LoaderContent/></div>
                                </>
                                :
                                <>
                                    <SingleFrameDesign categorydata={categorydata} frame={frame}/>
                                    <SingleFrameTopBar categorydata={categorydata} frame={frame}/>
                                    <SingleFrameAdditionalData categorydata={categorydata} frame={frame}/>
                                    <SingleFrameModals modals={modals}/>
                                    <SingleFrameClues clues={clues} id={id}/>
                                    <SingleFrameEditable instructions={instructions} tips={tips} editable={editable}/>
                                </>
                            }
                        </aside>
                    </Col>
                </Row>
            </div>

        </div>
    )
};
