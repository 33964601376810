import React from 'react';
import {HBox} from 'react-stylesheet';
import PropTypes from "prop-types";
import {PageSubtitle, PageTitle} from './page-items/';


const Page = ({ style, name, title, subtitle, description, alignItems, paddingTop, titleTag, subtitleTag, titleStyle, subtitleStyle, children, fluid }) => {
    return (
        <HBox id={'app-page'}
            className={`page`}
            minHeight={'100%'}
            width={'100%'}
            paddingTop={paddingTop}
            alignItems={alignItems}
            overflow={'auto'}
            style={style}>
            {title && <PageTitle style={titleStyle} tag={titleTag}>{title}</PageTitle>}
            {subtitle && <PageSubtitle style={subtitleStyle} tag={subtitleTag}>{subtitle}</PageSubtitle>}
            {/* {description && <PageTitle style={titleStyle} tag={titleTag}>{description}</PageTitle>} */}
            <HBox className={'app-page-content'} width={'100%'} height={'100%'} overflow={'auto'} alignItems={alignItems}>{children}</HBox>
        </HBox>
    )
};

Page.defaultProps = {
    fluid: true,
    titleTag: 'h1',
    alignItems: 'baseline'
};
Page.propTypes = {
    title: PropTypes.string,
    titleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    titleStyle: PropTypes.object,
    subtitle: PropTypes.string,
    subtitleTag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    subtitleStyle: PropTypes.object,
    fluid: PropTypes.bool,
    name: PropTypes.string,
    alignItems: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'justify-content', 'alig-items', 'baseline', 'stretch']),
};

export default Page;