import React from 'react';
import styled from 'styled-components';
import {colors} from '../../00_base/configurations';
import {BackgroundImage} from '../../01_atoms';

class BoxAnimated extends React.Component {
    render() {
        const {onClick, url, height, tabletHeight, isSingle, mobileHeight, transition, hoverStyle, classNameWrapper, classNameImage, style, isLoading} = this.props;

        const classNamesWrapper = `${classNameWrapper ? classNameWrapper : ''} box-animated`;
        const classNamesImage = `${classNameImage ? classNameImage : ''} box-animated-image`;
        return (
            <BoxWrapper className={classNamesWrapper}
                        onClick={onClick && onClick}
                        style={{...style && style, backgroundColor: isLoading ? colors.gray_loading : ''}}>

                <BackgroundImage url={url ? url : 'https://admin.youdoadventures.com/wp-content/uploads/2019/04/default-thumbnail.jpg'}
                                 height={height}
                                 size={isSingle ? 'contain' : 'cover'}
                                 tabletHeight={tabletHeight && tabletHeight}
                                 mobileHeight={mobileHeight && mobileHeight}
                                 isLoading={isLoading}
                                 transition={transition}
                                 hoverStyle={hoverStyle}
                                 className={classNamesImage}/>

            </BoxWrapper>
        )
    }

    static defaultProps = {
        height: '100%',
        transition: 'transform .3s',
        hoverStyle: `transform: scale(1.1) rotate(3deg)`
    }
}

export default BoxAnimated;

const BoxWrapper = styled.div`
    overflow: hidden;
    transition: box-shadow .5s; 
    &:hover {
        
    }
`;