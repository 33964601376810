import React, {useState} from "react";
import {Button} from "../../../01_atoms";
import {ScreenTool} from "../../../youdo-tools/src/components/05_screens/tool/screen-tool";
import {FinalScreen} from "./single-riddle-final";
import {colors} from "../../../00_base/variables";

export const SingleRiddleGame = (
    {riddle, frames}
) => {
    const [activeFrame, setActiveFrame] = useState(0);
    const [toolContinueActive, setToolContinueActive] = useState(false);

    const handleContinueClick = () => {
        setActiveFrame(activeFrame + 1);
        setToolContinueActive(false);
    };

    const handleFramePreviewContinue = () => setToolContinueActive(true);

    const frame = frames[activeFrame];
    const gameFinished = activeFrame >= frames.length;

    if (gameFinished) {
        return <FinalScreen riddle={riddle}/>
    }

    const toolId = frame.categories[0];
    const toolData = {categorydata: frame.categorydata};

    return (
        <div className="d-block mt-7 pb-5" style={{backgroundColor: colors.white, borderRadius:"10px"}}>
            <ScreenTool toolId={toolId}
                        toolData={toolData}
                        handleFramePreviewContinue={handleFramePreviewContinue}
                        previewFrameFinish={toolContinueActive}
                        hideTopBar={true}
                        previewMode={true}/>
            {toolContinueActive &&
            <Button className="px-5 mt-4 mx-auto d-block"
                    style={{fontWeight: "bold"}}
                    onClick={handleContinueClick}>
                המשך
            </Button>}
        </div>
    )
};