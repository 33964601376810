import React, {useEffect, useState} from "react";
import classNames from "classnames";
import "./tool-audio.scss";

export const ToolAudio = (
    {
        imagePause="https://images.youdoadventures.com/1608560494453-recorder_stopped_png.png",
        imagePlay="https://images.youdoadventures.com/1608560606843-recorder_png.png",
        audio,
        handleFrameFinish
    }) => {
    const [isAudioActive, setIsAudioActive] = useState(false);

    useEffect(handleFrameFinish, []);

    useEffect(() => {
        const audioPlayer = document.getElementById("tool-audio-player");
        if (isAudioActive) {
            audioPlayer.play();
        } else {
            audioPlayer.currentTime = 0;
            audioPlayer.pause();
        }
    }, [isAudioActive]);

    return (
        <div className="tool-audio">
            <div className="tool-audio-image" onClick={() => setIsAudioActive(!isAudioActive)}>
                <img src={imagePause} alt="audio-pause" className={classNames({active:!isAudioActive})}/>
                <img src={imagePlay} alt="audio-play" className={classNames({active:isAudioActive})}/>
            </div>
            <audio controls id="tool-audio-player" className="tool-audio-player">
                <source src={audio} type="audio/ogg"/>
                <source src={audio} type="audio/mpeg"/>
            </audio>
            {/*{nextFrameButton && nextFrameButton}*/}
        </div>
    )
};