import React, {useContext, useState} from "react";
import {dictionaryList, LanguageContext} from "./index";
import {MdLanguage} from "react-icons/md";
import "./language-selector.scss";
import {ThemeContext} from "../providers/theme-provider";
import {colors} from "../components/00_base/variables";
import {Image} from "../components/01_atoms";

export const LanguageSelector = () => {
    const {userLanguage, userLanguageChange} = useContext(LanguageContext);
    const [languagesActive, setLanguagesActive] = useState(false);
    const {mode} = useContext(ThemeContext);
    const textColor = mode === "light" ? colors.black : colors.white;
    const {languages} = useContext(LanguageContext);

    // const allLanguages = Object.keys(dictionaryList);

    return (
        <div className="language-selector d-flex align-items-center mx-3">
            <MdLanguage onClick={() => setLanguagesActive(!languagesActive)} color={textColor} cursor="pointer"/>
            {languagesActive &&
            <div className="px-3 position-absolute flex-row-reverse languages-wrapper">
                {languages?.map((lang, index) => {
                    const itemStyle = {cursor: "pointer", padding:"0 5px", fontWeight: userLanguage === lang.name ? "bold" : "normal"};
                    return (
                        <div key={index}
                             className={"d-flex align-items-center justify-content-between"}
                             onClick={() => {
                                 userLanguageChange(lang.name);
                                 setLanguagesActive(false);
                             }}>
                            <span className="font-size-xs"
                                  style={itemStyle}>
                                {lang.title}
                            </span>
                            <Image src={lang.flag} width={25}/>
                        </div>
                    )
                })}</div>
            }

        </div>
    )
};
