import React from "react";
import {FieldVideo, FilesUploader} from "../../../02_molecules";
import "./create-tool-audio.scss";

export const CreateToolAudio = (
    {
        imagePause="https://images.youdoadventures.com/1608560494453-recorder_stopped_png.png",
        imagePlay="https://images.youdoadventures.com/1608560606843-recorder_png.png",
        audio,
        handleToolDataChange}
) => {
    return (
        <div className="create-tool-audio">
            <div className="create-tool-audio-images">
                <FilesUploader name="imagePause"
                               value={imagePause}
                               multiple={false}
                               changeHandler={handleToolDataChange}
                               label={"תמונה למצב stop"}
                               triggerValue={"תמונה למצב stop"}
                               previewWidth={200}
                               controlStyle={{padding: "10px"}}/>
                <FilesUploader name="imagePlay"
                               label={"תמונה למצב play"}
                               triggerValue={"תמונה למצב play"}
                               value={imagePlay}
                               multiple={false}
                               changeHandler={handleToolDataChange}
                               previewWidth={200}
                               controlStyle={{padding: "10px"}}/>
            </div>
            <FieldVideo sourceType={2}
                        name="audio"
                        value={audio}
                        triggerValue={"העלה קובץ"}
                        changeHandler={handleToolDataChange}/>
        </div>
    )
}