import React, {useState} from "react";
import {Button} from "../../../01_atoms";
import {CreateToolSliderItem} from "./create-tool-slider-item";
import {CssGeneral} from "../../ui/css-general/css-general";
import {StyledDiv} from "../../ui/css-general/css-styled";
import {SortableList} from "../../../03_organisms/sortable/o-sortable";
import arrayMove from "array-move";
import "./create-tool-slider.scss";

const initialSection = {
    id: 0,
    image: '',
    title: '',
    description: "",
    color: "#999"
};
export const createNewId = (arr) => Math.max(...arr.map(item => item.id)) + 1;

export const CreateToolSlider = (
    {items = [], handleToolDataChange, indicatorStyle}
) => {
    const [sliderItems, setSliderItems] = useState(items);

    const addSection = () => {
        const newId = createNewId(sliderItems);
        setSliderItems(sliderItems.concat({...initialSection, id: sliderItems.length > 0 ? newId : 0}))
    };

    const removeSection = (itemId) => {
        setSliderItems(sliderItems.filter(item => item.id !== itemId));
    };

    const handleChange = (name, value, id) => {
        sliderItems.find(item => item.id === id)[name] = value;
        setSliderItems(sliderItems);
        handleToolDataChange("items", sliderItems)
    };

    const handleIndicatorStyleChange = (name, value) => {
        handleToolDataChange("indicatorStyle", {...indicatorStyle, [name]: value});
    };

    const IndicatorPreviewComponent = () => {
        return (
            <StyledDiv {...indicatorStyle} className={"slider-create-preview-indicator"}/>
        )
    };

    const onSortEnd = ({oldIndex, newIndex}) => {
        const updatedSections = arrayMove(sliderItems, oldIndex, newIndex);
        setSliderItems(updatedSections);
        handleToolDataChange("items", updatedSections);
    };

    const renderItem = (item, index) => {
        return <CreateToolSliderItem {...item}
                                     index={index}
                                     handleChange={handleChange}
                                     removeSection={removeSection}
                                     key={item.id}/>
    };

    return (
        <div className="create-tool slider-create">

            <SortableList items={sliderItems.map(renderItem)}
                          ItemTag={"li"}
                          ListTag={"ul"}
                          useDragHandle={true}
                          onSortEnd={onSortEnd}/>


            <Button outline className="tool-add-section" onClick={addSection}>הוסף פריט</Button>

            <CssGeneral key={"slider-indicator-ui"}
                        title="עיצוב כפתור אינדיקציה"
                        changeHandler={handleIndicatorStyleChange}
                        PreviewComponent={IndicatorPreviewComponent}
                        defaultProps={indicatorStyle}/>
        </div>
    )
};